import React, { useState, useEffect } from "react";
import "./style.scss";
import { Form, Input, Row, Col, Button, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ajax from "../../../../func/ajax";

import {
  defaultGutter,
  formItemLayout,
  editFormColSpan,
} from "../../../../static/formLayoutConfig";

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 14 },
};

export default () => {
  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.userInfo));
  }, []);
  const history = useHistory();
  const [form] = Form.useForm();
  const [liked, setLiked] = useState(true);
  const [count, setCount] = useState(60);
  const [userInfo, setUserInfo] = useState(null);

  const handleLogin = () => {
    const { password, validate_code } = form.getFieldsValue();
    ajax({
      path: "/customer/User/setPassword",
      data: { password, validate_code, phone: userInfo.phone },
    }).then((res) => {
      if (res.status === 200) {
        message.success("修改成功请重新登录");
        setTimeout(() => {
          localStorage.clear();
          history.replace("/login/g_login");
        }, 1000);
      }
    });
  };
  //倒计时
  const sendCode = () => {
    let counts = count - 1;
    const timer = setInterval(() => {
      setCount(counts--);
      setLiked(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked(true);
        setCount(60);
      }
    }, 1000);
  };

  //ajax获取验证码
  const getCode = (phone) => {
    ajax({
      path: `/creditor/iphonesend/${phone}`,
      data: { phone },
      method: "get",
      isNeedToken: false,
    }).then((res) => {});
  };

  //验证码操作
  const verifiedSubmit = () => {
    let phone = userInfo.phone;
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      sendCode();
      getCode(phone);
    } else {
      message.error("请输入正确的手机号");
      return;
    }
  };

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>个人设置</span>
            <RightOutlined className={"iconArrow"} />
            <span>密码修改</span>
          </li>
        </ul>
      </div>
      <div className={"modifyPassword-container"}>
        <Form {...formItemLayout} form={form}>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item label="选择验证方式">
                <span className="ant-form-text">手机验证</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item label="手机号">
                <span className="ant-form-text">{userInfo?.phone}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label="短信验证码"
                name={"validate_code"}
                style={{ display: "flex" }}
                rules={[
                  {
                    required: true,
                    message: "请输入验证码",
                  },
                ]}
              >
                <Input placeholder={"请输入验证码"} />
              </Form.Item>
            </Col>
            <Col>
              <Button
                disabled={liked ? false : true}
                onClick={verifiedSubmit}
                className={liked ? "verificationCode" : ""}
              >
                {liked ? "获取验证码" : count + "秒后重发"}
              </Button>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label="新密码"
                name={"password"}
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9A-Za-z]{6,}$/,
                    message: "密码长度不少于6位,数字+字母组成",
                  },
                ]}
              >
                <Input.Password placeholder="最少6位，数字+字母" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                name="new_password_repeat"
                dependencies={["password"]}
                label="再次输入密码"
                rules={[
                  { required: true, message: "再次输入密码" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("两次密码输入不一致！");
                    },
                  }),
                ]}
              >
                <Input.Password type="password" placeholder="请保持一致" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className={"modify"}>
          <Button type={"primary"} onClick={handleLogin}>
            提交
          </Button>
        </div>
      </div>
    </div>
  );
};
