import React from "react";

const navMap = [
  {
    name: "订单管理",
    path: "/user/myOrder/myOrderList",
    children: [
      {
        name: "我的订单",
        path: "/user/myOrder/myOrderList",
        index: "1-0",
        childrenPath: ["/user/myOrder/myOrderDetail"],
      },
    ],
  },
  {
    name: "我的工具",
    path: "/user/myTool/myAppoint/index",
    children: [
      { name: "我的预约", path: "/user/myTool/myAppoint", index: "2-1" },
      { name: "标的物订阅", path: "/user/myTool/subscribe", index: "2-2" },
    ],
  },
  {
    name: "个人设置",
    path: "/user/mySetting/index",
    children: [
      { name: "手机号修改", path: "/user/mySetting/modifyPhone", index: "3-1" },
      {
        name: "密码修改",
        path: "/user/mySetting/modifyPassword",
        index: "3-2",
      },
      { name: "第三方授权", path: "/user/mySetting/authorize", index: "3-3" },
    ],
  },
];

export default navMap;
