import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { Form, Input, Row, Col, Button, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
  defaultGutter,
  formItemLayout,
  editFormColSpan,
} from "../../../../../static/formLayoutConfig";
import ajax from "../../../../../func/ajax";
import formValid from "../../../../../func/formValid";
import { Md5 } from "ts-md5";

export default () => {
  const [form] = Form.useForm();
  const [liked, setLiked] = useState(true);
  const [count, setCount] = useState(60);

  const handleLogin = () => {
    formValid(form)
      .then(() => {
        let {
          phone,
          phone_code,
          new_password,
          new_password_repeat,
        } = form.getFieldsValue();
        new_password = Md5.hashStr(String(new_password.replace(/ /g, "")));
        new_password_repeat = Md5.hashStr(
          String(new_password_repeat.replace(/ /g, ""))
        );
        ajax({
          path: "/creditor/v1/userpassword",
          data: {
            phone,
            phone_code,
            new_password,
            new_password_repeat,
            type: 1,
          },
          isNeedToken: false,
        }).then((res) => {
          message.success("支付密码更新成功");
        });
      })
      .catch();
  };

  //倒计时
  const sendCode = () => {
    let counts = count - 1;
    const timer = setInterval(() => {
      setCount(counts--);
      setLiked(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked(true);
        setCount(60);
      }
    }, 1000);
  };

  //ajax获取验证码
  const getCode = (phone) => {
    ajax({
      path: `/creditor/iphonesend/${phone}`,
      data: { phone },
      method: "get",
      isNeedToken: false,
    }).then((res) => {});
  };

  //验证码操作
  const verifiedSubmit = () => {
    let { phone } = form.getFieldsValue();
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      sendCode();
      getCode(phone);
    } else {
      message.error("请输入正确的手机号");
      return;
    }
  };
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>账户管理</span>
            <Link to={"/creditorUser/account/managePassword/index"}>
              <RightOutlined className={"iconArrow"} />
              <span>密码管理</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>支付密码修改</span>
          </li>
        </ul>
      </div>
      <div className={"modifyPassword_container"}>
        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            phone: JSON.parse(localStorage.userInfo).account,
          }}
        >
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"手机号"}
                name="phone"
                rules={[
                  {
                    required: true,
                    pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                    message: "请输入手机号码",
                  },
                ]}
              >
                <Input disabled placeholder="请输入手机号码" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"短信验证码"}
                name="phone_code"
                rules={[
                  {
                    required: true,
                    message: "请输入验证码",
                  },
                ]}
              >
                <Input placeholder="请输入验证码" />
              </Form.Item>
            </Col>
            <Col>
              <Button
                disabled={liked ? false : true}
                onClick={verifiedSubmit}
                className={liked ? "verificationCode" : ""}
              >
                {liked ? "获取验证码" : count + "秒后重发"}
              </Button>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"新密码"}
                name="new_password"
                rules={[
                  {
                    required: true,
                    pattern: /^\d{6}$/,
                    message: "密码长度为6位数字",
                  },
                ]}
              >
                <Input.Password placeholder="请输入你要设定的密码" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                name="new_password_repeat"
                label={"再次输入密码"}
                dependencies={["new_password"]}
                rules={[
                  { required: true, message: "请再次输入新密码" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("两次密码输入不一致！");
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="请再次输入你要设定的密码"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className={"modify"}>
          <Button onClick={handleLogin} type={"primary"}>
            修改
          </Button>
        </div>
      </div>
    </div>
  );
};
