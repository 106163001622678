import React, { useEffect } from "react";
import "./style/global.scss";
import Index from "./pages/index/index";
import Search from "./pages/search/search";
import Login from "./pages/login/index";
import User from "./pages/user/user";
import CreditorUser from "./pages/creditorUser/user";
import ServiceProvider from "./pages/serviceProvider/serviceProviderIndex";
import ServiceProviderApply from "./pages/serviceProvider/serviceProviderApply";
import Institution from "./pages/institution/institutionIndex";
import InstitutionApply from "./pages/institution/institutionApply";
import SupplierApply from "./pages/supplierApply";
import PrivacyPolicy from "./pages/privacyPolicy/index";
import ServiceContract from './pages/serviceContract/index';
import AppServiceContract from './pages/appServiceContract/index';
import BrokerIndex from "./pages/broker/brokerIndex";
import BrokerApply from "./pages/broker/brokerApply";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import moment from "moment";
import "moment/locale/zh-cn";
import { BackTop } from "antd";
import ScrollToTop from "./component/ScrollToTop";
import { Provider } from "react-redux";
import store from "./store";
moment.locale("zh-cn");

function App(props) {
  const { history } = props;

  useEffect(() => {
    console.log(/^\/user./.test(history.location.pathname))
    // if (history.location.pathname !== "/privacyPolicy" && history.location.pathname !== "/serviceContract") {
    // if (history.location.pathname == /^\/creditorUser./ || history.location.pathname === /^\/user./) {
    if ( (/^\/creditorUser./).test(history.location.pathname)|| (/^\/user./).test(history.location.pathname)) {
      console.log("zzz")
      if (
        !localStorage.timer ||
        Number(localStorage.timer) + 60 * 1000 * 60 * 24 * 7 <
        new Date().getTime()
      ) {
        localStorage.clear();
        history.push("/");
      }
    }

  }, [history]);
  return (
    <Provider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <div className="App">
          <ScrollToTop>
            <Switch>
              <Route path={"/"} exact>
                <Index />
              </Route>
              <Route path={"/search"}>
                <Search history={history} />
              </Route>
              <Route path={"/institution"}>
                <Institution />
              </Route>
              <Route path={"/institutionApply"}>
                <InstitutionApply />
              </Route>
              <Route path={"/serviceProvider"}>
                <ServiceProvider />
              </Route>
              <Route path={"/serviceProviderApply"}>
                <ServiceProviderApply />
              </Route>
              
        {/*  经纪人*/}
              <Route path={"/brokerIndex"}>
                <BrokerIndex />
              </Route>
              <Route path={"/brokerApply"}>
                <BrokerApply />
              </Route>

              <Route path={"/login"}>
                <Login />
              </Route>
              <Route path={"/user"}>
                <User />
              </Route>
              <Route path={"/creditorUser"}>
                <CreditorUser />
              </Route>
              <Route path={"/supplierApply"}>
                <SupplierApply />
              </Route>
              <Route path={"/privacyPolicy"}>
                <PrivacyPolicy />
              </Route>
              <Route path={"/serviceContract"}>
                <ServiceContract />
              </Route>
              <Route path={"/appServiceContract"}>
                <AppServiceContract />
              </Route>
            </Switch>
          </ScrollToTop>
          <BackTop />
        </div>
      </Router>
    </Provider>
  );
}

export default withRouter(App);
