import React from "react";

import "./style.scss";
import { Form, Input, Button, Row, Col, message, Modal } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ImgUploader from "../../../../../../component/ImgUploader";
import {
  defaultGutter,
  editFormColSpan,
  formItemLayout,
} from "../../../../../../static/formLayoutConfig";
import formValid from "../../../../../../func/formValid";
import ajax from "../../../../../../func/ajax";
import queryToData from "../../../../../../func/queryToData";

export default () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const handleSubmit = () => {
    formValid(form).then((res) => {
      let { name, url } = form.getFieldsValue();
      formValid(form).then((res) => {
        ajax({
          path: "/creditor/v1.Zc_object/getObjectClaim",
          data: { name, url: url[0], object_id: queryToData().id },
        }).then((res) => {
          if (res.status === 200) {
            Modal.info({
              title: "提交成功",
              content: <div>申请已提交，平台审核会在3个工作日完成。</div>,
              onOk() {
                history.push("/creditorUser/subject/onShelf");
              },
            });
          } else {
            message.error(res.message);
          }
        });
      });
    });
  };

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>标的物管理</span>
            <RightOutlined className={"iconArrow"} />
            <span>标的物上架</span>
            <RightOutlined className={"iconArrow"} />
            <span>司法拍卖标的物认领</span>
          </li>
        </ul>
      </div>
      <div className={"claimSubmit_container"}>
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{
            name: JSON.parse(localStorage.userInfo).realname,
          }}
        >
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"认领人"}
                name={"name"}
                rules={[{ required: true, message: "该项为必填项" }]}
              >
                <Input disabled key={"input1"} placeholder={"请输入认领人"} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"司法执行判决书"}
                name={"url"}
                rules={[{ required: true, message: "该项为必填项" }]}
              >
                <ImgUploader maxNumber={1} defaultFileList={[]} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className={"submit_btn"}>
          <Button onClick={handleSubmit} type={"primary"}>
            提交审核
          </Button>
        </div>
      </div>
    </div>
  );
};
