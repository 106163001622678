import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import HmacSHA1 from "crypto-js/hmac-sha1";
const oss=window?.getOssData("U2FsdGVkX196I4sSrIsG+zRiXvVgkruadPgAS1gDAew=");
console.log(window.getOssData)

const ossData = {
  host: "https://laipai-img.oss-cn-hangzhou.aliyuncs.com",
  dir: "assets_customer_files/",
  accessKeyId: oss.accessKeyId,
  accessKeySecret: oss.accessKeySecret,
};
const expiration = new Date(
  new Date().getTime() + 24 * 60 * 60 * 1000
).toISOString();
const policyText = {
  expiration, //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
  conditions: [
    ["content-length-range", 0, 1048576000], // 设置上传文件的大小限制
  ],
};
const policyBase64 = Base64.stringify(Utf8.parse(JSON.stringify(policyText)));
const bytes = HmacSHA1(policyBase64, ossData.accessKeySecret, {
  asBytes: true,
});
const signature = Base64.stringify(bytes);

const ossSTS = {
  dir: "assets_customer_files/",
  expire: new Date(expiration).getTime(),
  host: ossData.host,
  accessId: ossData.accessKeyId,
  policy: policyBase64,
  signature,
};

export default ossSTS;
