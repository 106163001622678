import React, { useEffect, useState } from "react";
import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
import ajax from "../../../../func/ajax";
import { Empty } from "antd";
import Pagination from "antd/es/pagination";
export default () => {
  const [appointList, setAppointList] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const size = 5;
  //获取预约列表
  useEffect(() => {
    getAppointList(1);
  }, []);
  const getAppointList = (newPagination) => {
    if (newPagination) {
      setPage(newPagination);
    }
    ajax({
      path: "/customer/User/getVisitLisr",
      data: { size, page: newPagination },
    }).then((res) => {
      setAppointList(res.list);
      setCount(res.count);
      window.scrollTo(0, 0);
    });
  };
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>我的工具</span>
            <RightOutlined className={"iconArrow"} />
            <span>我的预约</span>
          </li>
        </ul>
      </div>
      <div className={"myAppoint-container"}>
        <ul className={"myAppoint-container-ul"}>
          {appointList.map((item, index) => {
            return (
              <li key={index} className={"myAppoint-container-item"}>
                <div
                  className={"cover_img"}
                  style={{ backgroundImage: `url(${item.img_path})` }}
                />
                <div className={"myAppoint-container-item-right"}>
                  <div className={"title"}>{item.object_title}</div>
                  <div className={"infos"}>
                    <div>
                      <span>看样时间：</span>
                      {item.join_time}
                    </div>
                    <div>
                      <span className={"spc_letter"}>看 样 人：</span>
                      {item.staff_name}
                    </div>
                    <div>
                      <span>联系电话：</span>
                      {item.staff_phone || ""}
                    </div>
                    {/*<div>*/}
                    {/*  <span>看样地址：</span>*/}
                    {/*  {item.province_code}*/}
                    {/*  {item.city_code}*/}
                    {/*  {item.district_code}*/}
                    {/*</div>*/}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {count === 0 && <Empty style={{ margin: "30px 0" }} />}
        {count !== 0 && (
          <Pagination
            style={{ textAlign: "right", margin: "40px 24px" }}
            total={count}
            pageSize={size}
            onChange={(e) => getAppointList(e)}
            showQuickJumper
            showTotal={(total) => `共 ${total} 条`}
          />
        )}
      </div>
    </div>
  );
};
