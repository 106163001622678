import React, { useEffect, useState } from "react";

import "./style.scss";
import { Table } from "antd";
import { RightOutlined } from "@ant-design/icons";

import columns from "./columns";
import ajax from "../../../../func/ajax";
import { valueToLabel } from "../../../../func/fn";
import { payType } from "../../../../static/option";
export default (props) => {
  useEffect(() => {
    getSettlementList(1);
  }, []);
  const [settlementList, setSettlementList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const size = 10;
  //结算明细
  const getSettlementList = (newPagination) => {
    if (newPagination) {
      setPage(newPagination);
    }
    ajax({
      path: "/creditor/v1/detailed",
      data: { page: newPagination, size },
      method: "get",
    }).then((res) => {
      if (Array.isArray(res.data)) {
        setSettlementList([]);
        setCount(0);
      } else {
        res.data.data.map((item) => {
          item.t_trade_type = item.trade_type === 1 ? "收入" : "支出";
          if (item.trade_type === 1) {
            item.pay_type_text = "余额";
          } else {
            item.pay_type_text = valueToLabel({
              optionArray: payType,
              value: item.tg_pay_type,
            });
          }
          return item;
        });
        setSettlementList(res.data.data);
        setCount(res.data.total);
      }
    });
  };
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>结算管理</span>
            <RightOutlined className={"iconArrow"} />
            <span>结算明细</span>
          </li>
        </ul>
      </div>
      <div className={"settlement_container"}>
        <Table
          rowKey="id"
          scroll={{ x: 960 }}
          columns={columns()}
          dataSource={settlementList}
          pagination={{
            current: page,
            pageSize: size,
            total: count,
            showTotal: (count) => `共${count}条数据`,
            showSizeChanger: false,
          }}
          onChange={(pagination) => {
            getSettlementList(pagination.current);
          }}
        />
      </div>
    </div>
  );
};
