import React, { useState, useEffect } from "react";
import "./style.scss";
import { RightOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ajax from "../../../../../func/ajax";

export default () => {
  useEffect(() => {
    getPaystate();
  }, []);
  const [payState, setPayStatus] = useState(0);
  //获取支付密码设置状态
  const getPaystate = () => {
    ajax({
      path: "/creditor/v1/paystate",
      method: "get",
    }).then((res) => {
      setPayStatus(res.message);
    });
  };
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/account/index"}>
              <span>账户管理</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>密码管理</span>
          </li>
        </ul>
      </div>
      <div className={"managePasswordIndex_container"}>
        <div className={"manage_item"}>
          <div>
            <LockOutlined style={{ color: "#1890FF", marginRight: "4px" }} />
            登录密码
          </div>
          <span>在登录资产营销平台时需要输入密码</span>
          <Link to={"/creditorUser/account/managePassword/modifyLoginPassword"}>
            修改
          </Link>
        </div>
        <div className={"manage_item"}>
          <div>
            {payState === 0 ? (
              <LockOutlined style={{ color: "#999999", marginRight: "4px" }} />
            ) : (
              <LockOutlined style={{ color: "#1890FF", marginRight: "4px" }} />
            )}
            支付密码
          </div>
          <span>在账户资金变动，需要输入的密码</span>
          <Link
            to={
              payState === 0
                ? "/creditorUser/account/managePassword/setPayPassword"
                : "/creditorUser/account/managePassword/modifyPayPassword"
            }
          >
            {payState === 0 ? "设置" : "修改"}
          </Link>
        </div>
      </div>
    </div>
  );
};
