import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Input, Dropdown, Menu } from "antd";
import "./style.scss";
import LoginChoose from "../../../component/Head/LoginChoose";
import { codeSelect } from "../../../static/option";

const navItem = [
  { title: "首页", path: "/" },
  { title: "司法拍卖", path: "/search?currentIndex=1" },
  { title: "流拍寻宝", path: "/search?currentIndex=2" },
  { title: "资产拍卖", path: "/search?currentIndex=3" },
  { title: "资产推介", path: "/search?currentIndex=4" },
  { title: "入驻" },
];

const navSettledItem = [
  { title: "品牌商加盟", path: "/serviceProvider" },
  { title: "经纪人加盟", path: "/brokerIndex" },
  { title: "债权人加盟", path: "/institution" },
  { title: "供应商加盟", path: "/supplierApply" },
];
// const navSettledItem = [
//   { title: "债权人入驻", path: "/institution" },
//   { title: "服务商入驻", path: "/serviceProvider" },
//   { title: "供应商入驻", path: "/supplierApply" },
//   { title: "经纪人入驻", path: "/brokerIndex" },
// ];

export default () => {
  const [name, setName] = useState("");
  const [showNavSettledItem, setShowNavSettledItem] = useState(false);
  const history = useHistory();

  const handleSearch = (e) => {
    let url = name ? `/search?name=${name}` : "/search";
    history.push(url);
  };

  const menu = (navSettledItem) => {
    return (
      <Menu>
        {navSettledItem.map((item) => {
          return (
            <Menu.Item
              key={item.path}
              onClick={() => {
                setShowNavSettledItem(false);
              }}
            >
              <Link to={item.path}>{item.title}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <div className={"index_header"}>
      <div className={"index_header_content"}>
        <div className={"top"}>
          <div className={"top_content container-1200"}>
            <div className={"band"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/b2f4c147af9e4673f1432d2c4ce30c20.png"
                alt=""
              />
              矩阵资产
            </div>
            <div className={"right"}>
              <ul className={"navbar"}>
                {navItem.map((item, index) => {
                  if (index !== navItem.length - 1) {
                    return (
                      <li key={item.title}>
                        <Link to={item.path}>{item.title}</Link>
                      </li>
                    );
                  } else {
                    return (
                      <Dropdown
                        overlay={menu(navSettledItem)}
                        visible={showNavSettledItem}
                        onVisibleChange={(visible) => {
                          setShowNavSettledItem(visible);
                        }}
                        key={index}
                        placement="bottomCenter"
                      >
                        <li>
                          <a>{item.title}</a>
                        </li>
                      </Dropdown>
                    );
                  }
                })}
                <li>
                  下载APP
                  <div className={"code-img"}>
                    <div className={"code-img-box"}>
                      <div className={"ios"}>
                        <img src={codeSelect[0]} alt="" />
                        <p>苹果手机下载</p>
                      </div>
                      <div className={"android"}>
                        <img src={codeSelect[1]} alt="" />
                        <p>安卓手机下载</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <LoginChoose />
            </div>
          </div>
        </div>
        <div className={" container-1200 content"}>
          <div className={"line"}></div>
          <div className={"des"}>
            <div>矩阵资产，了解你身边的资产</div>
            <p>开始你的投资之路</p>
          </div>
          <div className={"search"}>
            <div className={"inputItem"}>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                onPressEnter={handleSearch}
                placeholder={"请输入您要搜索的标的物名称"}
              />
            </div>

            <div className={"search_btn"} onClick={handleSearch}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/ece3523e195837c7eccf4a4aa2313d3d.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
