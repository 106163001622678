import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import { Empty, Input } from "antd";
import transPrice from "../../../../../../func/transPrice";
import ajax from "../../../../../../func/ajax";
import { valueToLabel } from "../../../../../../func/fn";
import { claimStatus, is_start_select } from "../../../../../../static/option";
import queryToData from "../../../../../../func/queryToData";

export default () => {
  const [subjectList, setSubjectList] = useState([]);
  const [page, setPage] = useState(1);
  const [jumpPage, setJumpPage] = useState(null);
  const [name, setName] = useState("");
  const size = 5;
  //获取标的列表
  const getObjectList = (newPagination, name) => {
    ajax({
      path: "/creditor/v1.Zc_object/getObjectList",
      data: { page: newPagination, size, name, type: 1 },
      ableEmpty: true,
    }).then((res) => {
      if (res.data.length > 0) {
        res.data.map((item, index) => {
          item.is_start_text = valueToLabel({
            optionArray: is_start_select,
            value: item.is_start,
          });
          item.status_text = valueToLabel({
            optionArray: claimStatus,
            value: item.status,
          });
          return item;
        });
      }
      setSubjectList(res.data);
      window.scrollTo(0, 0);
    });
  };

  useEffect(() => {
    if (queryToData().name) {
      setName(decodeURI(queryToData().name));
      getObjectList(1, decodeURI(queryToData().name));
    }
  }, [queryToData().name]);

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/subject/subjectList/index"}>
              <span>标的物管理</span>
            </Link>
            <Link to={"/creditorUser/subject/onShelf"}>
              <RightOutlined className={"iconArrow"} />
              <span>标的物上架</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>司法拍卖标的物认领</span>
          </li>
        </ul>
      </div>
      <div className={"claimIndex_container"}>
        <div className={"claim_search"}>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onPressEnter={(e) => {
              setPage(1);
              getObjectList(1, e.target.value);
            }}
            placeholder={"请输入区域、商圈或小区开始找房"}
          />
          <div
            className={"search_btn"}
            onClick={() => {
              setPage(1);
              getObjectList(1, name);
            }}
          >
            <img
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/ece3523e195837c7eccf4a4aa2313d3d.png"
              alt=""
            />
          </div>
        </div>
        <ul className={"subjectList_item"}>
          {subjectList.map((item, index) => {
            return (
              <li key={item.id}>
                <Link
                  to={`/creditorUser/subject/subjectDetail?id=${item.id}&type=${item.zc_type}&action=claim&name=${name}`}
                >
                  <div className={"subject_title"}>
                    <span>{item.zctype_name}</span>
                    {item.object_title}
                  </div>
                  <div className={"subject_detail"}>
                    <div className={"subject_detail_item_l"}>
                      标的编号：
                      {item.id}
                    </div>
                    <div className={"subject_detail_item_l"}>
                      处置机构：
                      <span>{item.court_name}</span>
                    </div>
                    <div className={"subject_detail_item_l"}>
                      所属区域：
                      <span>
                        {item.province_name}
                        {item.city_name}
                        {item.district_name}
                      </span>
                    </div>
                    <div>
                      标的类型：
                      <span>{item.second_name}</span>
                    </div>
                    <div>
                      处置平台：
                      <span>{item.source}</span>
                    </div>
                    <div className={"subject_detail_item_l"}>
                      辅助机构：
                      <span>{item.org_name}</span>
                    </div>
                    <div className={"subject_detail_item_l"}>
                      开拍时间：
                      <span>{item.start_time}</span>
                    </div>
                    <div className={"subject_detail_item_l"}>
                      创建时间：
                      <span>{item.create_time}</span>
                    </div>
                    <div>
                      评估价：
                      <span>¥{transPrice(item.assess_price, "zh_cn")}</span>
                    </div>
                    <div>
                      起拍价：
                      <span>¥{transPrice(item.initial_price, "zh_cn")}</span>
                    </div>
                    <div>
                      保证金：
                      <span>¥{transPrice(item.security_deposit, "zh_cn")}</span>
                    </div>
                    <div>
                      加价幅度：
                      <span>¥{transPrice(item.bid_increment, "zh_cn")}</span>
                    </div>
                    <div>
                      竞价周期：
                      <span>{item.bid_cycle}小时</span>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
        {subjectList.length === 0 && <Empty style={{ marginTop: "120px" }} />}
        {(subjectList.length > 0 || page > 1) && (
          <div className={"customize-btn-group textRight"}>
            <div
              onClick={() => {
                setPage(page - 1);
                getObjectList(page - 1, name);
              }}
              className={[
                "ant-pagination-prev",
                page === 1 ? "disable" : "",
              ].join(" ")}
            >
              <button className="ant-pagination-item-link">
                <LeftOutlined />
              </button>
            </div>
            <a className={"page"}>{page}</a>
            <div
              className={[
                "ant-pagination-prev",
                subjectList.length < size ? "disable" : "",
              ].join(" ")}
              onClick={() => {
                setPage(page + 1);
                getObjectList(page + 1, name);
              }}
            >
              <button className="ant-pagination-item-link">
                <RightOutlined />
              </button>
            </div>
            <div className={"ant-pagination-prev"}>
              <div className="ant-pagination-options-quick-jumper">
                跳至
                <Input
                  type="text"
                  onChange={(e) => {
                    setJumpPage(e.target.value);
                  }}
                  onPressEnter={(e) => {
                    if (e.target.value) {
                      setPage(e.target.value);
                      getObjectList(e.target.value, name);
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      setPage(e.target.value);
                      getObjectList(e.target.value, name);
                    }
                  }}
                />
                页
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
