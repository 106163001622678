import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import Head from "../../../component/Head";
import Footer from "../../../component/Footer";
import "./style.scss";
export default () => {
  const [url, setUrl] = useState("/login/c_login");
  useEffect(() => {
    if (localStorage && localStorage.userType === "2") {
      const delete_sign = JSON.parse(localStorage.userInfo).delete_sign;
      if (delete_sign === 0) {
        setUrl("/creditorUser");
      } else {
        setUrl(`/institutionApply?status=${delete_sign}`);
      }
    }
  }, []);

  return (
    <div className={"institutionIndex-container"}>
      <Head currentIndex={5} />
      <div className={"institutionIndex-content"}>
        <div className={"banner"}></div>
        <div className={"pain-points"}>
          <div className={"serviceProviderIndex-mainTitle"}>
            <div> </div>
            <span className={"bold"}>用户痛点</span>
            <div> </div>
          </div>
          <div className={"pain-points-content container-1200"}>
            <img
              className={"pain-points-leftImg"}
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/763696ed1f79eaf8b1c1691bd876e3cb.png"
              alt=""
            />
            <div className={"pain-points-describe"}>
              <div>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/adb1922f89f57193fb59a710ee18836c.png"
                  alt=""
                />
                作为司法拍卖的申请执行人，您是否担心标的物流拍或以底价成交？在矩阵资产平台，您可以认领司法拍卖标的物进行营销推广。
              </div>
              <div>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/9dd76fa2c1deeb15a4261444e16403f5.png"
                  alt=""
                />
                作为银行、信托、小贷公司、典当行、资产管理公司等机构债权人，您是否还在为抵押物的成交而担忧，矩阵资产平台可以帮您进行营销推广。{" "}
              </div>
            </div>
          </div>
        </div>
        <div className={"ourValue"}>
          <div className={"serviceProviderIndex-mainTitle"}>
            <div> </div>
            <span className={"bold"}>我们的价值</span>
            <div> </div>
          </div>
          <div className={"container-1200"}>
            <div className={"ourValue-title"}>
              <div>
                <span>大数据/精准匹配/尽在掌握</span>
              </div>
              <div>
                <span>多渠道/营销推广/专业分析</span>
              </div>
            </div>
            <ul className={"ourValue-content"}>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/8b19c0d13c5e52d120af1bd50c12456a.png"
                  alt=""
                />
                <div className={"ourValue-content-describe"}>
                  <div>意向客群</div>
                  <div>海量高净值潜在客户资源，寻求高价值投资机会。</div>
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/11c2f3c91d7ef69b1e041d700a92b816.png"
                  alt=""
                />
                <div className={"ourValue-content-describe"}>
                  <div>营销推广</div>
                  <div>
                    互联网及本地化优质推广渠道，增加标的物定向曝光，处置精准成交。
                  </div>
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/699a1fff93d19673730dff4284451185.png"
                  alt=""
                />
                <div className={"ourValue-content-describe"}>
                  <div>精准匹配</div>
                  <div>
                    分析资产特点，锁定潜在客户，结合购买需求，一键触达精准撮合。
                  </div>
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/e0a1f8653fb068e4396b9bee360a25ed.png"
                  alt=""
                />
                <div className={"ourValue-content-describe"}>
                  <div>专业分析</div>
                  <div>
                    专业营销团队，多维度分析客户需求、辅助用户决策、提升资产处置率。
                  </div>
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/f90779aed9a18efca57f2200da62b0eb.png"
                  alt=""
                />
                <div className={"ourValue-content-describe"}>
                  <div>资产剖析</div>
                  <div>
                    海量标的处置案例，积累丰富的标的物处置经验，快速锁定目标人群。
                  </div>
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/bd2c8947178149521da8d7acd9b1b293.png"
                  alt=""
                />
                <div className={"ourValue-content-describe"}>
                  <div>专人服务</div>
                  <div>
                    根据资产特点，提供专业、贴心的服务顾问，提供解决方案，为资产的顺利处置出谋划策，全程跟踪资产动态。
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={"saleCase"}>
          <div className={"serviceProviderIndex-mainTitle"}>
            <div> </div>
            <span className={"bold"}>营销案例</span>
            <div> </div>
          </div>
          <ul className={"saleCase-content container-1200"}>
            <li className={"saleCase-item"}>
              <img
                className={"coverImg"}
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/upload/49111366a5dadbfb68b96f325c25ae39.jpeg?x-oss-process=image/resize,h_300,w_400/interlace,1/format,webp"
                alt=""
              />
              <div className={"saleCase-infos"}>
                <div className={"saleCase-item-title"}>
                  徐州市淮海西路255号公交商贸大厦16幢2单元1606
                </div>
                <div className={"saleCase-item-price"}>
                  成 &nbsp;交 &nbsp;价：<span>¥89000元</span>
                </div>
                <div className={"saleCase-item-time"}>
                  成交时间：2020-07-07 10：00：00
                </div>
              </div>
              <img
                className={"auction_status"}
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/acbbb15c85fd576c3b2971a25a5af0d8.png"
                alt=""
              />
            </li>
            <li className={"saleCase-item"}>
              <img
                className={"coverImg"}
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/upload/49111366a5dadbfb68b96f325c25ae39.jpeg?x-oss-process=image/resize,h_300,w_400/interlace,1/format,webp"
                alt=""
              />
              <div className={"saleCase-infos"}>
                <div className={"saleCase-item-title"}>
                  徐州市淮海西路255号公交商贸大厦16幢2单元1606
                </div>
                <div className={"saleCase-item-price"}>
                  成 &nbsp;交 &nbsp;价：<span>¥89000元</span>
                </div>
                <div className={"saleCase-item-time"}>
                  成交时间：2020-07-07 10：00：00
                </div>
              </div>
              <img
                className={"auction_status"}
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/acbbb15c85fd576c3b2971a25a5af0d8.png"
                alt=""
              />
            </li>
          </ul>
        </div>
        <div className={"institution-submit"}>
          <Button type={"primary"}>
            <Link to={url}>申请入驻</Link>
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
