import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Input, Row, Col, message } from "antd";
import formValid from "../../../../func/formValid";
import { PhoneOutlined } from "@ant-design/icons";
import "./style.scss";
import CooperLogin from "../CooperLogin";
import queryToData from "../../../../func/queryToData";
import {
  customerBindPhone,
  getPhoneCode,
  wxLoginWay,
  aliPayLoginWay,
} from "../../../../api/login";

export default () => {
  const history = useHistory();
  const [code, setCode] = useState(
    queryToData().code || queryToData().auth_code || null
  );
  //微信授权后登陆：type=1, 支付宝授权后登陆：type=2
  const [typecode,setTypecode] = useState();
  const [liked, setLiked] = useState(true);
  const [count, setCount] = useState(60);
  const [accredit_id, setAccredit_id] = useState(null);
  const [form] = Form.useForm();

  const handleLogin = () => {
    formValid(form)
      .then(() => {
        let { phone, code } = form.getFieldsValue();
        customerBindPhone({
          accredit_id,
          type:typecode,
          // type: queryToData().code ? 1 : 2,
          code,
          phone,
        })
          .then((res) => {
            if (res.status === 200) {
              localStorage.clear();
              const { data } = res;
              localStorage.userType = 1;
              localStorage.userInfo = JSON.stringify(data);
              localStorage.token = data.token;
              localStorage.timer = new Date().getTime();
              message.success("登录成功");
              setTimeout(() => {
                history.push("/user");
              }, 1000);
            }
          })
          .catch();
      })
      .catch();
  };

  //倒计时
  const sendCode = () => {
    let counts = count - 1;
    const timer = setInterval(() => {
      setCount(counts--);
      setLiked(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked(true);
        setCount(60);
      }
    }, 1000);
  };

  //ajax获取验证码
  const getCode = (phone) => {
    getPhoneCode(phone).then((res) => {
      sendCode();
    });
  };

  //验证码操作
  const verifiedSubmit = () => {
    let { phone } = form.getFieldsValue();
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      getCode(phone);
    } else {
      message.error("请输入正确的手机号");
      return false;
    }
  };

  //微信授权登录
  const wxLogin = (code) => {
    wxLoginWay({ code })
      .then((res) => {
        if (res.status === 200 && res.data.token) {
          localStorage.clear();
          const { data } = res;
          localStorage.userType = 1;
          localStorage.userInfo = JSON.stringify(data);
          localStorage.token = data.token;
          localStorage.timer = new Date().getTime();
          message.success("登录成功");
          setTimeout(() => {
            history.push("/user");
          }, 1000);
        } else {
          setAccredit_id(res.data.accredit_id);
          setTypecode(1)
        }
      })
      .catch();
  };

  // 支付宝授权登录
  const aliPayLogin = (code) => {
    aliPayLoginWay({ auth_code: code })
      .then((res) => {
        if (res.status === 200 && res.data.token) {
          localStorage.clear();
          const { data } = res;
          localStorage.userType = 1;
          localStorage.userInfo = JSON.stringify(data);
          localStorage.token = data.token;
          localStorage.timer = new Date().getTime();
          message.success("登录成功");
          setTimeout(() => {
            history.push("/user");
          }, 1000);
        } else {
          setAccredit_id(res.data.accredit_id);
          setTypecode(2)
        }
      })
      .catch();
  };

  useEffect(() => {
    if (code) {
      if (queryToData().code) {
        wxLogin(code);
      } else {
        aliPayLogin(code);
      }
    }
  }, []);

  return (
    <div className={"certification"}>
      <div className={"left_part"}>
        <div className={"left_part_title"}>绑定手机号</div>
        <Form form={form} name="normal_login" className="login-form">
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                message: "请输入正确的手机号",
              },
            ]}
          >
            <Input
              prefix={
                <PhoneOutlined
                  style={{ color: "#1890FF" }}
                  className="site-form-item-icon"
                />
              }
              placeholder="请输入手机号码"
            />
          </Form.Item>
          <Form.Item>
            <Row gutter={24}>
              <Col span={16}>
                <Form.Item
                  name="code"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "请输入验证码",
                    },
                  ]}
                >
                  <Input placeholder="请输入验证码" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button
                  disabled={liked ? false : true}
                  onClick={verifiedSubmit}
                  className={liked ? "verificationCode" : ""}
                >
                  {liked ? "获取验证码" : count + "秒后重发"}
                </Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <Button
              className={"login-form-button"}
              type="primary"
              onClick={handleLogin}
            >
              绑定
            </Button>
          </Form.Item>
        </Form>
        {/*合作商登录*/}
        <CooperLogin />
      </div>
      <div className={"right_part"}>
        <img
          src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/f6ea3827fc857ae1e3de81ae886ea0b8.jpeg?x-oss-process=image/format,webp"
          alt=""
        />
        <div>下载矩阵资产APP</div>
        <p>随时随地看资产</p>
      </div>
    </div>
  );
};
