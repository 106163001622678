import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import "./style.scss";
import queryToData from "../../../../func/queryToData";
import ajax from "../../../../func/ajax";
import { orderStatus } from "../../../../static/option";

export default () => {
  const [orderDetail, setOrderDetail] = useState(null);

  //获取订单详情
  useEffect(() => {
    const order_id = queryToData().id;
    ajax({
      path: "/customer/User/getOrderDetails",
      data: {
        order_id,
      },
    }).then((res) => {
      orderStatus.map((item) => {
        if (item.value === res.data.trade_status) {
          res.data.trade_status_text = item.label;
          res.data.statusIcon = item.statusIcon;
        }
        return item;
      });
      setOrderDetail(res.data);
    });
  }, []);

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/user/myOrder/myOrderList"}>
              <span>订单管理</span>
            </Link>
          </li>
          <li>
            <Link to={"/user/myOrder/myOrderList"}>
              <RightOutlined className={"iconArrow"} />
              <span>我的订单</span>
            </Link>
          </li>
          <li>
            <RightOutlined className={"iconArrow"} />
            <span>订单详情</span>
          </li>
        </ul>
      </div>
      <div className={"myOrderDetail-container"}>
        <div
          className={[
            "order_status",
            orderDetail?.trade_status === 2 ? "cancel" : "",
          ].join(" ")}
        >
          <div>订单编号：{orderDetail?.order_num}</div>
          <div>
            <img src={orderDetail?.statusIcon} alt="" />
            {orderDetail?.trade_status_text}
          </div>
        </div>
        <div className={"myOrderDetail-infos"}>
          <div className={"myOrderDetail-header"}>订单详情</div>
          <div className={"myOrderDetail-orderNumber"}>商品信息</div>
          <div className={"myOrderDetail-main"}>
            <div className={"order-imgInfo"}>
              <img src={orderDetail?.img_url} alt="" />
              <div>
                <div className={"goods_name"}>{orderDetail?.goods_name}</div>
                <div className={"order-price"}>
                  商品价格： <span>¥{orderDetail?.total_price}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={"myOrderDetail-item"}>
            <div className={"myOrderDetail-item-title"}>订单信息</div>
            <div className={"myOrderDetail-item-infos"}>
              <div>
                <span>创建时间：</span>
                {orderDetail?.order_time}
              </div>
              <div>
                <span>支付时间：</span>
                {orderDetail?.pay_time}
              </div>
              <div>
                <span>支付方式：</span>
                {orderDetail?.pay_type_name}
              </div>
            </div>
          </div>
          <div className={"myOrderDetail-item"}>
            <div className={"myOrderDetail-item-title"}>支付信息</div>
            <div className={"myOrderDetail-item-infos"}>
              <div>
                <span>商品价格：</span>¥ {orderDetail?.goods_price}
              </div>
              <div>
                <span>实付金额：</span>¥ {orderDetail?.fact_price}
              </div>
            </div>
          </div>
          {(orderDetail?.trade_status === 3 ||
            orderDetail?.trade_status === 4) && (
            <>
              <div className={"myOrderDetail-item"}>
                <div className={"myOrderDetail-item-title"}>退款信息</div>
                <div className={"myOrderDetail-item-infos"}>
                  <div>
                    <span>申请时间：</span>
                    {orderDetail?.apply_time}
                  </div>
                  <div>
                    <span>申请方：</span>
                    {orderDetail?.m_name}
                  </div>
                  <div>
                    <span>退款方式：</span>
                    {orderDetail?.pay_type_name}
                  </div>
                  <div>
                    <span>退款金额：</span>¥ {orderDetail?.fact_price}
                  </div>
                </div>
              </div>
              {orderDetail?.trade_status === 4 && (
                <div className={"myOrderDetail-item"}>
                  <div className={"myOrderDetail-item-title"}>处理状态</div>
                  <div className={"myOrderDetail-item-infos"}>
                    <div>
                      <span>处理结果：</span>
                      {orderDetail?.examine_status === 1
                        ? "同意退款"
                        : "拒绝退款"}
                    </div>
                    <div>
                      <span>审核时间：</span>
                      {orderDetail?.examine_time}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {orderDetail?.trade_status === 2 && (
            <div className={"myOrderDetail-item"}>
              <div className={"myOrderDetail-item-title"}>取消信息</div>
              <div className={"myOrderDetail-item-infos"}>
                <div>
                  <span>取消时间：</span>
                  {orderDetail?.cancel_time}
                </div>
                <div>
                  <span>取消原因：</span>
                  {orderDetail?.cancel_reason}
                </div>
              </div>
            </div>
          )}
        </div>
        {orderDetail?.trade_status === 0 && (
          <div className={"handleOrder"}>
            <Button>取消订单</Button>
            <Button type={"primary"}>去付款</Button>
          </div>
        )}
      </div>
    </div>
  );
};
