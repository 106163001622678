export const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
    md: { span: 6 },
  },
  wrapperCol: {
    sm: { span: 16 },
    md: { span: 18 },
  },
};

export const defaultGutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

export const editFormColSpan = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};
