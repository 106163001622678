// 表格头部项目
import React from "react";

const columns = () => {
  return [
    {
      title: "流水号",
      width: 180,
      dataIndex: "order_num",
      fixed: "left",
    },
    {
      title: "类型",
      width: 100,
      dataIndex: "t_trade_type",
      fixed: "left",
    },
    { title: "金额", dataIndex: "price", width: 100 },
    { title: "支付方式", dataIndex: "pay_type_text", width: 100 },
    { title: "支付时间", dataIndex: "createtime", width: 200 },
  ];
};

export default columns;
