import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux-immutable";
import rootReducer from "./rootReducer";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    rootReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
