import React, { useEffect } from "react";
import Head from "../../component/Head";
import Footer from "../../component/Footer";
import BlankLayout from "./BlankLayout";
import { useHistory } from "react-router-dom";
import "./style.scss";
export default () => {
  const history = useHistory();
  useEffect(() => {
    if (localStorage.userType !== "2") {
      history.push("/?callback=logout");
    }
  }, []);
  return (
    <div className={"user_container"}>
      <Head />
      <div className={"user_content"}>
        <div className={"user_content_main"}>
          <div className={"container-1200"}>
            <div className={"user_content_middle"}>
              <BlankLayout />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
