import React, { useState } from "react";
import { Link } from "react-router-dom";
import { codeSelect } from "../../static/option";
import LoginChoose from "./LoginChoose";

import { Dropdown, Menu } from "antd";

import "./style.scss";
const navItem = [
  { title: "首页", path: "/" },
  { title: "司法拍卖", path: "/search?currentIndex=1" },
  { title: "流拍寻宝", path: "/search?currentIndex=2" },
  { title: "资产拍卖", path: "/search?currentIndex=3" },
  { title: "资产推介", path: "/search?currentIndex=4" },
  { title: "入驻" },
];
const navSettledItem = [
  { title: "品牌商加盟", path: "/serviceProvider" },
  { title: "经纪人加盟", path: "/brokerIndex" },
  { title: "债权人加盟", path: "/institution" },
  { title: "供应商加盟", path: "/supplierApply" },
];

export default (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showNavSettledItem, setShowNavSettledItem] = useState(false);

  const menu = (navSettledItem, index) => {
    return (
      <Menu>
        {navSettledItem.map((item) => {
          return (
            <Menu.Item
              key={item.path}
              onClick={() => {
                console.log(props);
                setCurrentIndex(5);
                setShowNavSettledItem(false);
              }}
            >
              <Link to={item.path}>{item.title}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <div className={"common_header"}>
      <div className={"top"}>
        <div className={"top_content container-1200"}>
          <div className={"band"}>
            <Link to={"/"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/b2f4c147af9e4673f1432d2c4ce30c20.png"
                alt=""
              />
              矩阵资产
            </Link>
          </div>
          <div className={"right"}>
            <ul className={"navbar"}>
              {navItem.map((item, index) => {
                if (index !== navItem.length - 1) {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setCurrentIndex(index);
                      }}
                      className={
                        index === parseInt(props.currentIndex) ? "active" : ""
                      }
                    >
                      <Link to={item.path}>{item.title}</Link>
                    </li>
                  );
                } else {
                  return (
                    <Dropdown
                      overlay={menu(navSettledItem, index)}
                      visible={showNavSettledItem}
                      onVisibleChange={(visible) => {
                        setShowNavSettledItem(visible);
                      }}
                      key={index}
                      placement="bottomCenter"
                    >
                      <li
                        className={
                          parseInt(props.currentIndex) === 5 ? "active" : ""
                        }
                      >
                        <a>{item.title}</a>
                      </li>
                    </Dropdown>
                  );
                }
              })}
              <li>
                下载APP
                <div className={"code-img"}>
                  <div className={"code-img-box"}>
                    <div className={"android"}>
                      <img src={codeSelect[0]} alt="" />
                      <p>苹果手机下载</p>
                    </div>
                    <div className={"ios"}>
                      <img src={codeSelect[1]} alt="" />
                      <p>安卓手机下载</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <LoginChoose />
          </div>
        </div>
      </div>
    </div>
  );
};
