import React, { useEffect, useState } from "react";
import "./style.scss";
import ajax from "../../func/ajax";
import transPrice from "../../func/transPrice";
import { sourceSelect, codeSelect } from "../../static/option";
export default (props) => {
  const [objectList, setObjectList] = useState([]);
  useEffect(() => {
    const ajaxUrl =
      props.currentIndex === "-1"
        ? "/customer/Index/HotObjcetListFailure"
        : "/customer/Index/HotObjcetList";
    ajax({
      path: ajaxUrl,
      data: { zc_type: props.currentIndex === "-1" ? "" : props.currentIndex },
      isNeedToken: true,
      ableEmpty: true,
    }).then((res) => {
      if (res.status === 200) {
        if (res.list.length > 0) {
          res.list.map((item) => {
            sourceSelect.map((sourceItem) => {
              if (sourceItem.label === item.source) {
                item.sourceIcon = sourceItem.img_path;
              }
            });
            return item;
          });
        }
        setObjectList(res.list);
      }
    });
  }, [props.currentIndex]);

  return (
    <div className={"hotObject"}>
      <div className={"hotObject__title"}>
        <img
          src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/670a5550c7ebc1e17b68379859a9bb78.png"
          alt=""
        />
        热门标的物
      </div>
      {objectList && objectList.length > 0 && (
        <div className={"hotObject__content"}>
          <ul className={"hotObject_ul"}>
            {objectList.map((item, index) => {
              return (
                <li key={index} className={"hotObject_li"}>
                  {item.zc_type === 1 ? (
                    <span className={"tagName"}>司法拍卖</span>
                  ) : item.zc_type === 2 ? (
                    <span className={"tagName blue"}>资产拍卖</span>
                  ) : (
                    <span className={"tagName red"}>资产推介</span>
                  )}

                  <div className={"cover-img"}>
                    <div
                      className={"cover-img-content"}
                      style={{ backgroundImage: `url(${item.img_path})` }}
                    />
                    <div className={"title"}>{item.object_title}</div>
                  </div>
                  <ul className={"infos"}>
                    {item.zc_type === 3 || (
                      <li>
                        {props.currentIndex === "-1" ? (
                          <div className={"des"}>
                            <span>当</span>
                            <span>前</span>
                            <span>价：</span>
                          </div>
                        ) : (
                          <div className={"des"}>
                            <span>起</span>
                            <span>拍</span>
                            <span>价：</span>
                          </div>
                        )}

                        <div className={"start_price"}>
                          ¥
                          <span>{transPrice(item.initial_price, "zh_cn")}</span>
                        </div>
                      </li>
                    )}
                    {item.zc_type === 3 && (
                      <li>
                        <div className={"des"}>
                          <span>标</span>
                          <span>的</span>
                          <span>编</span>
                          <span>号：</span>
                        </div>
                        <div>
                          <span>{item.id}</span>
                        </div>
                      </li>
                    )}
                    <li>
                      <div className={"des"}>
                        <span>评</span>
                        <span>估</span>
                        <span>价：</span>
                      </div>
                      <div className={"assess_price"}>
                        ¥{transPrice(item.assess_price, "zh_cn")}
                      </div>
                    </li>
                    {item.zc_type === 3 && (
                      <li>
                        <div className={"des"}>
                          <span>标</span>
                          <span>的</span>
                          <span>类</span>
                          <span>型：</span>
                        </div>
                        <div>
                          <span>{item.second_class}</span>
                        </div>
                      </li>
                    )}
                    {item.zc_type === 3 || (
                      <li>
                        {props.currentIndex === "-1" ? (
                          <div className={"des"}>
                            <span>上</span>
                            <span>架</span>
                            <span>时</span>
                            <span>间：</span>
                          </div>
                        ) : (
                          <div className={"des"}>
                            <span>开</span>
                            <span>拍</span>
                            <span>时</span>
                            <span>间：</span>
                          </div>
                        )}
                        <div className={"start_time"}>
                          <span>
                            {props.currentIndex === "-1"
                              ? item.create_time?.substring(5)
                              : item.start_time?.substring(5)}
                          </span>
                          {props.currentIndex === "-1" ? (
                            <span className={"auction_status"}>
                              {item.disposal_status === 1
                                ? "待确认"
                                : item.disposal_status === 2
                                ? "可处置"
                                : "不可处置"}
                            </span>
                          ) : (
                            <span className={"auction_status"}>
                              {item.object_status}
                            </span>
                          )}
                        </div>
                      </li>
                    )}
                  </ul>
                  <div className={"numbers"}>
                    <img src={item.sourceIcon} alt="" />
                    <div>
                      <span>围观 </span>
                      <b> {item.own_looker_num}</b>
                      <span> | </span>
                      <span>咨询 </span>
                      <b>{item.consult_num}</b>
                    </div>
                  </div>
                  <div className={"coverCode"}>
                    <div className={"coverCode-content"}>
                      <div>
                        <img src={codeSelect[0]} alt="" />
                        <span>ios下载</span>
                      </div>
                      <div>
                        <img src={codeSelect[1]} alt="" />
                        <span>Android下载</span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
