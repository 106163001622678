import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./style.scss";
import { Menu, Dropdown } from "antd";

export default () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState(null);

  const handleLogout = () => {
    if (pathname.match("creditorUser") || pathname.match("user")) {
      history.push("/?callback=logout");
    } else {
      localStorage.clear();
      history.push("/?callback=logout");
      window.location.reload();
    }
  };
  const avatarMenu = (
    <Menu>
      <Menu.Item>
        <span className={"login-choose-logout"} onClick={handleLogout}>
          退出登录
        </span>
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    if (localStorage.userType) {
      setUserInfo(JSON.parse(localStorage.userInfo));
    }
  }, []);
  return (
    <ul className={"login-choose"}>
      <li>
        {localStorage.userType === "1" ? (
          <div className={"gUser_name"}>
            <Dropdown overlay={avatarMenu} placement="bottomCenter" arrow>
              {/*user*/}
              <Link to={"/user"}>
                <img
                  src={
                    userInfo?.avatar
                      ? userInfo.avatar
                      : "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/575d25d8db58950f70076c1c900f539f.png"
                  }
                  alt=""
                />
                {userInfo?.nickname || userInfo?.phone}
              </Link>
            </Dropdown>
          </div>
        ) : (
          <div>
            <img
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/8c40e6d0c2203ca0fa6d209d76f877e4.png"
              alt=""
            />
            <Link to={"/login/g_login"}>用户登录</Link>
          </div>
        )}
      </li>
      <li>
        {localStorage.userType === "2" ? (
          <div className={"creditorUser_name"}>
            <Dropdown overlay={avatarMenu} placement="bottomCenter" arrow>
              {/*creditorUser*/}
              <Link to={userInfo?.delete_sign === 0 ? "/creditorUser" : ""}>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/79e737b926fbd5d12b637aaae307c8e9.png"
                  alt=""
                />
                {userInfo?.realname}
              </Link>
            </Dropdown>
          </div>
        ) : (
          <div>
            <img
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/8c40e6d0c2203ca0fa6d209d76f877e4.png"
              alt=""
            />
            <Link to={"/login/c_login"}>债权人登录</Link>
          </div>
        )}
      </li>
    </ul>
  );
};
