import React, { useEffect } from "react";
import Head from "../../component/Head";
import Footer from "../../component/Footer";
import CreditorLogin from "./LoginModal/CreditorLogin";
import GeneralLogin from "./LoginModal/GeneralLogin";
import GeneralRegister from "./LoginModal/GeneralRegister";
import CForgetPassword from "./LoginModal/ForgetPassword/c_forgetPassword";
import GForgetPassword from "./LoginModal/ForgetPassword/g_forgetPassword";
import Certification from "./LoginModal/certification";
import { Switch, Route } from "react-router-dom";
import "./style.scss";

export default (props) => {
  useEffect(() => {
    localStorage.clear();
    return () => {};
  });
  return (
    <div className={"login_handle_box main-content"}>
      <Head />
      <div className={"login_container"}>
        <div className={"login-content"}>
          <Switch>
            <Route path={"/login/g_login"} exact>
              <GeneralLogin />
            </Route>
            <Route path={"/login/g_register"} exact>
              <GeneralRegister />
            </Route>
            <Route path={"/login/c_login"}>
              <CreditorLogin />
            </Route>
            <Route path={"/login/c_forgetPassword"}>
              <CForgetPassword />
            </Route>
            <Route path={"/login/g_forgetPassword"}>
              <GForgetPassword />
            </Route>
            <Route path={"/login/certification"}>
              <Certification />
            </Route>
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};
