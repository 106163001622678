const queryToData = () => {
  const queryObj = {};
  const searchArray = window.location.search.slice(1).split("&");
  searchArray.map(item => {
    const _keyValue = item.split("=");
    queryObj[_keyValue[0]] = _keyValue[1];
    return item;
  });
  return queryObj;
};

export default queryToData;
