import React, { useState, useEffect } from "react";

import "./style.scss";
import { Button } from "antd";
import { ForwardOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import queryToData from "../../../../func/queryToData";

export default () => {
  const [queryData, setQueryData] = useState(null);
  useEffect(() => {
    setQueryData(queryToData());
  }, []);
  return (
    <>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/subject/subjectList"}>
              <span>标的物管理</span>
            </Link>
            <RightOutlined style={{ color: "#999999" }} />
            <span>标的物推广介绍页</span>
          </li>
        </ul>
      </div>
      <div className={"promoteIndex-container"}>
        <div className={"promoteIndex-item promoteIndex-item1"}>
          <div className={"oneSide"}>
            <img
              className={"topPic"}
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/53503037a8df2bbdcf18b0fb80fd0040.png"
              alt=""
            />
            <div className={"desInfos desInfos-left"}>
              <div className={"title"}>优势</div>
              <ul className={"desInfos-item"}>
                <li>1、醒目首页广告位 </li>
                <li>2、海量客户群体</li>
              </ul>
            </div>
          </div>
          <img
            className={"picShow"}
            src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/71f857bc7582fa36825acfeda197144d.png"
            alt=""
          />
        </div>
        <div className={"promoteIndex-item promoteIndex-item2"}>
          <div className={"oneSide"}>
            <img
              className={"topPic"}
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/36f7b7b19496707ca2e8f69ce227b35a.png"
              alt=""
            />
            <div className={"desInfos desInfos-right"}>
              <div className={"title"}>优势</div>
              <ul className={"desInfos-item"}>
                <li>1、用户搜索标的物的必经之路 </li>
                <li>2、海量客户群体</li>
              </ul>
            </div>
          </div>
          <img
            className={"picShow"}
            src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/15d0ddc28adc15273bb756057cb03a57.png"
            alt=""
          />
        </div>
        <div
          className={"promoteIndex-item promoteIndex-item1 promoteIndex-item3"}
        >
          <div className={"oneSide"}>
            <img
              className={"topPic"}
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/d4bd70560574e5edb5c752ecf293552b.png"
              alt=""
            />
            <div className={"desInfos desInfos-left"}>
              <div className={"title"}>优势</div>
              <ul className={"desInfos-item"}>
                <li>1、法拍客户精准触达</li>
                <li>2、微信渠道宣发，量变转为质变</li>
                <li>3、资产亮点突出</li>
              </ul>
            </div>
          </div>
          <img
            className={"picShow"}
            src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/dc8964bd555c7daab115f25c066a833d.png"
            alt=""
          />
        </div>
        <div
          className={"promoteIndex-item promoteIndex-item2 promoteIndex-item4"}
        >
          <div className={"oneSide"}>
            <img
              className={"topPic"}
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/ba6bb7632ebf288a0abdad982e0d9228.png"
              alt=""
            />
            <div className={"desInfos desInfos-right"}>
              <div className={"title"}>优势</div>
              <ul className={"desInfos-item"}>
                <li>1、扩大用户群体，吸引更多的围观 </li>
                <li>2、多渠道宣发，量变转为质变</li>
              </ul>
            </div>
          </div>
          <img
            className={"picShow"}
            src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/8bd0c6360776359975a7cb1a99bb13fe.png"
            alt=""
          />
        </div>
        <div className={"promoteIndex-handle-btn "}>
          <Button type={"primary"}>
            <Link
              to={`/creditorUser/subject/subjectPromote?id=${queryData?.id}&type=${queryData?.type}&isCreate=true`}
            >
              去推广
            </Link>
          </Button>
        </div>
      </div>
    </>
  );
};
