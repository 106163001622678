import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Input, message } from "antd";
import formValid from "../../../../func/formValid";
import { PhoneOutlined } from "@ant-design/icons";
import "./style.scss";
import CooperLogin from "../CooperLogin";
import { customerLogin } from "../../../../api/login";
import { codeSelect } from "../../../../static/option";

export default () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const handleLogin = () => {
    formValid(form)
      .then(() => {
        let { username, password } = form.getFieldsValue();
        customerLogin({ username, password })
          .then((res) => {
            localStorage.clear();
            const { data } = res;
            localStorage.userType = 1;
            localStorage.userInfo = JSON.stringify(data);
            localStorage.token = data.token;
            localStorage.timer = new Date().getTime();
            message.success("登录成功");
            setTimeout(() => {
              history.push("/user");
            }, 1000);
          })
          .catch();
      })
      .catch();
  };

  return (
    <div className={"general_login"}>
      <div className={"left_part"}>
        <div className={"left_part_title"}>普通用户登录</div>
        <Form form={form} name="normal_login" className="login-form">
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "请输入手机号码",
              },
            ]}
          >
            <Input
              prefix={
                <PhoneOutlined
                  style={{ color: "#1890FF" }}
                  className="site-form-item-icon"
                />
              }
              placeholder="请输入手机号码"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input.Password
              onPressEnter={handleLogin}
              type="password"
              placeholder="请输入密码"
            />
          </Form.Item>

          <Form.Item>
            <Button
              className={"login-form-button"}
              type="primary"
              onClick={handleLogin}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
        <div className={"handle_password"}>
          <Link to={"/login/g_forgetPassword"}>忘记密码</Link>
          <div>
            还没有账号马上<Link to={"/login/g_register"}>注册</Link>
          </div>
        </div>
        {/*合作商登录*/}
        <CooperLogin />
      </div>
      <div className={"right_part"}>
      <div className={"code-img"}>
            <div className={"android"}>
              <img src={codeSelect[0]} alt="" />
              <p>苹果手机下载</p>
            </div>
            <div className={"ios"}>
              <img src={codeSelect[1]} alt="" />
              <p>安卓手机下载</p>
            </div>
          </div>
        <div>下载矩阵资产APP</div>
        <p>随时随地看资产</p>
      </div>
    </div>
  );
};
