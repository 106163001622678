import React, { useEffect, useState } from "react";
import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
import { Form, Input, Button, Row, Col, message } from "antd";
import { Link } from "react-router-dom";
import formValid from "../../../../../func/formValid";
import ajax from "../../../../../func/ajax";
import dataFillForm from "../../../../../func/dataFillForm";
import queryToData from "../../../../../func/queryToData";
const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
    md: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
    md: { span: 16 },
  },
};
export const defaultGutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};
export const editFormColSpan = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 20,
};

export default () => {
  const [form] = Form.useForm();
  const [editable, setEditAble] = useState(true);

  const handleSubmit = () => {
    formValid(form)
      .then(() => {
        let {
          bank_name,
          bank_address,
          bank_branch,
          bank_account_name,
          bank_account_num,
        } = form.getFieldsValue();
        ajax({
          path: "/creditor/v1/bank",
          data: {
            bank_name,
            bank_address,
            bank_branch,
            bank_account_name,
            bank_account_num,
          },
          isNeedToken: false,
        }).then((res) => {
          if (res.status === 200) {
            message.success("操作成功");
            getApplyInfo();
          }
        });
      })
      .catch();
  };
  //判断是修改还是查看
  useEffect(() => {
    if (queryToData().action) {
      setEditAble(false);
    }
  }, []);

  //获取债权人申请信息
  const getApplyInfo = () => {
    ajax({
      path: "/creditor/v1/merchants",
      method: "get",
    }).then((res) => {
      let data = res;
      dataFillForm({
        form,
        data,
      });
    });
  };

  useEffect(() => {
    getApplyInfo();
  }, []);

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>账户管理</span>
            <Link to={"/creditorUser/account/certification/index"}>
              <RightOutlined style={{ color: "#999999" }} />
              <span>认证管理</span>
            </Link>
            <RightOutlined style={{ color: "#999999" }} />
            <span>开户信息</span>
          </li>
        </ul>
      </div>
      <div className={"bankDetail_container"}>
        <div className={"bankDetail_title"}>开户信息</div>
        <Form form={form} {...formItemLayout}>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"开户行所在地"}
                name="bank_address"
                rules={[{ required: true, message: "该项为必填项" }]}
              >
                <Input
                  disabled={!editable}
                  type={"email"}
                  placeholder={"请输入开户银行所在地"}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"开户支行名称"}
                name="bank_branch"
                rules={[{ required: true, message: "该项为必填项" }]}
              >
                <Input
                  placeholder={"请输入开户支行名称"}
                  disabled={!editable}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"银行账号"}
                name="bank_account_num"
                rules={[{ required: true, message: "该项为必填项" }]}
              >
                <Input
                  placeholder={"请输入银行账号"}
                  disabled={!editable}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"开户行"}
                name="bank_name"
                rules={[{ required: true, message: "该项为必填项" }]}
              >
                <Input
                  placeholder={"请输入开户行"}
                  disabled={!editable}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"银行账户名"}
                name="bank_account_name"
                rules={[{ required: true, message: "该项为必填项" }]}
              >
                <Input
                  placeholder={"请输入账户名"}
                  disabled={!editable}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className={"handle_submit"}>
          <Button onClick={handleSubmit} type={"primary"} disabled={!editable}>
            保存
          </Button>
        </div>
      </div>
    </div>
  );
};
