import React, { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Button } from "antd";
import "./style.scss";
import ajax from "../../../../func/ajax";
import { Empty } from "antd";

export default () => {
  const [graObject, setGraObject] = useState(null);
  const [balance, setBalance] = useState(0);
  const [cashData, setCashData] = useState(null);

  useEffect(() => {
    graObjectStat();
    getBalance();
    getCashlist();
  }, []);

  //标的物统计
  const graObjectStat = () => {
    ajax({
      path: "/creditor/v1.Zc_object/graObjectStat",
    }).then((res) => {
      if (res.status === 200) {
        setGraObject(res.data);
      }
    });
  };

  //提现记录
  const getCashlist = () => {
    ajax({
      path: "/creditor/v1/cashlist",
      data: { page: 1, size: 2 },
      method: "get",
    }).then((res) => {
      if (res.data.data) {
        setCashData(res.data.data);
      }
    });
  };

  //账户余额
  const getBalance = () => {
    ajax({
      path: "/creditor/v1/balance",
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        setBalance(res.balance);
      }
    });
  };

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>账户管理</span>
          </li>
          <li>
            <RightOutlined
              className={"iconArrow"}
              style={{ color: "#999999" }}
            />
            <span>商户概况</span>
          </li>
        </ul>
      </div>
      <div className={"manageAccountIndex_container"}>
        <div className={"manageAccountIndex_header"}>
          <div className={"withdrawal"}>
            <div className={"withdrawal_title"}>余额️</div>
            <div className={"withdrawal_price"}>
              {balance}
              <span>元</span>
            </div>
            <div className={"withdrawal_handle"}>
              <Link to={"/creditorUser/finance/financeAccount"}>提现</Link>
            </div>
          </div>
          <div className={"manageAccountIndex_record"}>
            <div className={"object_manage_title"}>
              <h3>提现记录</h3>
              <Link to={"/creditorUser/finance/financeAccount"}>
                查看全部 <RightOutlined />
              </Link>
            </div>
            <div className={"withdraw_record"}>
              {cashData && (
                <ul>
                  <li>
                    <div>类型</div>
                    <div>金额</div>
                    <div>提现状态</div>
                  </li>
                  {cashData.map((item) => {
                    return (
                      <li key={item.id}>
                        <div>提现</div>
                        <div>{item.price}</div>
                        <div className={item.status === 3 ? "refuse" : ""}>
                          {item.status === 1
                            ? "待审核"
                            : item.status === 2
                            ? "已提现"
                            : "提现拒绝"}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
              {!cashData && <Empty style={{ paddingTop: "20px" }} />}
            </div>
          </div>
        </div>
        <div className={"manageAccountIndex_objectManage"}>
          <div className={"objectManage_header"}>
            <div className={"objectManage_header-left"}>标的物管理</div>
            <div className={"objectManage_header-right"}>
              <Link to={"/creditorUser/subject/subjectList"}>
                查看全部 <RightOutlined />
              </Link>
            </div>
          </div>
          <div className={"objectManage_data"}>
            <div className={"objectManage_data_item"}>
              <img
                className={"item-icon"}
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/a9854f141e5f29fca3bb1b0f1062831d.png"
                alt=""
              />
              <div className={"item-title"}>司法拍卖标的物</div>
              <div className={"data"}>
                <div className={"data-item"}>
                  <p className={"count"}>{graObject?.judicial.judicial_win}</p>
                  <p className={"name"}>已上架</p>
                </div>
                <div className={"data-item"}>
                  <p className={"count active"}>
                    {graObject?.judicial.judicial_sustain}
                  </p>
                  <p className={"name"}>审核中</p>
                </div>
                <div className={"data-item"}>
                  <p className={"count"}>
                    {graObject?.judicial.judicial_spread}
                  </p>
                  <p className={"name"}>已推广</p>
                </div>
              </div>
            </div>
            <div className={"objectManage_data_item"}>
              <img
                className={"item-icon"}
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/01facd4fe887265ad3f1ee1fc469239a.png"
                alt=""
              />
              <div className={"item-title"}>资产拍卖标的物</div>
              <div className={"data"}>
                <div className={"data-item"}>
                  <p className={"count"}>{graObject?.object.object_win}</p>
                  <p className={"name"}>已上架</p>
                </div>
                <div className={"data-item"}>
                  <p className={"count active"}>
                    {graObject?.object.object_sustain}
                  </p>
                  <p className={"name"}>审核中</p>
                </div>
                <div className={"data-item"}>
                  <p className={"count"}>{graObject?.object.object_spread}</p>
                  <p className={"name"}>已推广</p>
                </div>
              </div>
            </div>
            <div className={"objectManage_data_item"}>
              <img
                className={"item-icon"}
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/b5f736eade43a67e79db64f0c2c6d3be.png"
                alt=""
              />
              <div className={"item-title"}>资产推介标的物</div>
              <div className={"data"}>
                <div className={"data-item"}>
                  <p className={"count"}>{graObject?.recomm.recomm_win}</p>
                  <p className={"name"}>已上架</p>
                </div>
                <div className={"data-item"}>
                  <p className={"count active"}>
                    {graObject?.recomm.recomm_sustain}
                  </p>
                  <p className={"name"}>审核中</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
