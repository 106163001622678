import React, { useState, useEffect } from "react";

import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
import ajax from "../../../../func/ajax";
import queryToData from "../../../../func/queryToData";
import { orderStatus, payType } from "../../../../static/option";
import { valueToLabel } from "../../../../func/fn";
import { Link } from "react-router-dom";

export default () => {
  const [orderDetail, serOrderDetail] = useState({});

  const getOrderDetail = () => {
    ajax({
      path: `/creditor/v1/order/${queryToData().id}`,
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        orderStatus.map((item) => {
          if (item.value === res.data.trade_status) {
            res.data.statusIcon = item.statusIcon;
            res.data.trade_status_text = item.label;
          }
        });
        res.data.pay_type_text = valueToLabel({
          optionArray: payType,
          value: res.data.pay_type,
        });
        serOrderDetail(res.data);
      }
    });
  };
  useEffect(() => {
    getOrderDetail();
  }, []);

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/finance/financeList"}>
              <span>财务管理</span>
            </Link>
            <Link to={"/creditorUser/finance/financeList"}>
              <RightOutlined className={"iconArrow"} />
              <span>财务报表</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>交易明细</span>
          </li>
        </ul>
      </div>
      <div className={"financeDetail_container"}>
        <div
          className={[
            "order_status",
            orderDetail?.trade_status === 2 ? "cancel" : "",
          ].join(" ")}
        >
          <div>订单编号：{orderDetail?.order_num}</div>
          <div>
            <img src={orderDetail?.statusIcon} alt="" />
            {orderDetail?.trade_status_text}
          </div>
        </div>
        <div className={"financeDetail_content"}>
          <div className={"financeDetail_title"}>交易明细</div>
          <div className={"financeDetail_item"}>
            <div className={"financeDetail_item_title"}>商品信息</div>
            <ul>
              <li>
                <span>商品名称：</span>
                {orderDetail?.snap_name}
              </li>
              <li>
                <span>商品金额：</span>¥{orderDetail?.total_price}
              </li>
              <li>
                <span>推广标的物：</span>
                {orderDetail?.o_object_title}
              </li>
            </ul>
          </div>
          <div className={"financeDetail_item service"}>
            <div className={"financeDetail_item_title"}>服务信息</div>
            <ul>
              <li>
                <span>详情信息：</span>
                {orderDetail?.snap_desc}
              </li>
              <li>
                <span>推广时间：</span>
                {orderDetail?.start_time} - {orderDetail?.end_time}
              </li>
            </ul>
          </div>
          <div className={"financeDetail_item order_infos"}>
            <div className={"financeDetail_item_title"}>订单信息</div>
            <ul>
              <li>
                <span>创建时间：</span>
                {orderDetail?.order_time}
              </li>
              <li>
                <span>支付时间：</span>
                {orderDetail?.pay_time}
              </li>
              {orderDetail?.trade_status === 4 && (
                <li>
                  <span>交易类型：</span>
                  收入
                </li>
              )}
              {orderDetail?.trade_status === 1 && (
                <li>
                  <span>交易类型：</span>
                  支出
                </li>
              )}
              <li>
                <span>支付方式：</span>
                {orderDetail?.pay_type_text}
              </li>
            </ul>
          </div>
          {orderDetail?.trade_status === 1 && orderDetail?.refund_sign === 2 && (
            <>
              <div className={"financeDetail_item refund_infos"}>
                <div className={"financeDetail_item_title"}>退款信息</div>
                <ul>
                  <li>
                    <span>处理时间：</span>
                    {orderDetail?.refund_time}
                  </li>
                  <li>
                    <span>退款方：</span>
                    平台
                  </li>
                  <li>
                    <span>退款类型：</span>
                    部分退款
                  </li>
                </ul>
                <ul>
                  <li>
                    <span>退款方式：</span>
                    {orderDetail?.pay_type_text}
                  </li>
                  <li>
                    <span>退款金额：</span>
                    {orderDetail?.refund_amount}
                  </li>
                  <li>
                    <span>退回原因：</span>
                    {orderDetail?.refund_reason}
                  </li>
                </ul>
              </div>
            </>
          )}

          {orderDetail?.trade_status === 4 && (
            <>
              <div className={"financeDetail_item refund_infos"}>
                <div className={"financeDetail_item_title"}>退款信息</div>
                <ul>
                  <li>
                    <span>处理时间：</span>
                    {orderDetail?.refund_time}
                  </li>
                  <li>
                    <span>退款方：</span>
                    平台
                  </li>
                  <li>
                    <span>退款类型：</span>
                    全额退款
                  </li>
                  <li>
                    <span>退款方式：</span>
                    {orderDetail?.pay_type_text}
                  </li>
                  <li>
                    <span>退款金额：</span>
                    {orderDetail?.refund_amount}
                  </li>
                  <li>
                    <span>退回原因：</span>
                    {orderDetail?.refund_reason}
                  </li>
                </ul>
              </div>
            </>
          )}

          {orderDetail?.trade_status === 2 && (
            <div className={"financeDetail_item refund_result"}>
              <div className={"financeDetail_item_title"}>取消信息</div>
              <ul>
                <li>
                  <span>取消时间：</span>
                  {orderDetail?.cancel_time}
                </li>
                <li>
                  <span>取消原因：</span>
                  {orderDetail?.cancel_reason}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
