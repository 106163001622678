import { message } from "antd";

const formValid = async form => {
  try {
    await form.validateFields();
    return Promise.resolve();
  } catch (errorInfo) {
    message.error("请按要求将表单填写完整");
    return Promise.reject();
  }
};

export default formValid;
