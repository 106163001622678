import React, { useState, useEffect, useRef } from "react";

import "./style.scss";
import { DatePicker, Radio, Checkbox, Button, Modal, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import queryToData from "../../../../func/queryToData";
import ajax from "../../../../func/ajax";

import QRcode from "qrcode.react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
let payDatas = {};
const apiPath =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_ENV === "stage"
    ? "http://assets-pc.m-satrix.com/creditor/v1/pre_order"
    : "http://assets-pc.m-satrix.com/creditor/v1/pre_order";

export default () => {
  const history = useHistory();
  const [payType, setPayType] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [objDetail, setObjDetail] = useState({});
  const dateRef = useRef({});
  const id = useRef(123);
  const [promote_time, setPromote_time] = useState([]);
  const aliPayActionUrl = apiPath;
  const [visible, setVisible] = useState(false);
  const [packageDetail, setPackageDetail] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [aliPayData, setAliPayData] = useState({
    start_time: "",
    end_time: "",
    pay_type: "",
    source: "1",
  });
  const [codeMsg, setCodeMsg] = useState(null);
  const [packageActive, setPackageActive] = useState(null);
  const [checked, setChecked] = useState(false);

  //已完成支付确认
  const handleConfirmPay = (id) => {
    ajax({
      path: "/creditor/v1/paystutas",
      data: { id },
    }).then((res) => {
      if (
        res.status === 200 &&
        (res.data.trade_state === "SUCCESS" ||
          res.data.tradeStatus === "TRADE_SUCCESS")
      ) {
        setVisible(false);
        message.success("支付成功");
        window.scrollTo(0, 0);
        setTimeout(() => {
          history.push(
            `/creditorUser/subject/subjectPromote?id=${queryToData().id}&type=${
              queryToData().type
            }`
          );
          window.location.reload();
        }, 1000);
      } else {
        setVisible(false);
        Modal.info({
          title: "支付失败",
          content: <>支付失败，请联系客服</>,
        });
      }
    });
  };

  const getObjectDetail = (id, type) => {
    ajax({
      path: "/creditor/v1.Zc_object/getObjectDetails",
      data: { id: id, type: type },
    }).then((res) => {
      setObjDetail(res.data);
    });
  };
  const getObjectTgDetails = (id) => {
    ajax({
      path: "/creditor/v1.Zc_object/getObjectTgDetails",
      data: { id },
    }).then((res) => {
      setObjDetail(res.data);
    });
  };
  //获取相对应的推广服务套餐
  const getPackage = () => {
    ajax({
      path: `/creditor/v1/product`,
      method: "get",
      data: { page: 1, size: 10, id: queryToData().type },
    }).then((res) => {
      if (res.status === 200) {
        setPackageDetail(res.data.data);
      }
    });
  };

  const handleSubmit = () => {
    if (aliPayData.start_time === "" || !payType) {
      message.error("请将推广信息填写完整");
    } else if (!checked) {
      message.error("请阅读知晓并勾选协议");
    } else {
      let goodsArr = [];
      goodsArr.push({
        count: 1,
        goods_id: packageDetail[packageActive].id,
      });
      const a = {};
      goodsArr.map((item, index) => {
        a[`products[${index}][count]`] = item.count;
        a[`products[${index}][goods_id]`] = item.goods_id;
      });
      payDatas = Object.assign(aliPayData, a);
      setAliPayData({
        ...aliPayData,
        ...a,
      });

      //支付方式选择 1支付宝 2微信 3 余额
      if (payType === 1) {
        ajax({
          path: "/creditor/v1/order",
          data: {
            object_id: queryToData().id,
            ...aliPayData,
            ...a,
          },
        }).then((res) => {
          if (res.status === 200) {
            setOrderId(res.data.order_id);
            Modal.confirm({
              title: "提示",
              content: <div>请在支付宝页面完成支付操作</div>,
              okText: "已完成支付",
              cancelText: "未完成支付",
              onOk() {
                handleConfirmPay(res.data.order_id);
              },
              onCancel() {},
            });
            dateRef.current.submit();
          }
        });
      } else if (payType === 2) {
        ajax({
          path: "/creditor/v1/order",
          data: {
            object_id: queryToData().id,
            ...aliPayData,
            ...a,
          },
        }).then((res) => {
          if (res.status === 200) {
            setOrderId(res.data.order_id);
            ajax({
              path: "/creditor/v1/pre_order",
              data: { id: res.data.order_id },
            }).then((res) => {
              if (res.status === 200 && res.data.return_msg === "OK") {
                setVisible(true);
                setCodeMsg(res.data.code_url);
              }
            });
          }
        });
      } else {
        ajax({
          path: "/creditor/v1/order",
          data: {
            object_id: queryToData().id,
            ...aliPayData,
            ...a,
          },
        }).then((res) => {
          if (res.status === 200 && res.data.order_id) {
            message.success("支付成功");
            window.scrollTo(0, 0);
            setTimeout(() => {
              history.push(
                `/creditorUser/subject/subjectPromote?id=${
                  queryToData().id
                }&type=${queryToData().type}`
              );
              window.location.reload();
            }, 1000);
          }
        });
      }
    }
  };

  useEffect(() => {
    setPackageActive(0);
    getPackage();
    //如果是新增推广
    if (queryToData().isCreate) {
      setIsCreate(true);
      getObjectDetail(queryToData().id, queryToData().type);
    } else {
      setIsCreate(false);
      getObjectTgDetails(queryToData().id);
    }
  }, []);
  useEffect(() => {
    if (promote_time) {
      const day = packageDetail ? packageDetail[packageActive].cycle - 1 : 0;
      const end_time = moment(promote_time)
        .add(day, "day")
        .format("YYYY-MM-DD");
      const start_time = moment(promote_time).format("YYYY-MM-DD");
      setAliPayData({
        ...aliPayData,
        start_time,
        end_time,
      });
    } else {
      setAliPayData({
        ...aliPayData,
        start_time: null,
        end_time: null,
      });
    }
  }, [promote_time]);

  useEffect(() => {
    setAliPayData({
      ...aliPayData,
      pay_type: packageDetail?.length > 0 ? payType : "",
    });
  }, [payType]);

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/subject/subjectList"}>
              <span>标的物管理</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>标的物推广详情</span>
          </li>
        </ul>
      </div>
      <div className={"subjectPromote_container"}>
        {isCreate || (
          <div className={"main_content"}>
            <ul className={"main_content_item statistics"}>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/f83aed50afc01ad24bd0507f7bdbe450.png"
                  alt=""
                />
                <div className={"data"}>
                  <div>{objDetail?.own_looker_num}</div>
                  <span>围观人数</span>
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/9b9cd501d5a9099abc07f8cd47f121d9.png"
                  alt=""
                />
                <div className={"data"}>
                  <div>{objDetail?.consult_num}</div>
                  <span>咨询人数</span>
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/52fc885a2060c69baf9b739ebcc87c5c.png"
                  alt=""
                />
                <div className={"data"}>
                  <div>{objDetail?.visit_count}</div>
                  <span>看样人数</span>
                </div>
              </li>
            </ul>
          </div>
        )}
        <div className={"main_content"}>
          <div className={"main_content_title"}>基本信息</div>
          <ul className={"baseInfos main_content_item"}>
            <li>
              标的编号：
              <span>{objDetail?.id}</span>
            </li>
            <li>
              标的名称：
              <span>{objDetail?.object_title}</span>
            </li>
            <li>
              所属区域：
              <span>
                {objDetail?.province_name}
                {objDetail?.city_name}
                {objDetail?.district_name}
              </span>
            </li>

            <li>
              资产类型：
              <span>{objDetail?.zctype_name}</span>
            </li>
            <li>
              标的类型：
              <span>{objDetail?.second_name}</span>
            </li>
            <li>
              处置机构：
              <span>
                {objDetail?.zc_type === 1
                  ? objDetail?.court_name
                  : objDetail?.merchants_name}
              </span>
            </li>
            <li>
              处置平台：
              <span>{objDetail?.source}</span>
            </li>
            <li>
              评估价：
              <span>{objDetail?.assess_price}</span>
            </li>
            <li>
              起拍价：
              <span>{objDetail?.initial_price}</span>
            </li>
            <li>
              开拍时间：
              <span>{objDetail?.start_time}</span>
            </li>
            <li>
              保证金：
              <span>{objDetail?.security_deposit}</span>
            </li>
            <li>
              加价幅度：
              <span>{objDetail?.bid_increment}</span>
            </li>
            <li>
              竞价周期：
              <span>{objDetail?.bid_cycle}小时</span>
            </li>
          </ul>
        </div>
        {isCreate ? (
          <>
            <div className={"main_content"}>
              <div className={"main_content_title"}>推广服务套餐</div>
              <div className={"main_content_item package"}>
                <ul className={"package_choose"}>
                  {packageDetail?.map((item, index) => {
                    return (
                      <li
                        className={index === packageActive ? "active" : ""}
                        key={index}
                        onClick={() => setPackageActive(index)}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>

                <div className={"package_detail"}>
                  <span className={"left"}>详细内容：</span>
                  {packageDetail?.length && (
                    <> {packageDetail[packageActive].desc}</>
                  )}
                </div>
                <div className={"package_detail"}>
                  <span className={"left"}>推广周期：</span>
                  {packageDetail?.length && (
                    <> {packageDetail[packageActive].cycle}天</>
                  )}
                </div>
                <div className={"promote_time"}>
                  <span className={"left"}>开始时间：</span>
                  <DatePicker
                    onChange={(e) => setPromote_time(e)}
                    format="YYYY-MM-DD"
                    style={{ width: "284px" }}
                  />
                </div>
                <div className={"promote_time"}>
                  <span className={"left"}>结束时间：</span>
                  {aliPayData?.end_time ? (
                    <DatePicker
                      className={"read_only"}
                      value={moment(aliPayData?.end_time)}
                      format="YYYY-MM-DD"
                      style={{ width: "284px" }}
                    />
                  ) : (
                    <DatePicker
                      className={"read_only"}
                      format="YYYY-MM-DD"
                      style={{ width: "284px" }}
                    />
                  )}
                </div>
                <div className={"promote_fee"}>
                  推广费用：<span>¥ </span>
                  {packageDetail?.length && (
                    <b> {packageDetail[packageActive].total_price}</b>
                  )}
                </div>
              </div>
            </div>
            <div className={"main_content"}>
              <div className={"main_content_title"}>支付方式</div>
              <div className={"main_content_item payType"}>
                <ul>
                  <li onClick={() => setPayType(1)}>
                    <Radio checked={payType === 1 ? true : false}></Radio>
                    <img
                      src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/dc0d2b6cb1819186c7b11b2bb39d0778.png"
                      alt=""
                    />
                    支付宝
                  </li>
                  <li onClick={() => setPayType(2)}>
                    <Radio checked={payType === 2 ? true : false}></Radio>
                    <img
                      src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/59696f9f3cd4c274ecc8b427589f3a4b.png"
                      alt=""
                    />
                    微信支付
                  </li>
                  <li onClick={() => setPayType(3)}>
                    <Radio checked={payType === 3 ? true : false}></Radio>
                    <img
                      src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/dca8acb7b7b0a676288b23ecf7a9a8da.png"
                      alt=""
                    />
                    余额
                  </li>
                </ul>
                <div className={"agreement"}>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  />
                  已阅读并知晓：支付成功即推广服务开启，不支持退款。
                </div>
                <div className={"handle_submit"}>
                  <Button type={"primary"} onClick={handleSubmit}>
                    支付
                  </Button>
                </div>
              </div>
            </div>
            {payDatas && (
              <form
                ref={dateRef}
                action={aliPayActionUrl}
                method="get"
                target="_black"
              >
                <input
                  key={"id"}
                  type="hidden"
                  readOnly={true}
                  name={"id"}
                  value={orderId}
                />
              </form>
            )}
          </>
        ) : (
          <>
            <div className={"main_content"}>
              <div className={"main_content_title"}>推广服务套餐</div>
              <div className={"main_content_item package"}>
                <ul className={"package_choose"}>
                  <li className={"active"}> {objDetail?.tg_snap_name}</li>
                </ul>

                <div className={"package_detail"}>
                  <span className={"left"}>详细内容：</span>
                  {objDetail?.tg_snap_desc}
                </div>
                <div className={"promote_time"}>
                  <span className={"left"}>推广时间：</span>
                  {objDetail?.tg_start_time} 一 {objDetail?.tg_end_time}
                </div>
                <div className={"promote_fee"}>
                  推广费用：<span>¥ </span>
                  <b> {objDetail?.tg_fact_price}</b>
                </div>
              </div>
            </div>
          </>
        )}

        <Modal
          title="微信扫描二维码完成支付"
          closable={false}
          maskClosable={false}
          width={"300px"}
          footer={null}
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
        >
          <div className={"wxPay_container"}>
            <div className="wepay-qrcode">
              {codeMsg && (
                <QRcode
                  style={{ width: "200px", height: "200px" }}
                  value={codeMsg}
                />
              )}
            </div>
            <div className={"handle_wepay_btn"}>
              <Button
                type={"danger"}
                className={"error_btn"}
                onClick={() => setVisible(false)}
              >
                未完成支付
              </Button>
              <Button
                type={"primary"}
                onClick={() => handleConfirmPay(orderId)}
              >
                我已完成支付
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
