// 数字转换成文字显示
const valueToLabel = ({ optionArray = [], value }) => {
  if (optionArray.length > 0) {
    const valueArray = optionArray.filter((item) => item.value === value);
    return valueArray.length <= 0 ? "" : valueArray[0]["label"];
  }
};

const searchHistory = (data) => {
  let historyItems = localStorage.searchHistory
    ? JSON.parse(localStorage.searchHistory)
    : [];
  //判断有没有重复的值
  let _index = historyItems.indexOf(data);
  if(_index !== -1){
    historyItems.splice(_index,1);
  }
  historyItems.unshift(data);
  historyItems = historyItems.slice(0, 5);
  localStorage.searchHistory = JSON.stringify(historyItems);
  return historyItems;
};

export { valueToLabel, searchHistory };
