import React, { useState, useEffect } from "react";

import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
import { Input, Button, message, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import queryToData from "../../../../../func/queryToData";
import ajax from "../../../../../func/ajax";
import { Md5 } from "ts-md5";

export default () => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [balance, setBalance] = useState(0);
  const [money, setMoney] = useState(null);
  const [bankInfo, setBankInfo] = useState({});
  const [password, setPassword] = useState(null);
  //获取债权人申请信息
  const getApplyInfo = () => {
    ajax({
      path: "/creditor/v1/merchants",
      method: "get",
    }).then((res) => {
      setBankInfo(res);
    });
  };
  //提交申请
  const handleSubmit = () => {
    const reg = /^\d{6}$/;
    if (reg.test(password)) {
      let mdPassword = Md5.hashStr(String(password.replace(/ /g, "")));

      ajax({
        path: "/creditor/v1/swithdrawal",
        data: { money, password: mdPassword },
      }).then((res) => {
        if (res.status === 200) {
          Modal.info({
            title: "提现",
            content: <div>您的提现已提交平台审核，请关注三个工作日</div>,
            onOk() {
              history.push("/creditorUser/finance/financeAccount");
            },
          });
        }
      });
    } else {
      message.error("密码应为六位数字");
    }
  };
  useEffect(() => {
    setBalance(queryToData().price);
    getApplyInfo();
  }, []);
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>财务管理</span>
            <RightOutlined icon={"iconArrow"} />
            <span>账号管理</span>
            <RightOutlined icon={"iconArrow"} />
            <span>提现</span>
          </li>
        </ul>
      </div>
      <div className={"financeWithDraw_container"}>
        {step === 1 ? (
          <div className={"withDraw_infos"}>
            <div className={"withDraw_infos_title"}>提现</div>
            <ul className={"withDraw_infos_content"}>
              <li>
                <span>可提现金额（元）:</span>
                <div>{balance}</div>
              </li>
              <li>
                <span>提现到银行卡号 :</span>
                <div>{bankInfo?.bank_account_num}</div>
              </li>
              <li>
                <span>提现到银行账户名 :</span>
                <div>{bankInfo?.bank_account_name}</div>
              </li>
              <li>
                <span>开户银行 :</span>
                <div>{bankInfo?.bank_name}</div>
              </li>
              <li>
                <span>提现金额（元）:</span>
                <div>
                  <Input
                    type="number"
                    value={money}
                    onChange={(e) => setMoney(e.target.value)}
                    placeholder={"请输入提现金额"}
                  />
                  <span>
                    如银行账户信息不对，请及时修改，
                    <Link to={"/creditorUser/account/certification/bankDetail"}>
                      点击前往
                    </Link>
                  </span>
                </div>
              </li>
            </ul>
            <div className={"textCenter"}>
              <Button
                type={"primary"}
                onClick={() => {
                  if (!money) {
                    message.error("请先输入要提现的金额");
                  } else if (money <= 0) {
                    message.error("提现金额不能小于0");
                  } else if (parseFloat(money) > parseFloat(balance)) {
                    message.error("提现金额不能大于余额");
                  } else {
                    setStep(2);
                  }
                }}
              >
                下一步
              </Button>
            </div>
          </div>
        ) : (
          <div className={"withDraw_confirm"}>
            <div className={"confirm_infos"}>
              <div className={"confirm_infos_title"}>确认提交信息</div>
              <ul className={"confirm_infos_content"}>
                <li>
                  <span>银行账户名：</span>
                  <div>{bankInfo?.bank_account_name}</div>
                </li>
                <li>
                  <span>银行账户卡号：</span>
                  <div>{bankInfo?.bank_account_num}</div>
                </li>
                <li>
                  <span>开户行：</span>
                  <div>{bankInfo?.bank_name}</div>
                </li>
                <li>
                  <span>开户支行：</span>
                  <div>{bankInfo?.bank_branch}</div>
                </li>
                <li>
                  <span>提现金额(元)：</span>
                  <div>{money}</div>
                </li>
              </ul>
              <div className={"pay textCenter"}>
                <div className={"payment_password"}>
                  支付密码：
                  <Input.Password
                    style={{ width: "220px" }}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={"请输入6位支付密码"}
                  />
                  <Link to={"/creditorUser/account/managePassword/index"}>
                    &nbsp;忘记密码?
                  </Link>
                </div>
                <div>
                  <Button onClick={handleSubmit} type={"primary"}>
                    确认提交
                  </Button>
                  <Button
                    className={"btn-primary-bg"}
                    onClick={() => setStep(1)}
                  >
                    返回修改
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
