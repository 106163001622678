import React, { useState, useEffect } from "react";
import "./style.scss";
import { Form, Input, Row, Col, Button, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import ajax from "../../../../func/ajax";
import dataFillForm from "../../../../func/dataFillForm";
import formValid from "../../../../func/formValid";
import { Link } from "react-router-dom";
const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
    md: { span: 4 },
  },
  wrapperCol: {
    sm: { span: 16 },
    md: { span: 20 },
  },
};
const defaultGutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};
const editFormColSpan = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 20,
};

export default () => {
  const [form] = Form.useForm();
  const [type, setType] = useState(1);
  const [applyData, setApplyData] = useState({});
  //获取债权人申请信息
  const getApplyInfo = () => {
    ajax({
      path: "/creditor/v1/merchants",
      method: "get",
    }).then((res) => {
      let data = res;
      setApplyData(data);
      setType(data.c_type);
      dataFillForm({
        form,
        data,
      });
    });
  };

  //提交修改
  const handleSubmit = () => {
    formValid(form).then(() => {
      let { email, link_name, company_address, phone } = form.getFieldsValue();

      //判断是否为修改信息，如果是修改则需要传id
      ajax({
        path: "/creditor/v1/merchants/basechange",
        data: {
          email,
          link_name,
          company_address,
          phone,
        },
      }).then((res) => {
        if (res.status === 200) {
          message.success("修改成功");
          getApplyInfo();
        }
      });
    });
  };

  useEffect(() => {
    getApplyInfo();
  }, []);
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/account/index"}>
              <span>账户管理</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>机构信息</span>
          </li>
        </ul>
      </div>
      <div className={"institutionInfo_container"}>
        <div className={"institutionInfo_header"}>
          <div className={"institutionInfo_header_left"}>
            <div className={"imageText"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/27c07f87d9de2257b473999f08f1dc6e.png"
                alt=""
              />
              <div className={"des"}>机构/债权人</div>
            </div>
            <div className={"name"}>{applyData?.name}</div>
          </div>
          <div className={"institutionInfo_header_right"}>
            <div className={"item"}>
              机构类型：
              <span className={"des"}>
                {applyData?.c_type === 2 ? "个人" : "企业"}
              </span>
            </div>
            {applyData?.c_type === 1 && (
              <div className={"create_time"}>
                成立时间：
                <span className={"des"}> {applyData?.registered_date}</span>
              </div>
            )}
          </div>
        </div>
        <div className={"institutionInfo_form"}>
          <div className={"institutionInfo_form_header"}>基本信息</div>
          <Form form={form} {...formItemLayout}>
            <Row gutter={defaultGutter}>
              <Col {...editFormColSpan}>
                <Form.Item
                  label={"邮箱"}
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "请输入正确的邮箱地址",
                    },
                  ]}
                >
                  <Input placeholder={"请输入邮箱"} allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={defaultGutter}>
              <Col {...editFormColSpan}>
                <Form.Item
                  label={"联系人"}
                  name={"link_name"}
                  rules={[{ required: true, message: "该项为必填项" }]}
                >
                  <Input placeholder={"请输入联系人"} allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={defaultGutter}>
              <Col {...editFormColSpan}>
                <Form.Item
                  label={"手机号"}
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                      message: "请输入正确的手机号码",
                    },
                  ]}
                >
                  <Input placeholder={"请输入手机号"} allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={defaultGutter}>
              <Col {...editFormColSpan}>
                <Form.Item
                  label={"地址"}
                  name={"company_address"}
                  rules={[{ required: true, message: "请输入地址" }]}
                >
                  <Input placeholder={"请输入地址"} allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={"modify"}>
            <Button onClick={handleSubmit} type={"primary"}>
              修改
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
