import React, { useEffect, useState } from "react";

import "./style.scss";
import { Link } from "react-router-dom";
import {
  Button,
  Empty,
  Pagination,
  notification,
  Popconfirm,
  message,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import ajax from "../../../../func/ajax";
import {
  claimStatus,
  is_start_select,
  zy_type,
} from "../../../../static/option";
import { valueToLabel } from "../../../../func/fn";
import transPrice from "../../../../func/transPrice";
const { TabPane } = Tabs;

export default () => {
  const [subjectList, setSubjectList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [defaultActiveKey, setDefaultActiveKey] = useState(1);
  const [type, setType] = useState(1);

  const size = 2;
  useEffect(() => {
    getObjectList(1, type);
  }, []);
  //获取标的列表
  const getObjectList = (newPagination, type) => {
    if (newPagination) {
      setPage(newPagination);
    }
    ajax({
      path: "/creditor/v1.Zc_object/getObjectList",
      data: { page: newPagination, size, type },
    }).then((res) => {
      if (res.data.length > 0) {
        res.data.map((item, index) => {
          item.is_start_text = valueToLabel({
            optionArray: is_start_select,
            value: item.is_start,
          });
          item.status_text = valueToLabel({
            optionArray: claimStatus,
            value: item.status,
          });
          if (item.province_code === 100000) {
            item.province_name = "全国";
          }
          return item;
        });
      }
      setSubjectList(res.data);
      setCount(res.count);
    });
  };
  //tab切换
  const callback = (e) => {
    setType(e);
    getObjectList(1, e);
  };
  //打开审核拒绝原因
  const openNotification = (e) => {
    const args = {
      message: "审核拒绝原因",
      description: e,
      duration: 2,
    };
    notification.error(args);
  };
  //上下架操作
  const handleShelf = (item, operation) => {
    ajax({
      path: "/creditor/v1.Zc_object/getObjectShelves",
      data: { zc_type: item.zc_type, object_id: item.id, operation },
    }).then((res) => {
      if (res.status === 200) {
        message.success("操作成功");
        getObjectList(page, type);
      }
    });
  };

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>标的物管理</span>
            <RightOutlined className={"iconArrow"} />
            <span>标的物列表</span>
          </li>
        </ul>
      </div>
      <div className={"subjectList_container"}>
        <Tabs defaultActiveKey={defaultActiveKey} onChange={callback}>
          {zy_type.map((item, index) => {
            return <TabPane key={index + 1} tab={item.label}></TabPane>;
          })}
        </Tabs>
        <ul className={"subjectList_item"}>
          {subjectList.map((item, index) => {
            return (
              <li key={item.id}>
                <div className={"subject_title"}>
                  <span
                    className={
                      item.zc_type === 2
                        ? "blue"
                        : item.zc_type === 3
                          ? "red"
                          : ""
                    }
                  >
                    {item.zctype_name}
                  </span>
                  {item.object_title}
                </div>
                <div className={"subject_detail"}>
                  <div className={"subject_detail_item_l"}>
                    处置机构：
                    {item.zc_type === 1 ? (
                      <span>{item.court_name}</span>
                    ) : (
                      <span>{item.merchants_name}</span>
                    )}
                  </div>
                  <div className={"subject_detail_item_l"}>
                    所属区域：
                    <span>
                      {item.province_name}
                      {item.city_name}
                      {item.district_name}
                    </span>
                  </div>
                  <div>
                    标的类型：
                    <span>{item.second_name}</span>
                  </div>
                  {item.zc_type === 3 || (
                    <div>
                      处置平台：
                      <span>{item.source}</span>
                    </div>
                  )}

                  {item.zc_type === 1 && (
                    <div className={"subject_detail_item_l"}>
                      辅助机构：
                      <span>{item.org_name}</span>
                    </div>
                  )}
                  <div className={"subject_detail_item_l"}>
                    {
                      item.zc_type === 3 ? '状态：' : '审核状态：'
                    }

                    {item.zc_type === 1 ? (
                      <span className={item.status === 2 ? "red" : "blue"}>
                        {item.status_text}
                      </span>
                    ) : (
                      <span className={item.is_start === 3 ? "red" : "blue"}>
                        {item.is_start_text}
                      </span>
                    )}
                  </div>
                  {item.zc_type === 3 || (
                    <div>
                      推广状态：
                      <span>{item.extend_id === 1 ? "已推广" : "未推广"}</span>
                    </div>
                  )}

                  {item.zc_type === 3 || (
                    <div className={"subject_detail_item_l"}>
                      开拍时间：
                      <span>{item.start_time}</span>
                    </div>
                  )}
                  <div className={"subject_detail_item_l"}>
                    创建时间：
                    <span>{item.create_time}</span>
                  </div>
                  <div>
                    评估价：
                    <span>¥{transPrice(item.assess_price, "zh_cn")}</span>
                  </div>
                  {item.zc_type === 3 || (
                    <div>
                      起拍价：
                      <span>¥{transPrice(item.initial_price, "zh_cn")}</span>
                    </div>
                  )}
                </div>
                <div className={"handle_subject_buttons"}>
                  <Button className={"look_detail"}>
                    <Link
                      to={`/creditorUser/subject/subjectDetail?id=${item.id}&type=${item.zc_type}`}
                    >
                      详情查看
                    </Link>
                  </Button>

                  {item.zc_type !== 3 && (
                    <>
                      {item.extend_id === 1 ? (
                        <Button className={"look_promote_detail"}>
                          <Link
                            to={`/creditorUser/subject/subjectPromote?id=${item.id}&type=${item.zc_type}`}
                          >
                            推广详情查看
                          </Link>
                        </Button>
                      ) : (
                        <>
                          {item.zc_type === 1 && item.status === 3 && (
                            <Button type={"primary"}>
                              <Link
                                to={`/creditorUser/subject/subjectPromoteIndex?id=${item.id}&type=${item.zc_type}`}
                              >
                                推广
                              </Link>
                            </Button>
                          )}
                          {item.zc_type === 2 && item.is_start === 4 && (
                            <Button type={"primary"}>
                              <Link
                                to={`/creditorUser/subject/subjectPromoteIndex?id=${item.id}&type=${item.zc_type}`}
                              >
                                推广
                              </Link>
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {item.zc_type === 1 && item.status === 2 && (
                    <Button
                      className={"danger"}
                      onClick={() => openNotification(item.remark)}
                    >
                      查看原因
                    </Button>
                  )}
                  {item.zc_type === 1 || (
                    <>
                      {item.is_start === 1 && (
                        <>
                          <Popconfirm
                            title="确定执行此操作?"
                            okText="确定"
                            cancelText="取消"
                            onConfirm={() => handleShelf(item, 1)}
                          >
                            <Button type={"primary"}>上架</Button>
                          </Popconfirm>
                          <Button className={"look_detail"}>
                            <Link
                              to={`/creditorUser/subject/onShelfDetail?id=${item.id}&type=${item.zc_type}`}
                            >
                              编辑
                            </Link>
                          </Button>
                        </>
                      )}
                      {item.is_start === 4 && (
                        <Popconfirm
                          title="确定执行此操作?"
                          okText="确定"
                          cancelText="取消"
                          onConfirm={() => handleShelf(item, 2)}
                        >
                          <Button className={"off_shelf"}>下架</Button>
                        </Popconfirm>
                      )}
                      {item.is_start === 3 && (
                        <>
                          <Button
                            className={"danger"}
                            onClick={() => openNotification(item.start_reson)}
                          >
                            查看原因
                          </Button>
                          <Button className={"look_detail"}>
                            <Link
                              to={`/creditorUser/subject/onShelfDetail?id=${item.id}&type=${item.zc_type}`}
                            >
                              编辑
                            </Link>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </li>
            );
          })}
        </ul>

        {count !== 0 && (
          <Pagination
            style={{ textAlign: "right" }}
            total={count}
            pageSize={size}
            showSizeChanger={false}
            onChange={(e) => getObjectList(e, type)}
            showQuickJumper
            showTotal={(total) => `共 ${total} 条`}
          />
        )}
        {count === 0 && <Empty />}
      </div>
    </div>
  );
};
