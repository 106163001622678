import React, { useEffect } from "react";
import IndexHeader from "./indexHeader";
import IndexEntrust from "./indexEntrust";
import Footer from "../../component/Footer";
import queryToData from "../../func/queryToData";
import { useHistory } from "react-router-dom";

function Index(props) {
  let history = useHistory();
  useEffect(() => {
    //判断退出登陆
    if (queryToData().callback && queryToData().callback === "logout") {
      localStorage.clear();
      history.push("/");
    }
  }, []);
  return (
    <div className={"index"}>
      <IndexHeader />
      <IndexEntrust />
      <Footer />
    </div>
  );
}

export default Index;
