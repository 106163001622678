import React from "react";

export default () => {
  //支付宝授权登录
  const aliPayLogin = () => {
    window.location.href =
      "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021001180624195&scope=auth_user&redirect_uri=http://m-satrix.com/login/certification&state=init";
  };

  //微信授权登录
  const weChatLogin = () => {
    window.location.href =
      "https://open.weixin.qq.com/connect/qrconnect?appid=wx94da33391c4a2770&redirect_uri=http://m-satrix.com/login/certification&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
  };
  return (
    <div className={"cooperLogin"}>
      <div className={"cooperLogin_title"}>
        <span></span>
        <div>用合作网站账户登录</div>
        <span></span>
      </div>
      <div className={"cooper_item"}>
        <img
          onClick={weChatLogin}
          src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/59696f9f3cd4c274ecc8b427589f3a4b.png"
          alt=""
        />
        <img
          onClick={aliPayLogin}
          src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/dc0d2b6cb1819186c7b11b2bb39d0778.png"
          alt=""
        />
      </div>
    </div>
  );
};
