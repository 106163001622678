import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

import "./style.scss";
import ajax from "../../../../func/ajax";
import { cancelReasonSelect, orderStatus } from "../../../../static/option";
import { Empty, Pagination, Modal, message } from "antd";
export default () => {
  const [currentIndex, setCurrentIndex] = useState("");
  const [cancelReasonIndex, setCancelReasonIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [currentOrderId, setCurrentId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [tradeType, setTradeType] = useState(null);
  const size = 5;

  useEffect(() => {
    getOrderList(page, currentIndex);
  }, [currentIndex]);

  //获取订单列表
  const getOrderList = (newPagination, type) => {
    setPage(newPagination);
    setTradeType(type);
    ajax({
      path: "/customer/User/getOrderLiset",
      data: {
        size,
        page: newPagination,
        tradeStatus: type,
      },
    }).then((res) => {
      if (res.list.length > 0) {
        res.list.map((item, index) => {
          orderStatus.map((citem, index) => {
            if (citem.value === item.trade_status) {
              item.icon = citem.icon;
              item.fontColor = citem.fontColor;
            }
            return item;
          });
        });
      }
      setOrderList(res.list);
      setCount(res.count);
      window.scrollTo(0, 0);
    });
  };
  //取消订单
  const setOrderCancel = () => {
    ajax({
      path: "/customer/User/setOrderCancel",
      data: {
        name: cancelReasonSelect[cancelReasonIndex].value,
        order_id: currentOrderId,
      },
    }).then((res) => {
      if (res.status === 200) {
        setShowModal(false);
        message.success("订单已取消");
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>订单管理</span>
          </li>
          <li>
            <RightOutlined className={"iconArrow"} />
            <span>我的订单</span>
          </li>
        </ul>
      </div>
      <div className={"myOrderList-container"}>
        <div className={"navBar"}>
          <ul className={"navBar-content"}>
            {orderStatus.map((item, index) => {
              return (
                <li
                  key={index}
                  className={[
                    "navBar-item",
                    currentIndex === item.value ? "active" : "",
                  ].join(" ")}
                  onClick={() => {
                    setPage(1);
                    setCurrentIndex(item.value);
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        </div>
        <ul className="prod-list">
          {orderList.map((item, index) => {
            return (
              <li className="prod-list-item" key={index}>
                <div className="prod-top">
                  <div className="prod-top-l">
                    <span>{item.order_time} </span>
                    <span>订单编号：{item.order_num} </span>
                  </div>
                  <div className="prod-top-r">
                    <p style={{ color: item.fontColor }}>
                      {item.icon}
                      {item.trade_name}
                    </p>
                  </div>
                </div>
                <div className="prod-content">
                  <a className="prod-img">
                    <img src={item.img_url} alt="" />
                  </a>
                  <div className="prod-detail">
                    <div className="prod-des">
                      <p className="prod-name">
                        <a> {item.goods_name} </a>
                        <span>x{item.goods_num} </span>
                      </p>
                      <p className="acPay">实付款 ￥ {item.fact_price} </p>
                    </div>
                    <div className="prod-handle">
                      <Link
                        to={`/user/myOrder/myOrderDetail?id=${item.order_id}`}
                      >
                        查看订单
                      </Link>
                      {item.trade_status === 0 && (
                        <>
                          <a
                            onClick={() => {
                              setCurrentId(item.order_id);
                              setShowModal(true);
                            }}
                            className={"prod-handle-cancel"}
                          >
                            取消订单
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {count === 0 && <Empty />}
        {count !== 0 && (
          <Pagination
            current={page}
            style={{ textAlign: "right", marginRight: "24px" }}
            total={count}
            pageSize={size}
            onChange={(e) => getOrderList(e, tradeType)}
            showQuickJumper
            showTotal={(total) => `共 ${total} 条`}
          />
        )}

        <Modal
          title="取消订单"
          visible={showModal}
          onOk={setOrderCancel}
          onCancel={() => {
            setShowModal(false);
          }}
        >
          <div>
            <div className="order-info">
              <p>请选择取消订单的原因（必填）：</p>
              {cancelReasonSelect.map((item, index) => {
                return (
                  <div
                    className="order-info-item"
                    key={index}
                    onClick={() => setCancelReasonIndex(index)}
                  >
                    <div className="order-info-item-text">{item.value}</div>
                    <div className="order-info-item-check">
                      {cancelReasonIndex === index && (
                        <img
                          src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/e27e9bb380826526fe93a23d65f82cbb.png"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
