import { fromJS } from "immutable";
import * as actionTypes from "./actionTypes";

const defaultState = fromJS({
  second_class: [],
});

const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    // 获取 二级分类 数据
    case actionTypes.GET_SECOND_CLASS:
      return state.update("second_class", () => {
        action.data.unshift({
          type: "",
          name: "全部",
        });
        return fromJS(action.data);
      });
    default:
      return state;
  }
};

export default rootReducer;
