import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import { BrowserRouter } from "react-router-dom";

//正式环境插入统计代码
(function () {
  if (process.env.NODE_ENV === "production") {
    var tj = document.createElement('script');
    tj.src = 'https://s4.cnzz.com/z_stat.php?id=1279802550&web_id=1279802550';
    var b = document.getElementsByTagName('body')[0];
    b.appendChild(tj)
  }
})()

const app = (
  <BrowserRouter>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </BrowserRouter>
);
ReactDOM.render(app, document.getElementById("root"));
