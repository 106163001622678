import './index.scss'
import React, { useEffect, useState } from 'react';
import { Input, List } from 'antd';

const { Search } = Input;

let mapObj = undefined;

function MyAMap(props) {
  const { onChangeLngLat } = props;
  const [map, setMap] = useState(undefined);//地图对象
  const [lngLat, setLngLat] = useState(undefined);//经纬度
  const [placeSearchObj, setPlaceSearchObj] = useState(undefined);//地图搜索关键字插件对象

  const [searchData, setSearchData] = useState([]);//
  const aMapLoad = () => {
    var aMapScript = document.createElement("script");
    aMapScript.setAttribute("type", "text/javascript");
    aMapScript.setAttribute("src", "https://webapi.amap.com/maps?v=1.4.15&key=28fb9893291fe07022ec25a7197e0f77&callback=initAMap");
    document.body.insertBefore(aMapScript, document.body.lastChild);
    window.initAMap = function () {
      var _map = new window.AMap.Map('container', {
        resizeEnable: true,
        zoom: 15
      })
      mapObj = _map;
      console.log(_map.add)
      setMap(_map);
      _map.on('click', (e) => mapClick(e, _map))
      window.AMap.plugin('AMap.PlaceSearch', function () {
        var placeSearch = new window.AMap.PlaceSearch({
          // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
        })
        setPlaceSearchObj(placeSearch)
      })
    }
  }

  const mapClick = (e, map) => {
    map.clearMap();
    var _marker = new window.AMap.Marker({
      icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
      position: [e.lnglat.lng, e.lnglat.lat]
    });
    setLngLat({ lng: e.lnglat.lng, lat: e.lnglat.lat })
    map.add(_marker);
  }

  const searchClick = ({ lng, lat }) => {
    map.clearMap();
    var _marker = new window.AMap.Marker({
      icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
      position: [lng, lat]
    });
    setLngLat({ lng: lng, lat: lat })
    map.add(_marker);
    map.setFitView();
  }

  const autoInput = (e) => {
    placeSearchObj.search(e, function (status, result) {
      // 搜索成功时，result即是对应的匹配数据
      if (result.poiList) {
        if (result.poiList.pois) {
          setSearchData(result.poiList.pois)
        }
      }
    })
  }

  useEffect(() => {
    aMapLoad();
    return () => {
      mapObj.destroy()
      mapObj.off('click', mapClick);
    }
  }, [])
  useEffect(() => {
    if (lngLat) {
      onChangeLngLat(lngLat)
    }
  }, [
    lngLat
  ])

  const { width, height } = props;
  return (
    <div id='container' style={{ width: width, height: height }}>
      <div className='input-card'>
        <Search
          placeholder="请输入搜索关键字"
          enterButton="搜索"
          size="large"
          onSearch={autoInput}
          onChange={(e) => autoInput(e.target.value)}
        />
        <div className='list-box'>
          <List
            style={{ background: '#ffffff', marginTop: '10px' }}
            itemLayout="horizontal"
            dataSource={searchData}
            renderItem={item => (
              <List.Item style={{ paddingLeft: '10px', paddingRight: '10px' }} onClick={() => searchClick({ lng: item.location.lng, lat: item.location.lat })}>
                <span className='name'>{item.name}</span>
                <span className='address'>({item.address})</span>
              </List.Item>
            )}
          />
        </div>
      </div>
      <div className="LngLat-card">
        <span className='input_label'>{lngLat ? '当前经纬度：' : '当前未选择地点'}</span>
        <input type="text" readOnly={true} className='input_text' value={lngLat ? `${lngLat.lat},${lngLat.lng}` : ''}></input>
      </div>
    </div>
  )
}

export default MyAMap;