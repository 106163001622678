import "./style.scss";

import React, { useState, useEffect } from "react";
import { message, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import AMap from "../../../../../component/AMap";
import {
  Form,
  Button,
  Input,
  Cascader,
  Row,
  Col,
  Select,
  DatePicker,
  Radio,
} from "antd";
// import { address } from "../../../../../static/address";
import { address } from "../../../../../static/address2";
import ImgUploader from "../../../../../component/ImgUploader";

import CKEditor from "ckeditor4-react";
import ckeditorConfig from "../../../../../static/ckeditorConfig";
import formValid from "../../../../../func/formValid";
import {
  inputIntValid,
  inputMinValid,
  inputZkValid,
  inputSYValid,
} from "../../../../../func/rules";
import queryToData from "../../../../../func/queryToData";
import ajax from "../../../../../func/ajax";
import { sourceSelect, clientList } from "../../../../../static/option";
import moment from "moment";
import dataFillForm from "../../../../../func/dataFillForm";
let secondClass = [];
export const formItemLayout = {
  labelCol: {
    md: { span: 4 },
  },
  wrapperCol: {
    md: { span: 18 },
  },
};
export const defaultGutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};
export const editFormColSpan = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
};
const { Option } = Select;

function OnShelfDetail(props) {
  const history = useHistory();
  const [second_class, setSecond_class] = useState([]);
  const [value1, setValue1] = useState(null);
  const [action, setAction] = useState(null);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(3);
  const [otherEndTime, setOtherEndTime] = useState(moment(new Date()));
  const [applyData, setApplyData] = useState({});
  const [lngLat, setLngLat] = useState(undefined);
  const [form] = Form.useForm();
  const city = ["110000", "120000", "310000", "500000"]; //直辖市
  const [clientList, setClientList] = useState([]);
  const handleSubmit = (operation) => {
    if (!form.getFieldsValue().second_class) {
      message.error("请选择标的类型");
      return;
    }
    //判断是保存还是提交审核
    if (operation === 1) {
      formValid(form).then((res) => {
        handleData(operation);
      });
    } else {
      handleData(operation);
    }
  };
  //处理数据
  const handleData = (operation) => {
    let province_code, city_code, district_code, latitude, longitude, bid_cycle;
    let {
      zc_type = 3,
      second_class,
      object_title,
      announcement,
      should_know,
      introduce,
      img,
      end_time,
      location,
      coordinate,
      source,
      source_url,
      initial_price,
      assess_price,
      start_time,
      bid_increment,
      security_deposit,
      discount_rate,
      expected_investment,
      client_id,
    } = form.getFieldsValue();
    //判断是新增还是编辑
    if (action === "create") {
      //图片处理
      if (img) {
        let imgArr = [];
        img.map((item, index) => {
          imgArr.push({
            id: 0,
            delete_sign: 0,
            img_path: item,
          });
        });
        img = imgArr;
      }
      //意向城市处理
      if (location) {
        province_code = location[0];
        city_code = location[1];
        district_code = location[2];
        // if (location.length === 1) {
        //   //判断是否是直辖市
        //   console.log(city.indexOf(location[0]),location[0])
        //   if(city.indexOf(location[0])===-1){
        //     province_code = location[0];
        //   }else{
        //     console.log("直辖市")
        //     city_code = location[0];
        //   }
        // } else if (location.length === 2) {
        //   console.log(city.indexOf(location[0]))
        //   if(city.indexOf(location[0])===-1){
        //     province_code = location[0];
        //     city_code = location[1];
        //   }else{
        //     console.log("直辖市")
        //     city_code = location[0];
        //     district_code = location[1];
        //   }

        // } else {
        //   province_code = location[0];
        //   city_code = location[1];
        //   district_code = location[2];
        // }
      }
      //判断是否为资产拍卖
      if (type === 2) {
        //判断竞价时间是否为自定义
        if (end_time || end_time === 0) {
          if (end_time === 0) {
            if (!otherEndTime) {
              message.error("请选择竞价结束时间");
              return false;
            } else {
              if (otherEndTime.diff(moment(start_time), "minute") < 59) {
                message.error("结束时间不能小于或等于开始时间");
                return;
              } else {
                bid_cycle = otherEndTime.diff(moment(start_time), "hour") + 1;
                end_time = moment(otherEndTime).format("YYYY-MM-DD HH");
              }
            }
          } else {
            //非自定义
            bid_cycle = end_time;
            end_time = moment(
              moment(start_time).valueOf() + end_time * 60 * 60 * 1000
            ).format("YYYY-MM-DD HH");
          }
        }

        //经纬度处理
        if (coordinate) {
          if (coordinate.split(",").length !== 2) {
            message.error("请输入正确格式的经纬度");
            return;
          } else {
            latitude = coordinate.split(",")[0];
            longitude = coordinate.split(",")[1];
          }
        }
        if (should_know) {
          should_know = should_know.editor.getData();
        }
        // 时间格式处理
        if (start_time) {
          start_time = moment(start_time).format("YYYY-MM-DD HH");
        }
      }
    } else {
      //编辑模式
      if (img && img.length > 0) {
        if (!applyData.img) {
          let imgArr = [];
          img.map((item, index) => {
            imgArr.push({
              id: 0,
              delete_sign: 0,
              img_path: item,
            });
          });
          img = imgArr;
        } else {
          if (img[0].img_path) {
            //没变过
            let oArr = [];
            img.map((item, index) => {
              oArr.push({
                id: item.id,
                delete_sign: 0,
                img_path: item.img_path,
              });
            });
            img = oArr;
          } else {
            let arr = [];
            let arrObj = [];
            applyData.img.map((item) => {
              arr.push(item.img_path);
            });
            let totalArr = new Set([...arr, ...img]);
            totalArr.forEach((item) => {
              let _obj = {};
              _obj.img_path = item;
              // 减少的
              if (img.indexOf(item) === -1) {
                _obj.delete_sign = 1;
              } else {
                _obj.delete_sign = 0;
              }
              arrObj.push(_obj);
              arrObj.map((item) => {
                applyData.img.map((oitem) => {
                  if (item.img_path === oitem.img_path) {
                    item.id = oitem.id;
                  }
                });
                if (!item.id) {
                  item.id = 0;
                }
                return item;
              });
            });
            img = arrObj;
          }
        }
      } else {
        if (applyData.img) {
          let deleteArr = [];
          applyData.img.map((item) => {
            deleteArr.push({
              id: item.id,
              delete_sign: 1,
              img_path: item.img_path,
            });
          });
          img = deleteArr;
        } else {
          img = null;
        }
      }
      //区域
      if (location) {
        province_code = location[0];
        city_code = location[1];
        district_code = location[2];
        // if (location.length === 1) {
        //   //不是直辖市
        //   if(city.indexOf(location[0]) >-1){
        //     province_code = location[0];
        //   }else{
        //     city_code = location[0];
        //   }

        // } else if (location.length === 2) {
        //   //当为直辖市的时候，为市、区；普通省的时候，为省市
        //   if(city.indexOf(location[0]) >-1){
        //     province_code = location[0];
        //     city_code = location[1];
        //   }else{
        //     city_code = location[0];
        //     district_code = location[1];
        //   }

        // } else {
        //   province_code = location[0];
        //   city_code = location[1];
        //   district_code = location[2];
        // }
      }
      //判断竞价时间是否为自定义
      if (end_time || end_time === 0) {
        if (end_time === 0) {
          if (!otherEndTime) {
            message.error("请选择竞价结束时间");
            return false;
          } else {
            if (otherEndTime.diff(moment(start_time), "minute") < 59) {
              message.error("结束时间不能小于或等于开始时间");
              return;
            } else {
              bid_cycle = otherEndTime.diff(moment(start_time), "hour");
              end_time = moment(otherEndTime).format("YYYY-MM-DD HH");
            }
          }
        } else {
          //非自定义
          bid_cycle = end_time;
          end_time = moment(
            moment(start_time).valueOf() + end_time * 60 * 60 * 1000
          ).format("YYYY-MM-DD HH");
        }
      }
      //经纬度处理
      if (coordinate) {
        if (coordinate.split(",").length !== 2) {
          message.error("请输入正确格式的经纬度");
          return;
        } else {
          latitude = coordinate.split(",")[0];
          longitude = coordinate.split(",")[1];
        }
      }
      if (should_know) {
        if (typeof should_know === "object") {
          should_know = should_know.editor.getData();
        }
      }
      // 时间格式处理
      if (start_time) {
        start_time = moment(start_time).format("YYYY-MM-DD HH");
      }
    }

    if (announcement) {
      if (typeof announcement === "object") {
        announcement = announcement.editor.getData();
      }
    }
    if (introduce) {
      if (typeof introduce === "object") {
        introduce = introduce.editor.getData();
      }
    }
    ajax({
      path: "/creditor/v1.Zc_object/getObjectAdd",
      data: {
        id: queryToData().id || "",
        zc_type,
        operation,
        second_class,
        object_title,
        initial_price,
        assess_price,
        province_code,
        city_code,
        district_code,
        start_time,
        bid_increment,
        security_deposit,
        source,
        source_url,
        end_time,
        coordinate,
        latitude,
        longitude,
        announcement,
        should_know,
        introduce,
        bid_cycle,
        img: img ? JSON.stringify(img) : null,
        discount_rate,
        expected_investment,
        client_id,
        object_image: img[0].img_path
      },
    }).then((res) => {
      if (res.status === 200) {
        if (operation === 1) {
          message.success("已提交审核");
          history.go(-1);
        } else {
          message.success("操作成功");
          history.go(-1);
        }
      }
    });
  };

  //获取标的详情
  const getObjDetail = () => {
    ajax({
      path: "/creditor/v1.Zc_object/getObjectDetails",
      data: { id: queryToData().id, type: queryToData().type },
    }).then((res) => {
      let { data } = res;
      setType(data.zc_type);
      graObjectBtype(data.zc_type);
      let secondArr = [];
      secondClass.map((item) => {
        secondArr.push(item.type);
      });
      if (secondArr.indexOf(data.second_class) === -1) {
        data.second_class = "";
      }
      //图片处理
      if (data.items1.length > 0) {
        data["img"] = data.items1.map((item, index) => {
          return {
            uid: index,
            id: item.id,
            img_path: item.img_path,
            status: "done",
            url: item.img_path,
            name: index,
          };
        });
      }
      //意向区域
      data.location = [];
      if (data.province_code !== 0) {
        data.location[0] = data.province_code.toString();
      }
      if (data.city_code && data.city_code !== "") {
        if (data.city_code !== 0 && data.province_code !== 0) {
          data.location[1] = data.city_code.toString();
        } else {
          data.location[0] = data.city_code.toString();
        }
      }
      if (data.district_code && data.district_code !== "") {
        if (
          data.district_code !== 0 &&
          data.city_code !== 0 &&
          data.province_code !== 0
        ) {
          data.location[2] = data.district_code.toString();
        } else {
          data.location[1] = data.district_code.toString();
        }
      }
      //竞价周期
      if (data.bid_cycle !== 0) {
        if (data.bid_cycle === 24 || data.bid_cycle === 12) {
          data.end_time = data.bid_cycle;
        } else {
          setValue1(0);
          data.otherEndTime = moment(data.end_time, "");
          setOtherEndTime(data.otherEndTime);
          data.end_time = 0;
        }
      }
      //开拍时间
      if (data.start_time === "0000-00-00 00:00:00" || !data.start_time) {
        data.start_time = null;
      } else {
        data.start_time = moment(data.start_time, "");
      }
      //处置平台
      if (data.source) {
        sourceSelect.map((item) => {
          if (item.label === data.source) {
            data.source = item.value;
          }
        });
      }
      if (data.additional) {
        if (data.additional.announcement) {
          data.announcement = data.additional.announcement;
        }
        if (data.additional.introduce) {
          data.introduce = data.additional.introduce;
        }
        if (data.additional.introduce) {
          data.should_know = data.additional.should_know;
        }
      }
      setApplyData(data);

      dataFillForm({
        form,
        data,
      });
    });
  };

  //根据不同的资产类型来获得相对的标的分类
  const graObjectBtype = (type) => {
    ajax({
      path: "/customer/Index/graObjectBtype",
      data: { type },
    }).then((res) => {
      if (res) {
        setSecond_class(res);
        secondClass = res;
      }
    });
  };
  // 委托方下拉
  const getClientList = () => {
    console.log('1')
    ajax({
      path: '/customer/Index/getClientList',
    }).then((res) => {
      console.log('2', res)
      setClientList(res.data)
    })
  }
  useEffect(() => {
    getClientList();
    setAction(queryToData().action);
    if (queryToData().id) {
      graObjectBtype(queryToData().type);
      getObjDetail();
    } else {
      graObjectBtype(2);
    }
  }, []);
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/subject/subjectList/index"}>
              <span>标的物管理</span>
            </Link>
            <Link to={"/creditorUser/subject/onShelf"}>
              <RightOutlined className={"iconArrow"} />
              <span>标的物上架</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>资产推介新增</span>
          </li>
        </ul>
      </div>
      <div className={"onShelfDetail_container"}>
        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            zc_type: 3,
          }}
        >
          {/* <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"项目类型"}
                name={"zc_type"}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  disabled={queryToData().id ? true : false}
                  onChange={(e) => {
                    setType(e);
                    graObjectBtype(e);
                    form.setFieldsValue({
                      second_class: "",
                    });
                  }}
                >
                  <Option value={2}>资产拍卖</Option>
                  <Option value={3}>资产推介</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"资产分类"}
                name={"second_class"}
                rules={[{ required: true, message: "" }]}
              >
                <Select>
                  {second_class.map((item, index) => {
                    return (
                      <Option key={item.type} value={item.type}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {type === 2 && (
            <>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan} className={"location_pick"}>
                  <Form.Item
                    label={"地图定位"}
                    name={"coordinate"}
                    rules={[{ required: true, message: "请填写标的物经纬度" }]}
                  >
                    <Input placeholder={"请填写标的物经纬度"} />
                  </Form.Item>
                  <Button
                    className={"btn-primary-bg location_pick_btn"}
                    onClick={() => setVisible(true)}
                  >
                    地图选取
                  </Button>
                </Col>
              </Row>
              <Modal
                width={900}
                height={500}
                title="地图选取"
                visible={visible}
                onOk={() => {
                  if (lngLat) {
                    form.setFieldsValue({
                      coordinate: `${lngLat.lat},${lngLat.lng}`,
                    });
                    setVisible(false);
                  } else {
                    message.error("未选中任何地方");
                  }
                }}
                onCancel={() => setVisible(false)}
                bodyStyle={{ padding: 0 }}
                wrapClassName={"aMapModal"}
              >
                <AMap
                  onChangeLngLat={setLngLat}
                  width={"900px"}
                  height={"500px"}
                />
              </Modal>
            </>
          )}
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"所在区域"}
                name="location"
                rules={[{ required: true, message: "此项为必填项" }]}
              >
                {applyData.location ? (
                  <Cascader
                    key={"select1"}
                    fieldNames={{
                      label: "label",
                    }}
                    options={address}
                    placeholder="请选择"
                    changeOnSelect={true}
                  />
                ) : (
                  <Cascader
                    key={"select2"}
                    defaultValue={[]}
                    fieldNames={{
                      label: "label",
                      value: "value",
                      children: "children",
                    }}
                    options={address}
                    placeholder="请选择"
                    changeOnSelect={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"标的物名称"}
                name={"object_title"}
                rules={[{ required: true, message: "请填写标的物名称" }]}
              >
                <Input placeholder={"请填写标的物名称"} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"图片"}
                name="img"
                extra="支持批量上传，最多4张图，至少1张图，10MB单张限制，尺寸在5500x5500以内，支持JPG、PNG、JPEG、BMP格式。"
                rules={[{ required: true, message: "请选择图片" }]}
              >
                {applyData?.img ? (
                  <ImgUploader
                    maxNumber={4}
                    multiple
                    key={"imgs"}
                    defaultFileList={applyData.img || []}
                  />
                ) : (
                  <ImgUploader multiple maxNumber={4} key={"imgs2"} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"评估价"}
                name={"assess_price"}
                rules={[
                  { required: true, message: "该项为必填项" },
                  { validator: inputMinValid },
                ]}
              >
                <Input
                  type="number"
                  min={0}
                  suffix="元"
                  placeholder={"请填写评估价"}
                />
              </Form.Item>
            </Col>
          </Row>
          {type === 3 && (
            <>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"折扣"}
                    name={"discount_rate"}
                    rules={[
                      { required: true, message: "该项为必填项" },
                      { validator: inputZkValid },
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      suffix="折"
                      step="0.1"
                      placeholder={"请填写折扣，如：8.5"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"预期投资回报率"}
                    name={"expected_investment"}
                    rules={[
                      { required: true, message: "该项为必填项" },
                      { validator: inputSYValid },
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      suffix="%"
                      placeholder={"请填写预期收益率，0-100之间数字，如：60"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"委托方"}
                    name={"client_id"}
                    rules={[{ required: true, message: "必填" }]}
                  >
                    <Select placeholder={"请选择委托方"} >
                      {clientList.map((item, index) => {
                        return (
                          <Option key={item.client_id} value={item.client_id}>
                            {item.client_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {type === 2 && (
            <>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"起拍价"}
                    name={"initial_price"}
                    rules={[
                      { required: true, message: "该项为必填项" },
                      { validator: inputMinValid },
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      suffix="元"
                      placeholder={"请填写起拍价"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"保证金"}
                    name={"security_deposit"}
                    rules={[
                      { required: true, message: "该项为必填项" },
                      { validator: inputMinValid },
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      suffix="元"
                      placeholder={"请填写保证金"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"加价幅度"}
                    name={"bid_increment"}
                    rules={[
                      { required: true, message: "该项为必填项" },
                      { validator: inputMinValid },
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      suffix="元"
                      placeholder={"请填写加价幅度"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"开拍时间"}
                    name="start_time"
                    rules={[{ required: true, message: "此项为必填项" }]}
                  >
                    {applyData.start_time ? (
                      <>
                        <DatePicker
                          key={"start"}
                          defaultValue={applyData.start_time}
                          showTime
                          format="YYYY-MM-DD HH"
                          style={{ width: "100%" }}
                        />
                      </>
                    ) : (
                      <DatePicker
                        key={"start1"}
                        defaultValue={""}
                        showTime
                        format="YYYY-MM-DD HH"
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"竞价结束时间"}
                    name={"end_time"}
                    rules={[
                      { required: true, message: "该项为必填项" },
                      { validator: inputIntValid },
                    ]}
                  >
                    <Radio.Group value={value1}>
                      <Radio
                        onClick={(e) => setValue1(parseInt(e.target.value))}
                        className={"radioStyle"}
                        value={24}
                      >
                        竞拍开始起24小时
                      </Radio>
                      <Radio
                        onClick={(e) => setValue1(parseInt(e.target.value))}
                        className={"radioStyle"}
                        value={12}
                      >
                        竞拍开始起12小时
                      </Radio>
                      <Radio
                        onClick={(e) => setValue1(parseInt(e.target.value))}
                        className={"radioStyle radioBlockStyle "}
                        value={0}
                      >
                        自定义&nbsp;
                        {value1 === 0 && (
                          <>
                            {applyData.otherEndTime ? (
                              <DatePicker
                                key={"end1"}
                                defaultValue={applyData.otherEndTime}
                                onChange={(e) => {
                                  if (e) {
                                    if (
                                      e.diff(
                                        moment(
                                          form.getFieldsValue().start_time
                                        ),
                                        "minute"
                                      ) < 59
                                    ) {
                                      message.error(
                                        "结束时间不能小于或等于开始时间"
                                      );
                                    } else {
                                      setOtherEndTime(e);
                                    }
                                  }
                                }}
                                showTime
                                format="YYYY-MM-DD HH"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              <>
                                <DatePicker
                                  key={"end2"}
                                  defaultValue={otherEndTime}
                                  onChange={(e) => {
                                    if (e) {
                                      if (
                                        e.diff(
                                          moment(
                                            form.getFieldsValue().start_time
                                          ),
                                          "minute"
                                        ) < 59
                                      ) {
                                        message.error(
                                          "结束时间不能小于或等于开始时间"
                                        );
                                        setOtherEndTime(
                                          form.getFieldsValue().start_time
                                        );
                                      } else {
                                        setOtherEndTime(e);
                                      }
                                    }
                                  }}
                                  showTime
                                  format="YYYY-MM-DD HH"
                                  style={{ width: "100%" }}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"公告"}
                name={"announcement"}
                rules={[{ required: true, message: "此项为必填项" }]}
              >
                {applyData.additional?.announcement ? (
                  <CKEditor
                    key={"announce1"}
                    config={ckeditorConfig}
                    data={applyData.additional?.announcement}
                    onBeforeLoad={(CKEDITOR) =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                  />
                ) : (
                  <CKEditor
                    key={"announce2"}
                    config={ckeditorConfig}
                    data={""}
                    onBeforeLoad={(CKEDITOR) =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          {type === 2 && (
            <Row gutter={defaultGutter}>
              <Col {...editFormColSpan}>
                <Form.Item
                  label={"须知"}
                  name={"should_know"}
                  rules={[{ required: true, message: "此项为必填项" }]}
                >
                  {applyData.additional?.should_know ? (
                    <CKEditor
                      key={"should1"}
                      config={ckeditorConfig}
                      data={applyData.additional?.should_know}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                    />
                  ) : (
                    <CKEditor
                      key={"should2"}
                      config={ckeditorConfig}
                      data={""}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={"标的物详情"}
                name={"introduce"}
                rules={[{ required: true, message: "此项为必填项" }]}
              >
                {applyData.additional?.introduce ? (
                  <CKEditor
                    key={"intro1"}
                    config={ckeditorConfig}
                    data={applyData.additional?.introduce}
                    onBeforeLoad={(CKEDITOR) =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                  />
                ) : (
                  <CKEditor
                    key={"intro2"}
                    config={ckeditorConfig}
                    data={""}
                    onBeforeLoad={(CKEDITOR) =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          {type === 2 && (
            <>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    label={"处置平台"}
                    name={"source"}
                    rules={[{ required: true, message: "此项为必填项" }]}
                  >
                    <Select>
                      {sourceSelect.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={defaultGutter}>
                <Col {...editFormColSpan}>
                  <Form.Item
                    name={"source_url"}
                    label={"处置平台连接"}
                    rules={[{ required: true, message: "此项为必填项" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>

        <div className={"handle_btn_group"}>
          <Button type={"primary"} onClick={() => handleSubmit(1)}>
            提交
          </Button>
          {/* <Button type={"primary"} onClick={() => handleSubmit(2)}>
            保存
          </Button> */}
          <Button
            onClick={() => history.goBack()}
            className={"cancel"}
            type={"primary"}
          >
            取消
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OnShelfDetail;
