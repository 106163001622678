import ManageAccount from "../account/accountIndex";
import InstitutionInfo from "../account/institutionInfo";
import CertificationIndex from "../account/certification/certificationIndex";
import CertificationDetail from "../account/certification/certificationDetail";
import BankDetail from "../account/certification/bankDetail";
import ManagePasswordIndex from "../account/managePassword/managePasswordIndex";
import ModifyLoginPassword from "../account/managePassword/modifyPassword/index";
import ModifyPayPassword from "../account/managePassword/modifyPassword/modifyPayPassword";
import SetPayPassword from "../account/managePassword/setPayPassword";
import SubjectList from "../subject/subjectList";
import SubjectDetail from "../subject/subjectDetail";
import SubjectPromote from "../subject/subjectPromote";
import SubjectPromoteList from "../subject/subjectPromoteIndex";
import OnShelf from "../subject/onshelf/onshelfIndex";
import OnShelfDetail from "../subject/onshelf/onShelfDetail";
import Claim from "../subject/onshelf/claim/claimIndex";
import ClaimSubmit from "../subject/onshelf/claim/claimSubmit";
import settlementList from "../settlement/settlementIndex";

import FinanceList from "../finance/financeReportList";
import FinanceDetail from "../finance/financeReportDetail";
import FinanceAccount from "../finance/accountManage/accountManageIndex";
import FinanceWithDraw from "../finance/accountManage/withDraw";

const routeMap = [
  {
    path: "creditorUser/account",
    children: [
      {
        path: "index",
        component: ManageAccount,
      },
      {
        path: "institutionInfo",
        component: InstitutionInfo,
      },
      {
        path: "certification",
        children: [
          { path: "index", component: CertificationIndex },
          { path: "bankDetail", component: BankDetail },
          { path: "certificationDetail", component: CertificationDetail },
        ],
      },
      {
        path: "managePassword",
        children: [
          { path: "index", component: ManagePasswordIndex },
          { path: "modifyLoginPassword", component: ModifyLoginPassword },
          { path: "modifyPayPassword", component: ModifyPayPassword },
          { path: "setPayPassword", component: SetPayPassword },
        ],
      },
    ],
  },
  {
    path: "creditorUser/subject",
    children: [
      { path: "subjectList", component: SubjectList },
      { path: "subjectDetail", component: SubjectDetail },
      { path: "subjectPromoteIndex", component: SubjectPromoteList },
      { path: "subjectPromote", component: SubjectPromote },
      { path: "onShelf", component: OnShelf },
      { path: "onShelfDetail", component: OnShelfDetail },
      { path: "claim", component: Claim },
      { path: "claimSubmit", component: ClaimSubmit },
    ],
  },
  {
    path: "creditorUser/settlement",
    component: settlementList,
  },
  {
    path: "creditorUser/finance",
    children: [
      { path: "financeList", component: FinanceList },
      { path: "financeDetail", component: FinanceDetail },
      { path: "financeAccount", component: FinanceAccount },
      { path: "financeWithDraw", component: FinanceWithDraw },
    ],
  },
];

export default routeMap;
