import React, { useState, useEffect } from "react";
import Header from "../../../component/Head";
import Footer from "../../../component/Footer";
import queryToData from "../../../func/queryToData";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Radio,
  message,
  DatePicker,
  Cascader,
  Steps,
} from "antd";

import {
  defaultGutter,
  editFormColSpan,
  formItemLayout,
} from "../../../static/formLayoutConfig";

import { address } from "../../../static/address";
import ImgUploader from "../../../component/ImgUploader";

import "./style.scss";
import { Link } from "react-router-dom";
import formValid from "../../../func/formValid";
import ajax from "../../../func/ajax";
import moment from "moment";
import dataFillForm from "../../../func/dataFillForm";

const { Step } = Steps;
export default () => {
  const [type, setType] = useState(2);
  const [status, setStatus] = useState("0");
  const [step, setStep] = useState(0);
  const [form] = Form.useForm();
  const [liked, setLiked] = useState(true);
  const [count, setCount] = useState(60);
  const [account, setAccount] = useState(null);
  const [applyData, setApplyData] = useState({});
  const [isModify, setIsModify] = useState(false);

  useEffect(() => {
    const queryData = queryToData();
    if (queryData.status) {
      setStatus(queryData.status);
      if (queryData.status === "2" || queryData.status === "3") {
        getApplyInfo();
        setAccount(JSON.parse(localStorage.userInfo).account);
        setStep(1);
        setIsModify(true);
      } else {
        setType(2);
      }
    }
  }, []);

  //获取债权人申请信息
  const getApplyInfo = () => {
    ajax({
      path: "/creditor/v1/merchants",
      method: "get",
    }).then((res) => {
      let data = res;
      let f_id_cardArr = [];
      let r_id_cardArr = [];
      let business_licenseArr = [];
      f_id_cardArr.push(data.f_id_card);
      r_id_cardArr.push(data.r_id_card);
      data.f_id_card = f_id_cardArr;
      data.r_id_card = r_id_cardArr;
      data["f_id_card"] = data.f_id_card.map((item, index) => {
        return {
          uid: index,
          status: "done",
          url: item,
          name: index,
        };
      });
      data["r_id_card"] = data.r_id_card.map((item, index) => {
        return {
          uid: index,
          status: "done",
          url: item,
          name: index,
        };
      });
      if (data.business_license) {
        business_licenseArr.push(data.business_license);
        data.business_license = business_licenseArr;
        data["business_license"] = data.business_license.map((item, index) => {
          return {
            uid: index,
            status: "done",
            url: item,
            name: index,
          };
        });
      }
      setApplyData(data);
      setType(data.c_type);
      dataFillForm({
        form,
        data,
      });
    });
  };

  //倒计时
  const sendCode = () => {
    let counts = count - 1;
    const timer = setInterval(() => {
      setCount(counts--);
      setLiked(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked(true);
        setCount(60);
      }
    }, 1000);
  };

  //ajax获取验证码
  const getCode = (phone) => {
    ajax({
      path: `/creditor/iphonesend/${phone}`,
      data: { phone },
      method: "get",
      isNeedToken: false,
    }).then((res) => {
    });
  };

  //验证码操作
  const verifiedSubmit = () => {
    let { phone } = form.getFieldsValue();
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      sendCode();
      getCode(phone);
    } else {
      message.error("请输入正确的手机号");
      return;
    }
  };
  //表单提交
  const handleSubmit = () => {
    formValid(form).then(() => {
      let {
        name,
        c_type,
        email,
        link_name,
        phone,
        company_address,
        company_address_add,
        phone_code,
        legal_person,
        id_card,
        f_id_card,
        r_id_card,
        credit_code,
        business_license,
        registered_date,
      } = form.getFieldsValue();

      //判断是否为修改信息，如果是修改则需要传id
      let id;
      if (queryToData().status === "3") {
        id = applyData.id;
        //如果存在url则未被修改
        if (f_id_card[0].url) {
          f_id_card = f_id_card[0].url;
        } else {
          f_id_card = f_id_card[0];
        }
        if (r_id_card[0].url) {
          r_id_card = r_id_card[0].url;
        } else {
          r_id_card = r_id_card[0];
        }
      } else {
        id = null;
        if (!company_address_add) {
          company_address_add = "";
        }
        company_address = company_address.join("") + company_address_add;
        f_id_card = f_id_card[0];
        r_id_card = r_id_card[0];
      }
      //判断是否为企业申请
      if (c_type === 1) {
        if (typeof registered_date !== "string") {
          registered_date = moment(registered_date).format("YYYY-MM-DD");
        }
        if (business_license[0].url) {
          business_license = business_license[0].url;
        } else {
          business_license = business_license[0];
        }
      }
      ajax({
        path: "/creditor/v1/apply",
        data: {
          id,
          name,
          c_type,
          email,
          link_name,
          company_address,
          phone,
          phone_code,
          legal_person,
          id_card,
          f_id_card,
          r_id_card,
          credit_code,
          business_license,
          registered_date,
        },
      }).then((res) => {
        if (res.status === 200) {
          window.scrollTo(0, 0);
          setStep(1);
          setStatus("2");
          if (queryToData().status) {
            setAccount(JSON.parse(localStorage.userInfo).account);
          } else {
            setAccount(res.data.account);
          }
        }
      });
    });
  };

  return (
    <div className={"institutionApply-container"}>
      <Header currentIndex={5} />
      <div className={"institutionApply-content"}>
        <div className={"institutionApply-main"}>
          <div className={"institutionApply-header container-1200"}>
            <Steps current={step} status={status === "3" ? "error" : "process"}>
              <Step title="填写入驻资料" description="准备机构资料" />
              {status === "0" && (
                <Step title="等待审核" description="工作人员审核" />
              )}
              {status === "2" && (
                <Step title="等待审核" description="工作人员审核" />
              )}
              {status === "3" && <Step title="审核拒绝" description="已拒绝" />}
            </Steps>
          </div>
          <div className={"institutionApply-form container-1200"}>
            {step === 0 && (
              <>
                <Form
                  form={form}
                  {...formItemLayout}
                  initialValues={{
                    c_type: 2,
                  }}
                >
                  <div className={"institutionApply-form-item"}>
                    <h3>填写基本信息</h3>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={"我所属的机构类型"}
                          name="c_type"
                          rules={[{ required: true, message: "该项为必填项" }]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              setType(e.target.value);
                            }}
                          >
                            <Radio value={2}>自然人</Radio>
                            <Radio value={1}>企业</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={"姓名或公司名称"}
                          name="name"
                          rules={[{ required: true, message: "该项为必填项" }]}
                        >
                          <Input
                            placeholder={"请输入姓名或公司名称"}
                            allowClear
                            disabled={isModify ? true : false}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={"邮箱"}
                          name="email"
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "请输入正确的邮箱地址",
                            },
                          ]}
                        >
                          <Input
                            disabled={isModify ? true : false}
                            placeholder={"请输入邮箱"}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className={"institutionApply-form-item"}>
                    <h3>填写联系人信息</h3>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={"联系人"}
                          name="link_name"
                          rules={[{ required: true, message: "该项为必填项" }]}
                        >
                          <Input placeholder={"请输入联系人姓名"} allowClear />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={"联系人地址"}
                          name="company_address"
                          rules={[{ required: true, message: "该项为必填项" }]}
                        >
                          {queryToData().status !== "3" ? (
                            <Cascader
                              fieldNames={{
                                label: "title",
                                value: "title",
                                children: "children",
                              }}
                              options={address}
                              placeholder="请选择地址"
                            />
                          ) : (
                            <Input placeholder={"请输入地址"} />
                          )}
                        </Form.Item>
                      </Col>
                      {queryToData().status !== "3" && (
                        <Col {...editFormColSpan}>
                          <Form.Item label={""} name="company_address_add">
                            <Input placeholder={"请输入详细地址"} allowClear />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={"联系人手机号"}
                          name="phone"
                          rules={[
                            {
                              required: true,
                              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                              message: "请输入正确的手机号码",
                            },
                          ]}
                        >
                          <Input
                            placeholder={"请输入联系人手机号"}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={"手机验证码"}
                          name="phone_code"
                          rules={[{ required: true, message: "该项为必填项" }]}
                        >
                          <Input placeholder={"请输入手机验证码"} allowClear />
                        </Form.Item>
                      </Col>
                      <Col {...editFormColSpan}>
                        <Button
                          disabled={liked ? false : true}
                          onClick={verifiedSubmit}
                          className={liked ? "verificationCode" : ""}
                        >
                          {liked ? "获取验证码" : count + "秒后重发"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className={"institutionApply-form-item"}>
                    <h3>填写入驻信息</h3>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={type === 1 ? "法人姓名" : "姓名"}
                          name="legal_person"
                          rules={[{ required: true, message: "该项为必填项" }]}
                        >
                          <Input placeholder={"请输入姓名"} allowClear />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={type === 1 ? "法人身份证号码" : "身份证号码"}
                          name="id_card"
                          rules={[{ required: true, message: "身份证号码" }]}
                        >
                          <Input
                            placeholder={"请输入法人身份证号码"}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={
                            type === 1 ? "法人身份证正面照" : "身份证正面照"
                          }
                          name="f_id_card"
                          rules={[{ required: true, message: "" }]}
                        >
                          {
                            applyData.f_id_card ?(
                              <ImgUploader
                              maxNumber={1}
                              defaultFileList={applyData.f_id_card}
                            />
                            ):(
                              <ImgUploader
                              maxNumber={1}
                            />
                            )
                          }
 
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={defaultGutter}>
                      <Col {...editFormColSpan}>
                        <Form.Item
                          label={
                            type === 1 ? "法人身份证反面照" : "身份证反面照"
                          }
                          name="r_id_card"
                          rules={[{ required: true, message: "该项为必填项" }]}
                        >
                          {
                            applyData.r_id_card ? (
                              <ImgUploader
                                maxNumber={1}
                                defaultFileList={applyData.r_id_card}
                              />
                            ) : (
                              <ImgUploader
                                maxNumber={1}
                              />
                            )
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                    {type === 1 && (
                      <>
                        {form.getFieldsValue().credit_code}
                        <Row gutter={defaultGutter}>
                          <Col {...editFormColSpan}>
                            <Form.Item
                              label={"统一社会信用代码"}
                              name="credit_code"
                              rules={[
                                { required: true, message: "该项为必填项" },
                              ]}
                            >
                              <Input
                                placeholder={"请输入统一社会信用代码"}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={defaultGutter}>
                          <Col {...editFormColSpan}>
                            <Form.Item
                              label={"企业营业执照照片"}
                              name="business_license"
                              rules={[
                                { required: true, message: "该项为必填项" },
                              ]}
                            >
                              {applyData.business_license &&
                              applyData.c_type === 1 ? (
                                <ImgUploader
                                  key={"upload1"}
                                  maxNumber={1}
                                  defaultFileList={applyData.business_license}
                                />
                              ) : (
                                <ImgUploader
                                  key={"upload2"}
                                  maxNumber={1}
                                 
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={defaultGutter}>
                          <Col {...editFormColSpan}>
                            <Form.Item
                              label={"企业注册时间"}
                              name="registered_date"
                              rules={[
                                { required: true, message: "该项为必填项" },
                              ]}
                            >
                              {applyData.registered_date &&
                              applyData.c_type === 1 ? (
                                <DatePicker
                                  defaultValue={moment(
                                    applyData.registered_date,
                                    ""
                                  )}
                                  format="YYYY-MM-DD"
                                  style={{ width: "100%" }}
                                />
                              ) : (
                                <DatePicker
                                  format="YYYY-MM-DD"
                                  style={{ width: "100%" }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </Form>
                <div className={"handle_submit"}>
                  <Button onClick={handleSubmit} type={"primary"}>
                    下一步
                  </Button>
                </div>
              </>
            )}

            {step === 1 && status === "2" && (
              <div className={"waiting_result"}>
                <div className={"institutionApply-form-item"}>
                  <h3>申请名称</h3>
                  <div>
                    <img
                      src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/97ac8fe49eb2968235fd3b23dd2ae926.png"
                      alt=""
                    />
                    <div>
                      您的入驻申请已提交，三个工作日内会有工作人员联系您核实，请耐心等待
                    </div>
                    <div>
                      <span className={"success_info"}>您已注册成功！</span>
                      您的账户:&nbsp;
                      <span>{account} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                      您的密码:&nbsp;
                      <span>手机号后六位</span>
                    </div>
                    <Button className={"handleBack"} type={"primary"}>
                      <Link to={"/institution"}>返回</Link>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {step === 1 && status === "3" && (
              <div className={"waiting_result"}>
                <div className={"institutionApply-form-item"}>
                  <h3>申请名称</h3>
                  <div>
                    <img
                      src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/cc647056f64bbd6e46b1aef1c7e7fdb2.png"
                      alt=""
                    />
                    <div>{applyData?.examine_remark}</div>
                    <Button
                      onClick={() => {
                        setStep(0);
                        setStatus("2");
                        getApplyInfo();
                      }}
                      className={"handleBack"}
                      type={"primary"}
                    >
                      <a>去修改</a>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
