import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Input, Row, Col, message } from "antd";
import formValid from "../../../../func/formValid";
import { PhoneOutlined } from "@ant-design/icons";
import CooperLogin from "../CooperLogin";
import "./style.scss";
import { customerResetPassword, getPhoneCode } from "../../../../api/login";

export default () => {
  const history = useHistory();
  const [liked, setLiked] = useState(true);
  const [count, setCount] = useState(60);
  const [form] = Form.useForm();

  const handleLogin = () => {
    formValid(form)
      .then(() => {
        let { validate_code, password, phone } = form.getFieldsValue();
        customerResetPassword({ validate_code, password, phone })
          .then((res) => {
            message.success("密码更新成功,请重新登录");
            setTimeout(() => {
              history.push("/login/g_login");
            }, 1000);
          })
          .catch();
      })
      .catch();
  };
  //倒计时
  const sendCode = () => {
    let counts = count - 1;
    const timer = setInterval(() => {
      setCount(counts--);
      setLiked(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked(true);
        setCount(60);
      }
    }, 1000);
  };

  //ajax获取验证码
  const getCode = (phone) => {
    getPhoneCode(phone)
      .then((res) => {})
      .catch();
  };

  //验证码操作
  const verifiedSubmit = () => {
    let { phone } = form.getFieldsValue();
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      sendCode();
      getCode(phone);
    } else {
      message.error("请输入正确的手机号");
      return;
    }
  };

  return (
    <div className={"creditor_signIn"}>
      <div className={"left_part gLeft_part"}>
        <div className={"left_part_title g_left_part_title"}>手机找回密码</div>
        <Form form={form} name="phone" className="login-form">
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                message: "请输入正确的手机号",
              },
            ]}
          >
            <Input
              prefix={
                <PhoneOutlined
                  style={{ color: "#1890FF" }}
                  className="site-form-item-icon"
                />
              }
              placeholder="请输入手机号码"
            />
          </Form.Item>
          <Form.Item>
            <Row gutter={20}>
              <Col span={15}>
                <Form.Item
                  name="validate_code"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "请输入验证码",
                    },
                  ]}
                >
                  <Input placeholder="请输入验证码" />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  disabled={liked ? false : true}
                  onClick={verifiedSubmit}
                  className={liked ? "verificationCode" : ""}
                >
                  {liked ? "获取验证码" : count + "秒后重发"}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                pattern: /^[0-9A-Za-z]{6,}$/,
                message: "密码长度不少于6位,数字+字母组成",
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="请输入你要设定的密码"
            />
          </Form.Item>
          <Form.Item
            name="password_confirm"
            dependencies={["password"]}
            rules={[
              { required: true, message: "请再次输入新密码" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("两次密码输入不一致！");
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              onPressEnter={handleLogin}
              placeholder="请再次输入你要设定的密码"
            />
          </Form.Item>

          <Form.Item>
            <Button
              className={"login-form-button"}
              type="primary"
              onClick={handleLogin}
            >
              重置密码
            </Button>
          </Form.Item>
        </Form>
        <div className={"textRight"}>
          <Link to={"/login/g_login"}>返回登录</Link>
        </div>
        <CooperLogin />
      </div>
      <div className={"right_part"}>
        <img
          src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/f6ea3827fc857ae1e3de81ae886ea0b8.jpeg?x-oss-process=image/format,webp"
          alt=""
        />
        <div>下载矩阵资产APP</div>
        <p>随时随地看资产</p>
      </div>
    </div>
  );
};
