import React, { useEffect, useState } from "react";

import "./style.scss";
import { Link } from "react-router-dom";
import { Button, Empty, Pagination } from "antd";
import { RightOutlined } from "@ant-design/icons";
import ajax from "../../../../func/ajax";
import { valueToLabel } from "../../../../func/fn";
import { payType, tradeStatus } from "../../../../static/option";

export default () => {
  const [fianceList, setFianceList] = useState([]);
  const [count, setCount] = useState(0);
  const [expenditure, setExpenditure] = useState(0);
  const [profit, setProfit] = useState(0);
  const [page, setPage] = useState(1);
  const size = 5;
  //财务报表
  const getFianceList = (newPagination) => {
    if (newPagination) {
      setPage(newPagination);
    }
    ajax({
      path: "/creditor/v1/o_detailed",
      data: { page: newPagination, size },
      method: "get",
    }).then((res) => {
      if (Array.isArray(res.data)) {
        setFianceList([]);
        setCount(0);
      } else {
        res.data.data.map((item) => {
          item.t_trade_type = item.t_trade_type === 1 ? "收入" : "支出";
          item.pay_type_text = valueToLabel({
            optionArray: payType,
            value: item.pay_type,
          });
          item.trade_status_text = valueToLabel({
            optionArray: tradeStatus,
            value: item.trade_status,
          });
          return item;
        });
        setFianceList(res.data.data);
        setCount(res.data.total);
      }
    });
  };
  //收入和支出
  const feeDetail = () => {
    ajax({
      path: "/creditor/v1/order/bill",
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        setExpenditure(res.expenditure);
        setProfit(res.profit);
      }
    });
  };
  useEffect(() => {
    getFianceList(1);
    feeDetail();
  }, []);
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>财务管理</span>
            <RightOutlined className={"iconArrow"} />
            <span>财务报表</span>
          </li>
        </ul>
      </div>
      <div className={"financeList_container"}>
        <div className={"main_content"}>
          <ul className={"main_content_item statistics"}>
            <li>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/46f33dd8fef44a61f3a8199cd4c5ae94.png"
                alt=""
              />
              <div className={"data"}>
                <div>¥ {profit}</div>
                <span>收入</span>
              </div>
            </li>
            <li>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/33cb6c92fc3acb65dc09bbbc4e3e968c.png"
                alt=""
              />
              <div className={"data"}>
                <div>¥ {expenditure}</div>
                <span>支出</span>
              </div>
            </li>
          </ul>
          <ul className={"financeList_content"}>
            {fianceList.map((item, index) => {
              return (
                <li className={"financeList_item"} key={index}>
                  <div className={"package_title"}>{item.snap_name}</div>
                  <ul className={"finance_detail"}>
                    <li>
                      <span>推广标的：</span>
                      {item.o_object_title}
                    </li>
                    <li>
                      <span>订单号：</span>
                      {item.order_num}
                    </li>
                    <li>
                      <span>创建时间：</span>
                      {item.createtime}
                    </li>
                    <li>
                      <span>支付时间：</span>
                      {item.pay_time}
                    </li>
                    <li>
                      <span>交易类型：</span>
                      {item.t_trade_type}
                    </li>
                    <li className={"short"}>
                      <span>支付方式：</span>
                      {item.pay_type_text}
                    </li>
                    <li>
                      <span>交易状态：</span>
                      {item.trade_status_text}
                    </li>
                  </ul>
                  <div className={"view"}>
                    <Button className={"btn-primary-border"}>
                      <Link
                        to={`/creditorUser/finance/financeDetail?id=${item.id}`}
                      >
                        查看
                      </Link>
                    </Button>
                  </div>
                </li>
              );
            })}
          </ul>
          {count !== 0 && (
            <Pagination
              style={{ textAlign: "right" }}
              total={count}
              pageSize={size}
              showSizeChanger={false}
              onChange={(e) => getFianceList(e)}
              showQuickJumper
              showTotal={(total) => `共 ${total} 条`}
            />
          )}
          {count === 0 && <Empty />}
        </div>
      </div>
    </div>
  );
};
