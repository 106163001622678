import React from "react";

const navMap = [
  {
    name: "账户管理",
    path: "/creditorUser/account/index",
    children: [
      {
        name: "商户概况",
        path: "/creditorUser/account/index",
        index: "1-0",
      },
      {
        name: "机构信息",
        path: "/creditorUser/account/institutionInfo",
        index: "1-0",
      },
      {
        name: "认证管理",
        path: "/creditorUser/account/certification/index",
        index: "1-1",
        childrenPath: [
          "/creditorUser/account/certification/bankDetail",
          "/creditorUser/account/certification/certificationDetail",
        ],
      },
      {
        name: "密码管理",
        path: "/creditorUser/account/managePassword/index",
        index: "1-2",
        childrenPath: [
          "/creditorUser/account/managePassword/modifyLoginPassword",
          "/creditorUser/account/managePassword/modifyPayPassword",
          "/creditorUser/account/managePassword/setPayPassword",
        ],
      },
    ],
  },
  {
    name: "标的物管理",
    path: "/creditorUser/subject/subjectList/index",
    children: [
      {
        name: "标的物列表",
        path: "/creditorUser/subject/subjectList",
        index: "2-1",
        childrenPath: [
          "/creditorUser/subject/subjectDetail",
          "/creditorUser/subject/subjectPromote",
        ],
      },
      {
        name: "标的物上架",
        path: "/creditorUser/subject/onShelf",
        index: "2-2",
        childrenPath: [
          "/creditorUser/subject/claim",
          "/creditorUser/subject/onShelfDetail",
        ],
      },
    ],
  },
  {
    name: "结算管理",
    path: "/creditorUser/settlement/index",
    children: [
      { name: "结算明细", path: "/creditorUser/settlement", index: "3-1" },
    ],
  },
  {
    name: "财务管理",
    path: "/creditorUser/finance/financeList/index",
    children: [
      {
        name: "财务报表",
        path: "/creditorUser/finance/financeList",
        index: "4-1",
        childrenPath: ["/creditorUser/finance/financeDetail"],
      },
      {
        name: "账户管理",
        path: "/creditorUser/finance/financeAccount",
        index: "4-2",
      },
    ],
  },
];

export default navMap;
