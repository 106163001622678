import React, { useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message, Steps, Col, Checkbox } from "antd";
import { RightOutlined } from "@ant-design/icons";
import ajax from "../../../../func/ajax";

const { Step } = Steps;
const steps = [
  { title: "身份验证" },
  { title: "修改手机号" },
  { title: "完成修改" },
];
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default () => {
  const history = useHistory();
  const [newPhone, setNewPhone] = useState(undefined);
  const [current, setCurrent] = useState(0);
  const [liked, setLiked] = useState(true);
  const [liked2, setLiked2] = useState(true);
  const [count, setCount] = useState(60);
  const [count2, setCount2] = useState(60);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  //验证手机号
  const checkPhone = () => {
    const { phone, validate_code, agree } = form.getFieldsValue();
    if (agree) {
      ajax({
        path: "/customer/User/checkPhone",
        data: { phone, validate_code },
      }).then((res) => {
        if (res.status === 200) {
          message.success("手机号验证成功");
          setCurrent(1);
          setCount(60);
          setLiked(true);
        }
      });
    } else {
      message.error("请先勾选协议");
    }
  };

  //设置手机号
  const setPhone = () => {
    const { phone, validate_code } = form2.getFieldsValue();
    ajax({
      path: "/customer/User/setPhone",
      data: { phone, validate_code },
    }).then((res) => {
      if (res.status === 200) {
        setNewPhone(phone);
        setCurrent(2);
        message.success("修改成功请重新登录");
        setTimeout(() => {
          localStorage.clear();
          history.push("/login/g_login");
        }, 1000);
      }
    });
  };

  //倒计时
  const sendCode = () => {
    let counts = count - 1;
    const timer = setInterval(() => {
      setCount(counts--);
      setLiked(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked(true);
        setCount(60);
      }
    }, 1000);
  };

  //倒计时
  const sendCode2 = () => {
    let counts = count2 - 1;
    const timer = setInterval(() => {
      setCount2(counts--);
      setLiked2(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked2(true);
        setCount2(60);
      }
    }, 1000);
  };

  //ajax获取验证码
  const getCode = (phone, step) => {
    ajax({
      path: `/creditor/iphonesend/${phone}`,
      data: { phone },
      method: "get",
      isNeedToken: false,
    }).then(
      (res) => {
        if (res && res.ct) {
          if (step === 1) {
            sendCode();
          } else {
            sendCode2();
          }
        } else {
          message.error(res.msg);
        }
      },
      (err) => {}
    );
  };

  //验证码操作
  const verifiedSubmit = () => {
    let { phone } = form.getFieldsValue();
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      getCode(phone, 1);
    } else {
      message.error("请输入正确的手机号");
      return;
    }
  };

  //获取新手机号验证码
  const newVerifiedSubmit = () => {
    let { phone } = form2.getFieldsValue();
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      getNewPhoneStatus(phone);
    } else {
      message.error("请输入正确的手机号");
      return;
    }
  };
  // 短信发送并验证手机号码是否已注册
  const getNewPhoneStatus = (phone) => {
    ajax({
      path: "/customer/current/send",
      data: { type: 1, phone },
    }).then((res) => {
      if (res && res.ct) {
        getCode(phone, 2);
      } else {
        message.error(res.msg);
      }
    });
  };
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>个人设置</span>
            <RightOutlined className={"iconArrow"} />
            <span>手机号修改</span>
          </li>
        </ul>
      </div>
      <div className={"modifyPhone-container"}>
        <Steps current={current} className={"step-content-footer"}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        {current === 0 && (
          <div className={"first-tips"}>
            我们将向该手机发送验证码，请在下方输入您看到的验证码
          </div>
        )}
        {current === 0 && (
          <div className={"steps-content"}>
            <Form
              {...layout}
              form={form}
              initialValues={{
                phone: JSON.parse(localStorage.userInfo).phone,
                agree: true,
              }}
            >
              <Form.Item
                label="原手机号"
                name={"phone"}
                rules={[
                  {
                    required: true,
                    pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                    message: "请输入正确格式的手机号码",
                  },
                ]}
              >
                <Input disabled placeholder={"请输入您的原手机号"} />
              </Form.Item>
              <Form.Item
                label="验 证 码"
                name={"validate_code"}
                style={{ display: "flex" }}
                rules={[
                  {
                    required: true,
                    message: "请输入验证码",
                  },
                ]}
              >
                <Input
                  placeholder={"请输入验证码"}
                  addonAfter={
                    <Button
                      disabled={liked ? false : true}
                      onClick={verifiedSubmit}
                      className={liked ? "verificationCode" : ""}
                    >
                      {liked ? "获取验证码" : count + "秒后重发"}
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item {...tailLayout} name="agree" valuePropName="checked">
                <Checkbox>同意原账户解绑第三方授权</Checkbox>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  type={"primary"}
                  style={{ width: "120px", height: "40px" }}
                  onClick={checkPhone}
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
        {current === 1 && (
          <div className={"steps-content"}>
            <Form
              {...layout}
              form={form2}
              initialValues={{
                phone: 123,
              }}
            >
              <Form.Item
                label="新手机号"
                name={"phone"}
                rules={[
                  {
                    required: true,
                    pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                    message: "请输入正确格式的手机号码",
                  },
                ]}
              >
                <Input placeholder={"请输入您的新手机号"} />
              </Form.Item>
              <Form.Item
                label="验 证 码"
                name={"validate_code"}
                style={{ display: "flex" }}
                rules={[
                  {
                    required: true,
                    message: "请输入验证码",
                  },
                ]}
              >
                <Input
                  placeholder={"请输入验证码"}
                  addonAfter={
                    <Button
                      disabled={liked2 ? false : true}
                      onClick={newVerifiedSubmit}
                      className={liked2 ? "verificationCode" : ""}
                    >
                      {liked2 ? "获取验证码" : count2 + "秒后重发"}
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  type={"primary"}
                  style={{ width: "120px", height: "40px" }}
                  onClick={setPhone}
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
        {current === 2 && (
          <div className={"steps-content third-steps-content"}>
            <img
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/97ac8fe49eb2968235fd3b23dd2ae926.png"
              alt=""
            />
            <span>手机号已修改为：{newPhone}</span>
          </div>
        )}
      </div>
    </div>
  );
};
