import myOrderList from "../myOrder/myOrderList/index";
import myOrderDetail from "../myOrder/myOrderDetail/index";
import MyAppoint from "../myTool/myAppoint/index";
import Subscribe from "../myTool/subscribe/index";
import ModifyPhone from "../mySetting/modifyPhone/index";
import ModifyPassword from "../mySetting/modifyPassword/index";
import Authorize from "../mySetting/authorize/index";

const routeMap = [
  {
    path: "user/myOrder",
    children: [
      {
        path: "myOrderList",
        component: myOrderList,
      },
      {
        path: "myOrderDetail",
        component: myOrderDetail,
      },
    ],
  },
  {
    path: "user/myTool",
    children: [
      {
        path: "myAppoint",
        component: MyAppoint,
      },
      {
        path: "subscribe",
        component: Subscribe,
      },
    ],
  },
  {
    path: "user/mySetting",
    children: [
      {
        path: "modifyPhone",
        component: ModifyPhone,
      },
      {
        path: "modifyPassword",
        component: ModifyPassword,
      },
      {
        path: "authorize",
        component: Authorize,
      },
    ],
  },
];

export default routeMap;
