import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ajax from "../../../../../func/ajax";

export default () => {
  const [settleState, setSettleState] = useState(null);
  const [bankState, setBankState] = useState(null);
  useEffect(() => {
    getChangestate();
    getBankstate();
  }, []);
  //查看入驻信息是否正在变更
  const getChangestate = () => {
    ajax({
      path: "/creditor/v1/changestate",
      method: "get",
    }).then((res) => {
      setSettleState(res.data.changestatus);
    });
  };
  //查看银行账户是否填写
  const getBankstate = () => {
    ajax({
      path: "/creditor/v1/bankstate",
      method: "get",
    }).then((res) => {
      setBankState(res.data);
    });
  };

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/account/index"}>
              <span>账户管理</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>认证管理</span>
          </li>
        </ul>
      </div>
      <div className={"certificationIndex_container"}>
        <div className={"manage_item"}>
          <div className={"manage_item_left"}>入驻信息</div>
          <span>入驻的身份信息</span>
          {settleState === 1 && (
            <Button className={"changing"}>变更审核中</Button>
          )}
          {settleState === 2 && (
            <div className={"manage_item_right"}>
              <Link
                to={"/creditorUser/account/certification/certificationDetail"}
              >
                修改
              </Link>
            </div>
          )}
          {settleState === 3 && (
            <div className={"manage_item_right"}>
              <Button className={"refuse"}>审核失败</Button>
              <Link
                to={"/creditorUser/account/certification/certificationDetail"}
              >
                修改
              </Link>
            </div>
          )}
        </div>
        <div className={"manage_item"}>
          <div className={"manage_item_left"}>银行账户信息</div>
          <span>在提现打款时确认账户信息正确</span>
          <div className={"manage_item_right"}>
            {bankState === 0 ? (
              <Link to={"/creditorUser/account/certification/bankDetail"}>
                填写
              </Link>
            ) : (
              <>
                <Link
                  to={
                    "/creditorUser/account/certification/bankDetail?action=view"
                  }
                >
                  查看
                </Link>
                <Link to={"/creditorUser/account/certification/bankDetail"}>
                  修改
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
