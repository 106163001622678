import React from "react";
import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
export default () => {
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>我的工具</span>
            <RightOutlined className={"iconArrow"} />
            <span>标的物订阅</span>
          </li>
        </ul>
      </div>
      <div className={"subscribe-container"}>
        <img
          src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/assets_customer_files/1617938098776_subscribe.png"
          alt=""
        />
      </div>
    </div>
  );
};
