import React from "react";

import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <Link to={"/creditorUser/subject/subjectList/index"}>
              <span>标的物管理</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>标的物上架</span>
          </li>
        </ul>
      </div>
      <div className={"onShelfIndex_container"}>
        <div className={"manage_item"}>
          <div className={"manage_item_left"}>司法拍卖</div>
          <span>做为申请执行人可认领标的物进行营销推广</span>
          <div className={"manage_item_right"}>
            <Link to={"/creditorUser/subject/claim"}>认领</Link>
          </div>
        </div>
        <div className={"manage_item"}>
          {/* <div className={"manage_item_left"}>资产拍卖、资产推介</div> */}
          <div className={"manage_item_left"}>资产推介</div>

          <span>可发布不良资产标的物，平台只做信息展示不做竞价拍卖</span>
          <div className={"manage_item_right"}>
            <Link to={`/creditorUser/subject/onShelfDetail?action=create`}>
              发布
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
