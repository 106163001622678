function transPrice(value, type = "default") {
  if (type == "default") {
    return value
      .toString()
      .replace(/(^|\s)\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
  } else if (type == "zh_cn") {
    if (value > 10000) {
      let milliForm = (input) => {
        return (
          input &&
          input
            .toString()
            .replace(/(^|\s)\d+/g, (m) =>
              m.replace(/(?=(?!\b)(\d{3})+$)/g, ",")
            )
        );
      };
      let price = Number.parseFloat(value);
      if (price >= 10000) {
        price = price / 10000;
        let pointIndex = String(price).indexOf(".");
        if (pointIndex > -1) {
          price = String(price).substr(0, pointIndex + 3);
          if (price.substr(pointIndex + 1, 2) == "00") {
            price = String(price).substring(0, pointIndex);
          }
        }
        price = price + "万";
      } else {
        price = milliForm(price) + "元";
      }
      return price;
    } else {
      return value
        .toString()
        .replace(/(^|\s)\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
    }
  }
}
export default transPrice;
