import React from 'react';
import './index.scss';

export default function serviceContract() {

    return (
        <div className='service-contract'>
            <div className='service-contract-item'>服务协议的确认和接纳</div>
            <br />
            <div className='service-contract-introduction'>
                矩阵资产网站由杭州矩石阵网络科技有限公司运营，涉及具体产品服务的，将由有资质的服务商提供。<b>如果用户（“用户”或“您”）在本网站提供的移动应用或软件上（以下简称“矩阵资产”），访问、预定或使用矩阵资产的产品或服务（以上统称为“服务”），便视为用户接受了以下免责说明（下称“本服务协议”或“本协议”），请您仔细阅读以下内容，尤其是以下加粗字体。如果您不同意以下任何内容，请立刻停止访问/使用本网站或其他任何移动应用或软件所提供的相关服务。</b>
            </div>
            <br></br>
            <div className='service-contract-content'>
                <div className='rules'>
                    <li>1、总则</li>
                    <p>本协议内容包括协议正文、矩阵资产子频道各单项服务协议及其矩阵资产已经发布的或将来可能发布的各类规则，包括但不限于免责声明、隐私政策、账户协议等其他协议（“其他条款”）。如果本协议与“其他条款”有不一致之处，则以“其他条款”为准。除另行明确声明外，任何矩阵资产网提供的服务均受本协议约束。</p>
                    <p>若您作为矩阵资产网的关联公司或合作公司的用户登陆矩阵资产网平台，访问矩阵资产网网站或使用矩阵资产网服务，即视为您同意本协议的所有条款及矩阵资产网公布的其他规则、说明和操作指引。</p>
                </div>
                <div className='introduction'>
                    <li>2、服务简介</li>
                    <div className='must'>
                        <p>杭州矩石阵网络科技有限公司运用自己的操作系统通过国际互联网络为用户提供网络会员服务。用户必须：</p>
                        <p>⑴提供设备，包括个人电脑一台、调制解调器一个及配备上网装置。</p>
                        <p>⑵个人上网和支付与此服务有关的电话费用。</p>
                    </div>
                    <div className='must'>
                        <p>考虑到<b>矩阵资产</b>网络会员服务的重要性，用户同意：</p>
                        <p>⑴提供及时、详尽及准确的个人资料。</p>
                        <p>⑵不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。</p>
                    </div>
                    <div className='must'>
                        <p>另外，用户可授权杭州矩石阵网络科技有限公司向第三方透露其基本资料，但杭州矩石阵网络科技有限公司不能公开用户的补充资料。除非：</p>
                        <p>⑴用户要求杭州矩石阵网络科技有限公司或授权某人通过电子邮件服务透露这些信息。</p>
                        <p>⑵相应的法律要求及程序要求杭州矩石阵网络科技有限公司提供用户的个人资料。</p>
                    </div>
                    <div className='must'>
                        <p>如果用户提供的资料不准确，杭州矩石阵网络科技有限公司保留结束用户使用矩阵资产网络会员服务的权利。
                        用户在享用矩阵资产网络会员服务的同时，同意接受矩阵资产网络会员服务提供的各类信息服务，<b>包括但不限于EDM、短信、矩阵资产App信息推送、PC端广告等服务类及营销类信息。</b></p>
                    </div>
                </div>
                <div className='modify'>
                    <li>3、服务条款的修改</li>
                    <p>矩阵资产网会在必要时修改服务条款，如制订、修改本协议及/或各类规则向用户提供基于互联网以及移动网的相关服务的，应在本页面及其相应页面提前公布通知，用户应该定期登陆本页面及其他相关页面，了解最新的协议内容。变更后的协议和规则在本页面及相关页面公布后七天，将自动生效。<b>如您不同意相关变更，应当立即停止访问矩阵资产网或使用矩阵资产服务，若用户在网站协议和规则变更七日后继续使用矩阵资产网服务的，即表示您接受已经修订的协议和规则。</b></p>
                </div>
                <div className='change'>
                    <li>4、服务变更、中断、终止</li>
                    <p>您完全理解并同意，本服务涉及到互联网及移动通讯等服务，可能会受到各个环节不稳定因素的影响。因此任何因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、GSM网络、互联网络、通信线路等其他矩阵资产网无法预测或控制的原因，造成服务中断、取消或终止的风险，由此给您带来的损失矩阵资产网不承担赔偿责任。</p>
                    <p>矩阵资产网需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断，矩阵资产网无需为此承担任何责任，但矩阵资产网应尽可能事先进行通知。</p>
                    <p>您完全理解并同意，除本服务协议另有规定外，鉴于网络服务的特殊性，矩阵资产网有可能变更、中断或终止部分或全部的网络服务，矩阵资产网无需为此承担任何责任，但矩阵资产网应尽可能事先进行通知，并尽可能给您预留时间以便处理相关权益。</p>
                </div>
                <div className='use-rule'>
                    <li>5、使用规则</li>
                    <p>用户在使用矩阵资产网服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用该服务进行任何违法或不正当的活动，包括但不限于下列行为：</p>
                    <div className='rule-items'>
                        <p><b>上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：</b></p>
                        <p>(1)反对宪法所确定的基本原则的；</p>
                        <p>(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                        <p>(3)损害国家荣誉和利益的；</p>
                        <p>(4)煽动民族仇恨、民族歧视、破坏民族团结的；</p>
                        <p>(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                        <p>(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                        <p>(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
                        <p>(8)侮辱或者诽谤他人，侵害他人合法权利的；</p>
                        <p>(9)含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
                        <p>(10)含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</p>
                    </div>
                    <div className='activity'>
                        <p><b>为任何非法目的而使用网络服务系统。 利用矩阵资产网络服务从事以下活动：</b></p>
                        <p>(1)未经允许，进入计算机信息网络或者使用计算机信息网络资源的； 未经允许，对计算机信息网络功能进行删除、修改或者增加的；未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；故意制作、传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的行为。</p>
                        <p>(2)对矩阵资产网站上的任何数据作商业性利用，包括但不限于在未经矩阵资产事先书面同意的情况下，以复制、传播等任何方式使用矩阵资产网站上展示的资料。</p>
                        <p>(3)使用任何装置、软件或例行程序等其他方式干预或试图干预矩阵资产网站的正常运作或正在矩阵资产网站上进行的任何交易、活动，或采取任何将导致不合理的庞大数据负载加诸矩阵资产网络设备的行动。</p>
                        <p>(4)违反诚实信用原则的不正当竞争行为，或恶意下订单或虚假交易等其他恶意扰乱矩阵资产交易秩序的行为</p>
                        <p>(5)与网上交易无关的其他行为。</p>
                    </div>
                    <div className='copyright'>
                        <li>6、版权声明</li>
                        <p>任何会员接受本协议条款，即表明该用户将其在本站发表的任何形式的信息的著作权，包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利无偿独家转让给矩阵资产网运营商所有，同时表明该会员许可矩阵资产网有权利就任何主体侵权而单独提起诉讼，并获得全部赔偿。</p>
                        <p>本协议已经构成《著作权法》第二十五条所规定的书面协议，其效力及于用户在矩阵资产网发布的任何受著作权法保护的作品内容，无论该内容形成于本协议签订前还是本协议签订后。会员同意并明确了解上述条款，不将已发表于本站的信息，以任何形式发布或授权其它网站及媒体（矩阵资产网关联公司网站除外）使用。同时，矩阵资产网保留删除站内各类不符合规定的信息而不通知会员的权利。</p>
                        <p>杭州矩石阵网络科技有限公司是矩阵资产网的运营商,拥有此网站内容及资源的版权,受国家知识产权保护,享有对本网站声明的解释与修改权；未经杭州矩石阵网络科技有限公司的明确书面许可,任何单位或个人不得以任何方式,以任何文字作全部和局部复制、转载、引用。否则本公司将追究其法律责任。</p>
                        <p>矩阵资产网网站页面所有信息受《中华人民共和国著作权法》及相关法律法规和中国加入的所有知识产权方面的国际条约的保护。我们制定了旨在保护知识产权权利人合法权益的相关措施和步骤，当著作权人和/或依法可以行使信息网络传播权的权利人（以下简称“权利人”）发现可能存有侵犯其信息网络传播权的情况，权利人应事先向本站发出书面权利通知，并提供有效权属证明，本站将根据相关法律规定采取措施删除相关内容。同时用户登陆矩阵资产网提交相关评论、文章或其他信息通过矩阵资产网编辑发布的，矩阵资产网享有著作权，矩阵资产网有权在本网站及关联公司网站使用用户在矩阵资产网上发表的信息（包括但不限于图片等），但用户在矩阵资产网上发表的信息若其它浏览者要转载，需经过本网站和该用户的许可，否则视为侵权，其他任何第三人未经矩阵资产网允许，不得以任何形式复制、转载或采用。因用户或其他第三人违反本法律声明而引发的任何一切索赔、损害等等，本网站概不负责。</p>
                        <p>矩阵资产网不保证为向用户提供便利而设置的外部链接的准确性和完整性，若由此而引起的版权问题或其他问题，请致电或电邮本网站。经核实后会立即予以删除或更改。</p>
                    </div>
                    <div className='user-rules'>
                        <li>7、用户隐私制度</li>
                        <div className='must'>
                            <li>尊重用户个人隐私是杭州矩石阵网络科技有限公司的一项基本政策。所以，作为对以上第二点个人注册资料分析的补充，杭州矩石阵网络科技有限公司不会公开、编辑或透露用户的补充资料及保存在矩阵资产网络会员服务中的非公开内容，除非有法律许可要求或杭州矩石阵网络科技有限公司在诚信的基础上认为透露这些信件在以下三种情况是必要的：</li>
                            <p>⑴遵守有关法律规定，遵从矩阵资产网络会员合法服务程序。</p>
                            <p>⑵保持维护矩阵资产的商标所有权。</p>
                            <p>⑶在紧急情况下竭力维护用户个人和社会大众的隐私安全。</p>
                            <p>⑷符合其他相关的要求。</p>
                        </div>
                        <div className='specail'>
                            <p><b>特别授权</b></p>
                            <li><b>您完全理解并不可撤销地、免费地授予</b>矩阵资产<b>及其关联公司、业务合作伙伴下列权利：</b></li>
                            <p><b>(1)矩阵资产关联公司或业务合作伙伴允许矩阵资产用户登录关联公司或业务合作伙伴并使用其服务，矩阵资产用户在关联公司或业务合作伙伴的任何行为均需遵守该等协议的约定、公布的规则以及有关正确使用服务的说明和操作指引。为了实现上述功能，您同意矩阵资产将您在矩阵资产网的注册信息、交易/支付数据等信息和数据同步至关联公司或业务合作伙伴系统并允许其使用</b></p>
                            <p>(2)<b>如您以矩阵资产关联公司或业务合作伙伴用户账号和密码登录</b>矩阵资产，<b>为了实现向您提供同等服务的功能，您同意矩阵资产将您在关联公司或业务合作伙伴账号项下的注册信息、交易/支付数据等信息和数据同步至矩阵资产系统并进行使用，并且您不会因此追究矩阵资产以及矩阵资产关联公司或业务合作伙伴的责任。</b></p>
                            <p><b>(3)为确保交易安全，允许矩阵资产及其关联公司、业务合作伙伴对用户信息进行数据分析，并允许矩阵资产及其关联公司、业务合作伙伴对上述分析结果进行商业利用。</b></p>
                            <p><b>(4)矩阵资产会从关联公司、业务合作伙伴来源获得您的相关信息。例如当您通过矩阵资产关联公司、业务合作伙伴网站预订时，您向其提供的预订信息可能会转交给矩阵资产，以便矩阵资产处理您的订单、确保您顺利预订。又如，矩阵资产允许您用社交媒体账号关联矩阵资产的账号进行登录，在您同意的情况下（您授权给该社交平台）。</b></p>
                            <p><b>(5)您在享受矩阵资产提供的各项服务的同时，授权并同意接受矩阵资产向您的电子邮件、手机、通信地址等发送商业信息，包括不限于最新的矩阵资产产品信息、促销信息等。若您选择不接受矩阵资产提供的各类信息服务，您可以按照矩阵资产提供的相应设置拒绝该类信息服务。</b></p>

                        </div>
                        <div className='save'>
                            <li>8、用户的账号，密码和安全性</li>
                            <p>您一旦注册成功成为用户，您将得到一个密码和账号。如果您不保管好自己的账号和密码安全，将负全部责任。另外，每个用户都要对其账户中的所有活动和事件负全责。您可随时改变您的密码和图标，也可以结束旧的账户重开一个新账户。您若发现任何非法使用用户账号或安全漏洞的情况，立即通告杭州矩石阵网络科技有限公司。<b>请您对密码加以妥善保管，切勿将密码告知他人，因密码保管不善而造成的所有损失由您自行承担。您对通过您的账户所进行的活动、行为和事件依法享有权利和承担责任，且不得以任何形式转让账户、授权他人使用您的账户以及与他人交易账户。鉴于网络服务的特殊性，矩阵资产无义务审核是否是您本人使用该组用户名及密码，仅审核用户名及密码是否与数据库中保存的一致，任何人只要输入的用户名及密码与数据库中保存的一致，即可凭借该组用户名及密码登陆并使用矩阵资产所提供的各类服务，所以即使您认为您的账户登陆行为并非您本人所为，矩阵资产网将不承担因此而产生的任何责任。</b></p>
                        </div>
                        <div className='save'>
                            <li>9、拒绝提供担保</li>
                            <p>用户明确同意邮件服务的使用由用户个人承担风险。杭州矩石阵网络科技有限公司明确表示不提供任何类型的担保，不论是明确的或隐含的，但是不对商业性的隐含担保，特定目的和不违反规定的适当担保作限制。杭州矩石阵网络科技有限公司不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保。杭州矩石阵网络科技有限公司拒绝提供任何担保，包括信息能否准确，及时，顺利的传送。用户理解并接受任何信息资料(下载或通过矩阵资产网络会员服务取得)，取决于用户自己并由其承担系统受损或资料丢失的所有风险和责任。用户不会从杭州矩石阵网络科技有限公司收到口头或书写的意见或信息，也不会在这里作明确担保。</p>
                        </div>
                        <div className='save'>
                            <li>10、有限责任</li>
                            <p>用户明确同意邮件服务的使用由用户个人承担风险。杭州矩石阵网络科技有限公司明确表示不提供任何类型的担保，不论是明确的或隐含的，但是不对商业性的隐含担保，特定目的和不违反规定的适当担保作限制。杭州矩石阵网络科技有限公司不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保。杭州矩石阵网络科技有限公司拒绝提供任何担保，包括信息能否准确，及时，顺利的传送。用户理解并接受任何信息资料(下载或通过矩阵资产网络会员服务取得)，取决于用户自己并由其承担系统受损或资料丢失的所有风险和责任。用户不会从杭州矩石阵网络科技有限公司收到口头或书写的意见或信息，也不会在这里作明确担保。</p>
                        </div>
                        <div className='save'>
                            <li>11、矩阵资产网络会员服务信息的储存及限制</li>
                            <p>杭州矩石阵网络科技有限公司不对用户所发布信息的删除或储存失败负责。杭州矩石阵网络科技有限公司没有对信息的传输量规定上限，但是它有判定用户的行为是否符合矩阵资产网络会员服务条款的要求和精神的保留权利，如果用户违背了服务条款的规定，则中断其网络会员服务的账号。本网站所有的文章版权归原文作者和杭州矩石阵网络科技有限公司共同所有，任何人需要转载网站内文章，必须征得原文作者或杭州矩石阵网络科技有限公司授权。</p>
                        </div>
                        <div className='user-management'>
                            <li>12、用户管理</li>
                            <div className='management-rules'>
                                <li>用户单独承担发布内容的责任。用户对服务的使用是根据所有适用于网站服务的地方法律、国家法律和国际法律标准的。用户必须遵循：</li>
                                <p>⑴发布信息时必须符合中国有关法规。</p>
                                <p>⑵使用网络会员服务不作非法用途。</p>
                                <p>⑶不干扰或混乱网络服务。</p>
                                <p>⑷遵守所有使用网络会员服务的网络协议、规定和程序。</p>
                            </div>
                            <p>网络会员服务是利用因特网发送和收取信息。所以，用户的行为指引是根据国家有关因特网的法规，政策和程序的。用户须承诺不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽等信息资料。另外，用户也不能传输任何教唆他人构成犯罪行为的资料；不能传输助长国内不利条件和涉及国家安全的资料；不能传输任何不符合当地法规、国家法律和国际法律的资料。未经许可而非法进入其它电脑系统是禁止的。若用户的行为不符合以上提到的服务条款，杭州矩石阵网络科技有限公司将作出独立判断立即取消用户服务账号。用户需对自己在网络会员服务中的行为承担法律责任。用户若在网络会员服务中散布和传播反动、色情或其他违反国家法律的信息，网络会员服务的系统记录有可能作为用户违反法律的证据。</p>
                        </div>
                        <div className='save'>
                            <li>13、用户的违约责任</li>
                            <p>矩阵资产网可依据用户数据来认定用户是否构成违约；用户有义务对异常数据进行充分举证和合理解释，否则将被认定为违约。</p>
                            <p>如用户使用矩阵资产网服务时违反有关法律法规规定或本协议约定的，视为用户违约。</p>
                            <div className='deal'>
                                <li>违约处理措施：</li>
                                <p>（1）用户在矩阵资产网平台上发布的信息构成违反本协议或涉嫌违法或涉嫌侵犯第三方合法权利的，矩阵资产网<b>可根据相应规则立即采取措施对相应信息进行删除。</b></p>
                                <p>2）用户在矩阵资产网平台上实施的行为，矩阵资产网<b>可依据相应规则对用户执行账户扣减积分、限制参加营销活动、中止向用户提供部分或全部服务、取消订单、划扣违约金等处理措施。如用户的行为构成根本违约的，矩阵资产网可冻结用户的账户，终止向用户提供服务。</b></p>
                            </div>
                            <div className='pay'>
                                <li>赔偿责任：</li>
                                <p><b>如用户的行为使矩阵资产网及/或其关联公司遭受损失（包括直接经济损失和间接经济损失），或受到任何第三方的索赔，或受到有关行政部门的处罚，用户应赔偿矩阵资产网及/或其关联公司的全部损失，包括但不限于律师费、诉讼费等。</b></p>
                            </div>
                        </div>
                        <div className='save'>
                            <li>14、保障</li>
                            <p>用户同意保障和维护杭州矩石阵网络科技有限公司全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、账号和其它知识产权的追索费。</p>
                        </div>
                        <div className='save'>
                            <li>15、结束服务</li>
                            <div className='over'>
                                <li>用户或杭州矩石阵网络科技有限公司可随时根据实际情况中止网站服务。杭州矩石阵网络科技有限公司不需对任何个人或第三方负责而随时中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对矩阵资产网络会员服务不满，用户只有以下的追索权：</li>
                                <p>⑴ 不再使用矩阵资产网络会员服务。</p>
                                <p>⑵ 结束用户使用矩阵资产网络会员服务的资格。</p>
                                <p>⑶ 通告杭州矩石阵网络科技有限公司停止该用户的服务。结束用户服务后，用户使用矩阵资产网络会员服务的权利马上中止。从那时起，杭州矩石阵网络科技有限公司不再对用户承担任何义务。</p>
                            </div>
                        </div>
                        <div className='save'>
                            <li>16、通告</li>
                            <p>所有发给用户的通告都可通过电子邮件或常规的信件传送。杭州矩石阵网络科技有限公司会通过邮件服务发报消息给用户，告诉他们服务条款的修改、服务变更、或其它重要事情。</p>
                        </div>
                        <div className='save'>
                            <li>17、参与广告策划</li>
                            <p>用户可在他们发表的信息中加入宣传资料或参与广告策划，在矩阵资产网络会员服务免费服务上展示他们的产品。任何这类促销方法，包括运输货物、付款、服务、商业条件、担保及与广告有关的描述都只是在相应的用户和广告销售商之间发生。杭州矩石阵网络科技有限公司不承担任何责任，矩阵资产网络会员服务没有义务为这类广告销售负任何一部分的责任。</p>
                        </div>
                        <div className='save'>
                            <li>18、邮件内容的所有权</li>
                            <p>用户定义的内容包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；电子邮件的全部内容；矩阵资产网络会员服务为用户提供的商业信息。所有这些内容均受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在杭州矩石阵网络科技有限公司和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</p>
                        </div>
                        <div className='save'>
                            <li>19、法律</li>
                            <p><b>本服务协议要与该国的国家法律解析一致，包括法律条款中有争议抵触的内容。用户和杭州矩石阵网络科技有限公司一致同意服从法院的管辖。若有任何条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响，因用户解释本服务协议或通过矩阵资产网预订任何产品而导致的争议，将同意接受杭州市萧山区人民法院的管辖。</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}