// 校验规则：不得小于0
const inputMinValid = (rule, val) => {
  return isNaN(val) || val < 0
    ? Promise.reject("该值不得小于0")
    : Promise.resolve();
};

// 校验规则：不得小于0
const inputIntValid = (rule, val) => {
  return !isNaN(val) && !Number.isInteger(Number(val))
    ? Promise.reject("该项必须为整数")
    : Promise.resolve();
};
// 校验规则：折扣率    0-10
const inputZkValid = (rule, val) => {
  return !isNaN(val) && (val <= 0 || val >= 10)
    ? Promise.reject("该项必须大于0小于10")
    : Promise.resolve();
};
// 校验规则：收益率    0-100
const inputSYValid = (rule, val) => {
  return !isNaN(val) && (val <= 0 || val >= 100)
    ? Promise.reject("该项必须大于0小于100")
    : Promise.resolve();
};


export { inputMinValid, inputIntValid ,inputZkValid,inputSYValid};
