import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
import transPrice from "../../../func/transPrice";
import ajax1 from "../../../func/ajax";
import { sourceSelect, codeSelect } from "../../../static/option";
export default () => {
  const [objectList, setObjectList] = useState([]);
  const [failObjectList, setFailObjectList] = useState([]);
  useEffect(() => {
    ajax1({
      path: "/customer/Index/ObjcetList",
      isNeedToken: true,
      ableEmpty: true,
      data: { page: 1, size: 8, zc_type: "", order_type: "" },
    }).then((res) => {
      if (res.list.length > 0) {
        res.list.map((item) => {
          sourceSelect.map((sourceItem) => {
            if (sourceItem.label === item.source) {
              item.sourceIcon = sourceItem.img_path;
            }
          });
          return item;
        });
      }
      setObjectList(res.list);
    });
    ajax1({
      path: "/customer/Index/ObjcetListFailure",
      isNeedToken: true,
      ableEmpty: true,
      data: { page: 1, size: 8 },
    }).then((res) => {
      if (res.list.length > 0) {
        res.list.map((item) => {
          sourceSelect.map((sourceItem) => {
            if (sourceItem.label === item.source) {
              item.sourceIcon = sourceItem.img_path;
            }
          });
          return item;
        });
      }
      setFailObjectList(res.list);
    });
  }, []);
  return (
    <div className={"indexEntrust"}>
      <div className={"indexEntrust_Title"}>
        <div className={"content container-1200"}>
          <span className={"type"}>最新上架</span>
          <div className={"more"}>
            <Link className="section-title-r" to={"/search"}>
              更多标的物&nbsp;
              <RightOutlined />
            </Link>
          </div>
        </div>
      </div>
      <ul className={"indexEntrust_ul container-1200"}>
        {objectList.map((item, index) => {
          return (
            <li key={index} className={"indexEntrust_li"}>
              <span
                className={
                  item.zc_type === 1
                    ? "tagName"
                    : item.zc_type === 2
                    ? "tagName blue"
                    : "tagName red"
                }
              >
                {item.zctype_name}
              </span>

              <div className={"cover-img"}>
                <div
                  className={"cover-img-content"}
                  style={{ backgroundImage: `url(${item.img_path})` }}
                />
                <div className={"title"}>{item.object_title}</div>
              </div>
              <ul className={"infos"}>
                <li>
                  <div className={"des"}>
                    <span>起</span>
                    <span>拍</span>
                    <span>价：</span>
                  </div>
                  <div className={"start_price"}>
                    ¥<span>{transPrice(item.initial_price, "zh_cn")}</span>
                  </div>
                </li>
                <li>
                  <div className={"des"}>
                    <span>评</span>
                    <span>估</span>
                    <span>价：</span>
                  </div>
                  <div className={"assess_price"}>
                    ¥{transPrice(item.assess_price, "zh_cn")}
                  </div>
                </li>
                <li>
                  <div className={"des"}>
                    <span>开</span>
                    <span>拍</span>
                    <span>时</span>
                    <span>间：</span>
                  </div>
                  <div className={"start_time"}>
                    <span>{item.start_time.substring(5)}</span>
                    <span className={"auction_status"}>
                      {item.object_status}
                    </span>
                  </div>
                </li>
              </ul>
              <div className={"numbers"}>
                <img src={item.sourceIcon} alt="" />
                <div>
                  <span>围观 </span>
                  <b> {item.own_looker_num}</b>
                  <span> | </span>
                  <span>咨询 </span>
                  <b>{item.consult_num}</b>
                </div>
              </div>
              <div className={"coverCode"}>
                <div className={"coverCode-content"}>
                  <div>
                    <img src={codeSelect[0]} alt="" />
                    <span>ios下载</span>
                  </div>
                  <div>
                    <img src={codeSelect[1]} alt="" />
                    <span>Android下载</span>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <div className={"indexEntrust_Title"}>
        <div className={"content container-1200"}>
          <span className={"type"}>流拍寻宝</span>
          <div className={"more"}>
            <Link className="section-title-r" to={"/search?currentIndex=2"}>
              更多标的物&nbsp;
              <RightOutlined />
            </Link>
          </div>
        </div>
      </div>
      <ul className={"indexEntrust_ul container-1200"}>
        {failObjectList.map((item, index) => {
          return (
            <li key={index} className={"indexEntrust_li"}>
              <span
                className={
                  item.zc_type === 1
                    ? "tagName"
                    : item.zc_type === 2
                    ? "tagName blue"
                    : "tagName red"
                }
              >
                {item.zctype_name}
              </span>

              <div className={"cover-img"}>
                <div
                  className={"cover-img-content"}
                  style={{ backgroundImage: `url(${item.img_path})` }}
                />
                <div className={"title"}>{item.object_title}</div>
              </div>
              <ul className={"infos"}>
                <li>
                  <div className={"des"}>
                    <span>当</span>
                    <span>前</span>
                    <span>价：</span>
                  </div>
                  <div className={"start_price"}>
                    ¥<span>{transPrice(item.initial_price, "zh_cn")}</span>
                  </div>
                </li>
                <li>
                  <div className={"des"}>
                    <span>评</span>
                    <span>估</span>
                    <span>价：</span>
                  </div>
                  <div className={"assess_price"}>
                    ¥{transPrice(item.assess_price, "zh_cn")}
                  </div>
                </li>
                <li>
                  <div className={"des"}>
                    <span>上</span>
                    <span>架</span>
                    <span>时</span>
                    <span>间：</span>
                  </div>
                  <div className={"start_time"}>
                    <span>{item.create_time.substring(5)}</span>
                    <span className={"auction_status"}>
                      {item.disposal_status === 1
                        ? "待确认"
                        : item.disposal_status === 2
                        ? "可处置"
                        : "不可处置"}
                    </span>
                  </div>
                </li>
              </ul>
              <div className={"numbers"}>
                <img src={item.sourceIcon} alt="" />
                <div>
                  <span>围观 </span>
                  <b> {item.own_looker_num}</b>
                  <span> | </span>
                  <span>咨询 </span>
                  <b>{item.consult_num}</b>
                </div>
              </div>
              <div className={"coverCode"}>
                <div className={"coverCode-content"}>
                  <div>
                    <img src={codeSelect[0]} alt="" />
                    <span>ios下载</span>
                  </div>
                  <div>
                    <img src={codeSelect[1]} alt="" />
                    <span>Android下载</span>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
