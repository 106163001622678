import React, { Fragment, useEffect, useState, memo } from "react";

import PropTypes from "prop-types";
import { message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ossSTS from "../../func/ossSTS.js";

function ImgUploader(props) {
  const { defaultFileList = [], multiple, maxNumber } = props;
  const [fileList, setFileList] = useState([]);
  const [currentFileNumber, setCurrentFileNumber] = useState(0);

  const onChange = ({ fileList }) => {
    if (maxNumber && fileList.length > maxNumber) {
      fileList.splice(maxNumber);
      message.warning({ content: "超过可上传最大数量", key: "uploadMaxNum" });
    }
    setCurrentFileNumber(fileList.length);
    setFileList(
      fileList.map((item) => {
        item["url"] = item["url_complete"] =
          item.url.indexOf("http") > -1
            ? item.url
            : `${ossSTS.host}/${item.url}`;
        return item;
      })
    );
    const { onChange } = props;
    if (onChange) {
      onChange(
        fileList.map((item) => {
          return (item["url"] = item["url_complete"] =
            item.url.indexOf("http") > -1
              ? item.url
              : `${ossSTS.host}/${item.url}`);
        })
      );
    }
  };

  const getExtraData = (file) => {
    return {
      key: file.url,
      OSSAccessKeyId: ossSTS.accessId,
      policy: ossSTS.policy,
      Signature: ossSTS.signature,
    };
  };

  // const transformFile = (file) => {
  //   const filename = `${Date.now()}_${file.name}`;
  //   file.url = ossSTS.dir + filename;
  //   return file;
  // };
  const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
    || file.type === 'image/gif'|| file.type === 'image/bmp'|| file.type === 'image/webp'|| file.type === 'image/psd'
    || file.type === 'image/svg' || file.type === 'image/tiff' ;
    if (!isJpgOrPng) {
      message.error(`${file.name} 不是图片文件，请重新选择图片`);
      return Upload.LIST_IGNORE;
    }
    const filename = `${Date.now()}_${file.name}`;
    file.url = ossSTS.dir + filename;
    return file;
  };

  // const beforeUpload = (file)=>{
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
  //   || file.type === 'image/gif'|| file.type === 'image/bmp'|| file.type === 'image/webp'|| file.type === 'image/psd'
  //   || file.type === 'image/svg' || file.type === 'image/tiff' ;
  //   if (!isJpgOrPng) {
  //     message.error(`${file.name} 不是图片文件，请重新选择图片`);

  //   }
  //   return isJpgOrPng ? true : Upload.LIST_IGNORE;
  // }




  const uploaderConfig = {
    name: "file",
    defaultFileList,
    action: ossSTS.host,
    // transformFile,
    data: getExtraData,
    listType: "picture-card",
    className: "img-uploader__list",
    onChange,
    multiple,
    fileList,
    beforeUpload
  };

  useEffect(() => {
    setCurrentFileNumber(defaultFileList.length);
    setFileList(defaultFileList);
  }, [defaultFileList]);

  return (
    <Upload {...uploaderConfig}>
      {/*{console.log(maxNumber === 0 || maxNumber > currentFileNumber)}*/}
      {(maxNumber === 0 || maxNumber > currentFileNumber) && (
        <Fragment>
          <UploadOutlined /> 上传图片
        </Fragment>
      )}
    </Upload>
  );
}

ImgUploader.prototype = {
  defaultFileList: PropTypes.array,
  multiple: PropTypes.bool,
  maxNumber: PropTypes.number,
};

ImgUploader.defaultProps = {
  defaultFileList: [],
  multiple: false,
  maxNumber: 0,
};

export default memo(ImgUploader);
