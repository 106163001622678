import "./style.scss";

import { Avatar, Dropdown, Layout, Menu } from "antd";
import {
  NavLink,
  Redirect,
  Link,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import React, { useEffect, useRef, useState, useMemo } from "react";
import ScrollToTop from "../../../component/ScrollToTop";
import navMap from "./navMap";
import routeMap from "./routeMap";

const { Content } = Layout;

function BlankLayout(props) {
  const { location } = props;
  // console.log(location.pathname.split("/")[2]);
  const [collapse, setCollapse] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const layout = useRef(null);

  const collapseChange = () => {
    setCollapse(!collapse);
  };
  useEffect(
    () => {
      try {
        setContentHeight(layout.current.offsetHeight);
      } catch (e) {}
      return () => {};
    },
    [] // eslint-disable-next-line
  );

  const RouteRender = (currentRoute, prevPath = "") => {
    return currentRoute.map(({ children = null, path, component }) => {
      const currentPath = `${prevPath}/${path}`;
      if (children) {
        return (
          <Route path={currentPath} key={currentPath}>
            <Switch>{RouteRender(children, currentPath)}</Switch>
          </Route>
        );
      } else {
        return path && path !== "**" ? (
          <Route key={path} path={currentPath} component={component} />
        ) : (
          <Route key={`${currentPath}/redirect`} component={component} />
        );
      }
    });
  };

  return (
    <Layout className={"creditorUser_basic-layout container-1200"}>
      <div className={"uLeft"}>
        <div className={"creditorUser-uLeft-header"}>商户中心</div>
        <ul className={"uNav"}>
          {navMap.map((item, index) => {
            return (
              <li key={item.name} className={"uNavItem"}>
                <div className={"uNavItemMain"}>{item.name}</div>
                <ul>
                  {item.children.map((childItem, childIndex) => {
                    return (
                      <li
                        className={
                          childItem.childrenPath?.indexOf(location.pathname) >
                          -1
                            ? "active"
                            : childItem.path === location.pathname
                            ? "active"
                            : ""
                        }
                        key={childItem.name}
                      >
                        <Link to={childItem.path}>{childItem.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className={"uRight"}
        style={{ minHeight: contentHeight }}
        ref={layout}
      >
        <div>
          <Content className={"basic-layout__content"}>
            <ScrollToTop>
              <Switch>
                {RouteRender(routeMap)}
                <Redirect exact to={"/creditorUser/account/index"} />
              </Switch>
            </ScrollToTop>
          </Content>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(BlankLayout);
