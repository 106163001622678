import React, { useEffect, useState } from "react";

import "./style.scss";
import { Link } from "react-router-dom";
import { Button, Empty, message, Popconfirm } from "antd";
import { RightOutlined } from "@ant-design/icons";
import ajax from "../../../../func/ajax";
import queryToData from "../../../../func/queryToData";
import ckeditorConfig from "../../../../static/ckeditorConfig";
import CKEditor from "ckeditor4-react";
import { valueToLabel } from "../../../../func/fn";
import {
  claimStatus,
  is_start_select,
  is_start_select_zctj,
  zy_type,
} from "../../../../static/option";

export default () => {
  const [objDetail, setObjDetail] = useState({});
  const [action, setAction] = useState(null);
  const [name, setName] = useState(queryToData().name || "");
  //获取标的详情
  const getObjectDetail = () => {
    ajax({
      path: "/creditor/v1.Zc_object/getObjectDetails",
      data: { id: queryToData().id, type: queryToData().type },
    }).then((res) => {

      if (res.status === 200) {
        let is_start_select_list = res.data.zc_type === 3 ? is_start_select_zctj : is_start_select

        res.data.is_start_text = valueToLabel({
          optionArray: is_start_select_list,
          value: res.data.is_start,
        });
        res.data.status_text = valueToLabel({
          optionArray: claimStatus,
          value: res.data.relation_status,
        });
        res.data.zc_type_name = valueToLabel({
          optionArray: zy_type,
          value: res.data.zc_type,
        });
        is_start_select_list.map((item) => {
          if (res.data.is_start === item.value) {
            res.data.is_start_icon = item.statusIcon;
          }
        });
        claimStatus.map((item) => {
          if (res.data.relation_status === item.value) {
            res.data.status_icon = item.statusIcon;
          }
        });
        setObjDetail(res.data);
      }
    });
  };
  //上下架操作
  const handleShelf = (item, operation) => {
    ajax({
      path: "/creditor/v1.Zc_object/getObjectShelves",
      data: { zc_type: item.zc_type, object_id: item.id, operation },
    }).then((res) => {
      if (res.status === 200) {
        message.success("操作成功");
        getObjectDetail();
      }
    });
  };

  useEffect(() => {
    if (queryToData().action) {
      setAction(queryToData().action);
    }
    getObjectDetail();
    return () => {
      if (action === "claim") {
        window.location.href = `/creditorUser/subject/claim?name=${name}`;
      }
    };
  }, []);

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          {queryToData().action === "claim" ? (
            <>
              <li>
                <Link to={"/creditorUser/subject/subjectList"}>
                  <span>标的物管理</span>
                </Link>
                <Link to={"/creditorUser/subject/onShelf"}>
                  <RightOutlined className={"iconArrow"} />
                  <span>标的物上架</span>
                </Link>
                <Link
                  to={`/creditorUser/subject/claim?name=${queryToData().name}`}
                >
                  <RightOutlined className={"iconArrow"} />
                  <span>司法拍卖标的物认领</span>
                </Link>
                <RightOutlined className={"iconArrow"} />
                <span>标的物详情页</span>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to={"/creditorUser/subject/subjectList"}>
                  <span>标的物管理</span>
                </Link>
                <Link to={"/creditorUser/subject/subjectList"}>
                  <RightOutlined className={"iconArrow"} />
                  <span>标的物列表</span>
                </Link>
                <RightOutlined className={"iconArrow"} />
                <span>标的物详情页</span>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className={"subjectDetail_container"}>
        {queryToData().action === "claim" || (
          <div className={"subjectDetail_header"}>
            <div>标的编号：{objDetail?.id}</div>
            {objDetail.zc_type === 1 ? (
              <div>
                <img src={objDetail?.status_icon} alt="" />
                {objDetail.status_text}
              </div>
            ) : (
              <div>
                <img src={objDetail?.is_start_icon} alt="" />
                {objDetail.is_start_text}
              </div>
            )}
          </div>
        )}
        <div className={"subjectContent_container"}>
          <div className={"main_content"}>
            <div className={"main_content_title"}>基本信息</div>
            <ul className={"baseInfos main_content_item"}>
              <li>
                标的编号：
                <span>{objDetail?.id}</span>
              </li>
              <li>
                标的名称：
                <span>{objDetail?.object_title}</span>
              </li>
              <li>
                所属区域：
                <span>
                  {objDetail?.province_name}
                  {objDetail?.city_name}
                  {objDetail?.district_name}
                </span>
              </li>
              <li>
                资产类型：
                <span>{objDetail?.zc_type_name}</span>
              </li>
              <li>
                标的类型：
                <span>{objDetail?.second_name}</span>
              </li>
              <li>
                处置机构：
                <span>
                  {objDetail?.zc_type === 1
                    ? objDetail?.court_name
                    : objDetail?.merchants_name}
                </span>
              </li>
              <li>
                评估价：
                <span>{objDetail?.assess_price}</span>
              </li>
              {objDetail?.zc_type === 3 ? (
                <>
                  <li>
                    折扣：
                    <span>{objDetail?.discount_rate}折</span>
                  </li>
                  <li>
                    预期投资回报率:
                    <span>{objDetail?.expected_investment}%</span>
                  </li>
                  <li>
                    委托方:
                    <span>{objDetail?.client_name}</span>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    处置平台：
                    <span>{objDetail?.source}</span>
                  </li>
                  <li>
                    起拍价：
                    <span>{objDetail?.initial_price}</span>
                  </li>
                  <li>
                    开拍时间：
                    <span>{objDetail?.start_time}</span>
                  </li>
                  <li>
                    保证金：
                    <span>{objDetail?.security_deposit}</span>
                  </li>
                  <li>
                    加价幅度：
                    <span>{objDetail?.bid_increment}</span>
                  </li>
                  <li>
                    竞价周期：
                    <span>{objDetail?.bid_cycle}小时</span>
                  </li>
                  <li>
                    创建时间：
                    <span>{objDetail?.create_time}</span>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className={"main_content"}>
            <div className={"main_content_title"}>标的物图片</div>
            <div className={"main_content_item subjectImages"}>
              {objDetail.items1?.map((item, index) => {
                return (
                  <div key={index}>
                    <img src={item.img_path} alt="" />
                  </div>
                );
              })}
            </div>
            {objDetail.items1?.length === 0 && <Empty />}
          </div>
          <div className={"main_content "}>
            <div className={"main_content_title"}>公告须知</div>
            <ul className={"main_content_item announcement"}>
              <li>
                <span className={"announcement_left"}>竞买公告：</span>

                <div className={"read_only"}>
                  {objDetail.additional ? (
                    <CKEditor
                      key={1}
                      config={ckeditorConfig}
                      data={objDetail.additional?.announcement}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                    />
                  ) : (
                    <CKEditor
                      key={2}
                      config={ckeditorConfig}
                      data={""}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                    />
                  )}
                </div>
              </li>
              {objDetail?.zc_type === 3 || (
                <li>
                  <span className={"announcement_left"}>竞买须知：</span>
                  <div className={"read_only"}>
                    {objDetail.additional ? (
                      <CKEditor
                        key={1}
                        config={ckeditorConfig}
                        data={objDetail.additional?.should_know}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                      />
                    ) : (
                      <CKEditor
                        key={2}
                        config={ckeditorConfig}
                        data={""}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                      />
                    )}
                  </div>
                </li>
              )}
              <li>
                <span className={"announcement_left"}>标的物介绍：</span>
                <div className={"read_only"}>
                  {objDetail.additional ? (
                    <CKEditor
                      key={1}
                      config={ckeditorConfig}
                      data={objDetail.additional?.introduce}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                    />
                  ) : (
                    <CKEditor
                      key={2}
                      config={ckeditorConfig}
                      data={""}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                    />
                  )}
                </div>
              </li>
            </ul>
            {/*司法认领或其他操作*/}
            {action === "claim" ? (
              <div className={"handle_claim"}>
                <div className={"handle_btn"}>
                  {objDetail.config_second === 0 ? (
                    <>
                      {objDetail.relation_status === 3 ? (
                        <Button
                          style={{ marginBottom: "30px" }}
                          className={"enAble_claim"}
                          type={"primary"}
                        >
                          已认领
                        </Button>
                      ) : (
                        <Button className={"enAble_claim"} type={"primary"}>
                          无法认领
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button type={"primary"}>
                      <Link
                        to={`/creditorUser/subject/claimSubmit?id=${objDetail?.id}`}
                      >
                        认领
                      </Link>
                    </Button>
                  )}
                </div>
                {objDetail?.config_second === 0 &&
                  objDetail.relation_status !== 3 && (
                    <div className={"enAble_claim_reason"}>
                      {objDetail.config_second_name},如有疑问致电
                      <Button type={"link"}>400-157-1060</Button>
                    </div>
                  )}
              </div>
            ) : (
              <div className={"handle_btn"}>
                {/*上下架操作*/}
                {objDetail?.zc_type === 1 || (
                  <>
                    {objDetail.is_start === 1 && (
                      <>
                        <Link
                          to={`/creditorUser/subject/onShelfDetail?id=${objDetail.id}&type=${objDetail.zc_type}`}
                        >
                          编辑
                        </Link>
                        <Popconfirm
                          title="确定执行此操作?"
                          okText="确定"
                          cancelText="取消"
                          onConfirm={() => handleShelf(objDetail, 1)}
                        >
                          <Button type={"primary"}>上架</Button>
                        </Popconfirm>
                      </>
                    )}
                    {objDetail.is_start === 4 && (
                      <Popconfirm
                        title="确定执行此操作?"
                        okText="确定"
                        cancelText="取消"
                        onConfirm={() => handleShelf(objDetail, 2)}
                      >
                        <Button className={"off_shelf"}>下架</Button>
                      </Popconfirm>
                    )}
                    {objDetail.is_start === 2 && (
                      <Button className={"off_shelf"}>审核中</Button>
                    )}
                    {objDetail.is_start === 3 && (
                      <>
                        <Link
                          to={`/creditorUser/subject/onShelfDetail?id=${objDetail.id}&type=${objDetail.zc_type}`}
                        >
                          编辑
                        </Link>
                      </>
                    )}
                  </>
                )}
                {/*推广操作*/}
                {objDetail?.zc_type !== 3 && (
                  <>
                    {objDetail.extend_id === 1 ? (
                      <Button className={"look_promote_detail"}>
                        <Link
                          to={`/creditorUser/subject/subjectPromote?id=${objDetail.id}&type=${objDetail.zc_type}`}
                        >
                          推广详情查看
                        </Link>
                      </Button>
                    ) : (
                      <>
                        {objDetail.zc_type === 1 &&
                          objDetail.relation_status === 3 && (
                            <Button type={"primary"}>
                              <Link
                                to={`/creditorUser/subject/subjectPromoteIndex?id=${objDetail.id}&type=${objDetail.zc_type}`}
                              >
                                推广
                              </Link>
                            </Button>
                          )}
                        {objDetail.zc_type === 2 &&
                          objDetail.is_start === 4 && (
                            <Button type={"primary"}>
                              <Link
                                to={`/creditorUser/subject/subjectPromoteIndex?id=${objDetail.id}&type=${objDetail.zc_type}`}
                              >
                                推广
                              </Link>
                            </Button>
                          )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
