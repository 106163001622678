import React from 'react';
import './index.scss';

export default function PrivacyPolicy() {

    return (
        <div className='privacy-policy'>
            <h1 className='privacy-policy-title'>《隐私权政策》</h1>
            <div className='privacy-policy-introduction'>
                矩阵资产平台是由杭州矩石阵网络科技有限公司研发，矩阵资产平台尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，矩阵资产平台会按照本隐私权政策的规定使用和披露您的个人信息。但矩阵资产平台将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，矩阵资产平台不会将这些信息对外披露或向第三方提供。矩阵资产平台会不时更新本隐私权政策。
            </div>
            <br />
            <div className='privacy-policy-agree'>
                您在同意矩阵资产平台服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于矩阵资产平台服务使用协议不可分割的一部分。
            </div>
            <br />
            <div className='privacy-policy-content'>
                <div className='area'>
                    <li>1、使用范围</li>
                    <p style={{ marginTop: '10px' }}>a)在您注册矩阵资产平台帐号时，您根据矩阵资产平台要求提供的个人注册信息； </p>
                    <p>b)在您使用矩阵资产平台网络服务，或访问矩阵资产平台平台网页时，矩阵资产平台自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</p>
                    <p>c)矩阵资产平台通过合法途径从商业伙伴处取得的用户个人数据。您了解并同意，以下信息不适用本隐私权政策:</p>
                    <div className='disapear'>
                        <p>a)您在使用矩阵资产平台提供的搜索服务时输入的关键字信息；</p>
                        <p>b)矩阵资产平台收集到的您在矩阵资产平台发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</p>
                        <p>c)违反法律规定或违反矩阵资产平台规则行为及矩阵资产平台已对您采取的措施。</p>
                    </div>
                </div>
                <div className='use'>
                    <li>2、信息使用</li>
                    <p style={{ marginTop: '10px' }}>a)矩阵资产平台不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和矩阵资产平台（含矩阵资产平台关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</p>
                    <p>b)矩阵资产平台亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何矩阵资产平台用户如从事上述活动，一经发现，矩阵资产平台有权立即终止与该用户的服务协议。</p>
                    <p>c)为服务用户的目的，矩阵资产平台可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与矩阵资产平台合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</p>
                </div>
                <div className='info-disclosure'>
                    <li>3.信息披露 </li>
                    <p>在如下情况下，矩阵资产平台将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息： </p>
                    <p style={{ marginTop: '10px' }}>a)经您事先同意，向第三方披露； </p>
                    <p>b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息； </p>
                    <p>c)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
                    <p>d)如您出现违反中国有关法律、法规或者矩阵资产平台服务协议或相关规则的情况，需要向第三方披露；</p>
                    <p>e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</p>
                    <p>f)在矩阵资产平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，矩阵资产平台有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</p>
                    <p>g) 其它矩阵资产平台根据法律、法规或者网站政策认为合适的披露。  </p>
                </div>
                <div className='store'>
                    <li>4.信息存储和交换  </li>
                    <p style={{ marginTop: '10px' }}>矩阵资产平台收集的有关您的信息和资料将保存在矩阵资产平台及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或矩阵资产平台收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>
                </div>
                <div className='cookie'>
                    <li>5.Cookie的使用  </li>
                    <p style={{ marginTop: '10px' }}>a)在您未拒绝接受cookies的情况下，矩阵资产平台会在您的计算机上设定或取用cookies ，以便您能登录或使用依赖于cookies的矩阵资产平台服务或功能。矩阵资产平台使用cookies可为您提供更加周到的个性化服务，包括推广服务。  </p>
                    <p>b)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的矩阵资产平台网络服务或功能。</p>
                    <p>c)通过矩阵资产平台所设cookies所取得的有关信息，将适用本政策。 </p>
                </div>
                <div className='save'>
                    <li>6.信息安全  </li>
                    <p style={{ marginTop: '10px' }}>a)矩阵资产平台帐号均有安全保护功能，请妥善保管您的用户名及密码信息。矩阵资产平台将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”</p>
                    <p>b)在使用矩阵资产平台网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是矩阵资产平台用户名及密码发生泄露，请您立即联络矩阵资产平台客服，以便矩阵资产平台采取相应措施。</p>
                </div>
            </div>
        </div>
    )
}