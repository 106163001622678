const editorConfig = {
  toolbar: [
    { name: "clipboard", items: ["Undo", "Redo"] },
    { name: "styles", items: ["Format", "Font", "FontSize"] },
    { name: "colors", items: ["TextColor", "BGColor"] },
    {
      name: "basicstyles",
      items: [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "Subscript",
        "Superscript",
        "CopyFormatting",
        "RemoveFormat"
      ]
    },
    {
      name: "paragraph",
      items: [
        "NumberedList",
        "BulletedList",
        "Outdent",
        "Indent",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
        "BidiLtr",
        "BidiRtl"
      ]
    },
    { name: "insert", items: ["Table", "HorizontalRule", "Image"] },
    { name: "document", items: ["Source", "Print"] },
    { name: "tools", items: ["Maximize"] }
  ],
  image_previewText: "请先上传图片",
  filebrowserImageUploadUrl:
    "https://api.laipaifafu.com/index.php/Api/public/uploadFile",
  font_names:
    "宋体/宋体;黑体/黑体;仿宋/仿宋_GB2312;楷体/楷体_GB2312;隶书/隶书;微软雅黑/微软雅黑;sans-serif;Arial;Comic Sans MS;Courier New;Tahoma;Times New Roman;Verdana",
  fontSize_defaultLabel: "三号",
  fontSize_sizes:
    "八号/5pt;七号/5.5pt;小六/6.5pt;六号/7.5pt;小五/9pt;五号/10.5pt;小四/12pt;四号/14pt;小三/15pt;三号/16pt;小二/18pt;二号/22pt;小一/24pt;一号/26pt;小初/36pt;初号/42pt"
};

export default editorConfig;
