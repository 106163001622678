import * as actionTypes from "./actionTypes";
import ajax from "../func/ajax";

// 获取二级分类
const _getSecondClass = (data) => ({
  type: actionTypes.GET_SECOND_CLASS,
  data,
});

export const getSecondClass = () => {
  return (dispatch) => {
    ajax({ path: "/customer/Index/getGrabtype" }).then((res) => {
      dispatch(_getSecondClass(res));
    });
  };
};
