import React, { useState, useEffect } from "react";

import "./style.scss";

import { Button, Empty, notification, Pagination } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ajax from "../../../../../func/ajax";
import { valueToLabel } from "../../../../../func/fn";
import { withdrawStatus } from "../../../../../static/option";

export default () => {
  const [balance, setBalance] = useState(0);
  const [count, setCount] = useState(0);
  const [cashData, setCashData] = useState(null);
  const [page, setPage] = useState(1);
  const size = 5;

  //提现记录
  const getCashlist = (newPagination) => {
    if (newPagination) {
      setPage(newPagination);
    }
    ajax({
      path: "/creditor/v1/cashlist",
      data: { page: newPagination, size },
      method: "get",
    }).then((res) => {
      if (res.data.data) {
        res.data.data.map((item) => {
          item.withdrawStatus_text = valueToLabel({
            optionArray: withdrawStatus,
            value: item.status,
          });
          item.withdrawStatus_textColor =
            item.status === 1
              ? "#1890FF"
              : item.status === 2
              ? "#6ECD3F"
              : item.status === 3
              ? "#B52E25"
              : "";
          return item;
        });
        setCashData(res.data.data);
        setCount(res.data.total);
      }
    });
  };

  //账户余额
  const getBalance = () => {
    ajax({
      path: "/creditor/v1/balance",
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        setBalance(res.balance);
      }
    });
  };
  //审核拒绝原因
  const openNotification = (e) => {
    const args = {
      message: "审核拒绝原因",
      description: e,
      duration: 2,
    };
    notification.open(args);
  };

  useEffect(() => {
    getCashlist(1);
    getBalance();
  }, []);
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>财务管理</span>
            <RightOutlined className={"iconArrow"} />
            <span>账户管理</span>
          </li>
        </ul>
      </div>
      <div className={"financeAccount_container"}>
        <div className={"overage"}>
          <div className={"overage_detail"}>
            <div className={"overage_price"}>
              <span>余额:</span>¥{balance}
            </div>
            <Button type={"primary"}>
              <Link
                to={`/creditorUser/finance/financeWithDraw?price=${balance}`}
              >
                提现
              </Link>
            </Button>
          </div>
        </div>
        <div className={"financeAccount_title"}>提现记录</div>
        <ul className={"finance_detail"}>
          {cashData?.map((item, index) => {
            return (
              <li key={item.id} className={"finance_detail_item"}>
                <div className={"finance_detail_item_title"}>
                  <span className={"gray f12"}>流水号：</span>
                  {item.order_num}
                </div>
                <ul className={"finance_detail_item_infos"}>
                  <li>
                    <span>类型：</span>
                    提现
                  </li>
                  <li>
                    <span>金额：</span>
                    {item.price}
                  </li>
                  <li>
                    <span>银行账户：</span>
                    {item.bank_account_num}
                  </li>
                  <li>
                    <span>银行机构名称：</span>
                    {item.bank_name}
                  </li>
                  <li>
                    <span>开户名：</span>
                    {item.bank_account_name}
                  </li>
                  <li>
                    <span> 支付方式：</span>
                    余额
                  </li>
                  <li>
                    <span>申请时间：</span>
                    {item.createtime}
                  </li>
                  <li>
                    <span>状态：</span>
                    <b style={{ color: item.withdrawStatus_textColor }}>
                      {item.withdrawStatus_text}
                    </b>
                  </li>
                </ul>

                {item.status === 3 && (
                  <div className={"textRight"}>
                    <Button
                      className={"btn-danger-border"}
                      onClick={() => openNotification(item.remark)}
                    >
                      查看原因
                    </Button>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
        {count !== 0 && (
          <Pagination
            style={{ textAlign: "right" }}
            total={count}
            pageSize={size}
            onChange={(e) => getCashlist(e)}
            showQuickJumper
            showTotal={(total) => `共 ${total} 条`}
          />
        )}
        {count === 0 && <Empty />}
      </div>
    </div>
  );
};
