import moment from "moment";

const dataFillForm = ({ form, data, options = {} }) => {
  const {
    startTimeKey = "start_time",
    endTimeKey = "end_time",
    timeRangeKey = "",
    registered_date = "",
  } = options;
  if (data) {
    const keysArray = Object.keys(form.getFieldsValue());
    const result = data;
    Object.keys(result).map((item) => {
      if (keysArray.indexOf(item) > -1) {
        if (item === "registered_date") {
          form.setFieldsValue({ [item]: moment(result[item]) });
        } else {
          form.setFieldsValue({ [item]: result[item] });
        }
      }
      return item;
    });
    if (timeRangeKey) {
      form.setFieldsValue({
        [timeRangeKey]: [moment(data[startTimeKey]), moment(data[endTimeKey])],
      });
    }
  }
};
export default dataFillForm;
