import "./style.scss";

import { Col, Form, Input, Layout, Modal, Row, message } from "antd";
import { Redirect, Link, Route, Switch, withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState, useMemo } from "react";
import ScrollToTop from "../../../component/ScrollToTop";

import navMap from "./navMap";
import routeMap from "./routeMap";
import {
  defaultGutter,
  editFormColSpan,
  formItemLayout,
} from "../../../static/formLayoutConfig";
import ImgUploader from "../../../component/ImgUploader";
import ajax from "../../../func/ajax";
const { Content } = Layout;

function BlankLayout(props) {
  const { location } = props;
  const [userInfo, setUserInfo] = useState(null);
  const [form] = Form.useForm();
  const layout = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [avatarInfo, setAvatarInfo] = useState(null);
  //获取用户信心
  const getUseInfo = () => {
    ajax({
      path: "/customer/User/loginUser",
    }).then((res) => {
      if (res.status === 200) {
        localStorage.clear();
        const { data } = res;
        localStorage.userType = 1;
        localStorage.userInfo = JSON.stringify(data);
        localStorage.token = data.token;
        window.location.reload();
      }
    });
  };

  //路由设置
  const RouteRender = (currentRoute, prevPath = "") => {
    return currentRoute.map(({ children = null, path, component }) => {
      const currentPath = `${prevPath}/${path}`;
      if (children) {
        return (
          <Route path={currentPath} key={currentPath}>
            <Switch>{RouteRender(children, currentPath)}</Switch>
          </Route>
        );
      } else {
        return path && path !== "**" ? (
          <Route key={path} path={currentPath} component={component} />
        ) : (
          <Route key={`${currentPath}/redirect`} component={component} />
        );
      }
    });
  };

  //渲染用户头像
  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.userInfo));
    let msgs = JSON.parse(localStorage.userInfo);
    if (msgs.avatar && msgs.avatar != "") {
      let img = [];
      img.push({
        uid: 0,
        status: "done",
        url: msgs.avatar,
        name: "用户头像",
      });
      setAvatarInfo(img);
    }
  }, []);

  //设置用户头像及昵称
  const setAvatar = () => {
    let { nickname, img_url } = form.getFieldsValue();
    if (img_url === 1) {
      if (avatarInfo) {
        img_url = avatarInfo[0].url;
      } else {
        img_url = "";
      }
    } else {
      if (img_url.length === 0) {
        img_url = "";
      } else {
        img_url = img_url[0];
      }
    }
    ajax({
      path: "/customer/User/setAvatar",
      data: { nickname, img_url: img_url },
      ableEmpty: true,
    }).then((res) => {
      if (res.status === 200) {
        setShowModal(false);
        message.success("修改成功");
        getUseInfo();
      }
    });
  };

  return (
    <Layout className={"user_basic-layout container-1200"}>
      <div className={"uLeft"}>
        <div className={"userInfo"}>
          <div className={"avatar"}>
            <img
              src={
                userInfo?.avatar
                  ? userInfo?.avatar
                  : "https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/575d25d8db58950f70076c1c900f539f.png"
              }
              alt=""
            />
            <Modal
              title="个人信息修改"
              visible={showModal}
              onCancel={() => setShowModal(false)}
              onOk={setAvatar}
            >
              <div
                style={{ width: "100%", height: "200px", position: "relative" }}
              >
                <Form
                  form={form}
                  {...formItemLayout}
                  initialValues={{
                    nickname: userInfo?.nickname,
                    img_url: [].push(userInfo?.avatar),
                  }}
                >
                  <Row gutter={defaultGutter}>
                    <Col {...editFormColSpan}>
                      <Form.Item
                        label={"昵称"}
                        name="nickname"
                        rules={[
                          { type: "string" },
                          { required: true, message: "昵称不能为空" },
                          { max: 11, message: "昵称不能过长" },
                        ]}
                      >
                        <Input
                          placeholder={"请输入昵称"}
                          style={{ width: "300px" }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={defaultGutter}>
                    <Col {...editFormColSpan}>
                      <Form.Item label={"头像"} name="img_url">
                        {avatarInfo ? (
                          <ImgUploader
                            key={"upload1"}
                            defaultFileList={avatarInfo ? avatarInfo : []}
                            maxNumber={1}
                          />
                        ) : (
                          <ImgUploader key={"upload2"} maxNumber={1} />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
          </div>
          <div className={"describe"}>
            <span className={"name"}>{userInfo?.nickname}</span>{" "}
            <a onClick={() => setShowModal(true)}>修改</a>
          </div>
        </div>
        <ul className={"uNav"}>
          {navMap.map((item, index) => {
            return (
              <li key={item.name} className={"uNavItem"}>
                <div className={"uNavItemMain"}>{item.name}</div>
                <ul>
                  {item.children.map((childItem, childIndex) => {
                    return (
                      <li
                        className={
                          childItem.childrenPath?.indexOf(location.pathname) >
                          -1
                            ? "active"
                            : childItem.path === location.pathname
                            ? "active"
                            : ""
                        }
                        key={childItem.name}
                      >
                        <Link to={childItem.path}>{childItem.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={"uRight"} ref={layout}>
        <div>
          <Content className={"basic-layout__content"}>
            <ScrollToTop>
              <Switch>
                {RouteRender(routeMap)}
                <Redirect exact to={"/user/myOrder/myOrderList"} />
              </Switch>
            </ScrollToTop>
          </Content>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(BlankLayout);
