import React, { useEffect, useState } from "react";

import "./style.scss";
import { RightOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Button, message } from "antd";
import ImgUploader from "../../../../../component/ImgUploader";
import ajax from "../../../../../func/ajax";
import dataFillForm from "../../../../../func/dataFillForm";
import formValid from "../../../../../func/formValid";
import { Link, useHistory } from "react-router-dom";
const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
    md: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
    md: { span: 16 },
  },
};
const defaultGutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};
const editFormColSpan = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 20,
};

export default () => {
  const history = useHistory();
  useEffect(() => {
    getApplyInfo();
  }, []);
  const [form] = Form.useForm();
  const [type, setType] = useState(1);
  const [applyData, setApplyData] = useState({});
  //表单提交
  const handleSubmit = () => {
    formValid(form).then(() => {
      let {
        name,
        legal_person,
        id_card,
        f_id_card,
        r_id_card,
        credit_code,
        business_license,
      } = form.getFieldsValue();

      //判断是否为修改信息，如果是修改则需要传id
      let type = applyData.c_type;

      //如果存在url则未被修改
      if (f_id_card[0].url) {
        f_id_card = f_id_card[0].url;
      } else {
        f_id_card = f_id_card[0];
      }
      if (r_id_card[0].url) {
        r_id_card = r_id_card[0].url;
      } else {
        r_id_card = r_id_card[0];
      }
      //判断是否为企业申请
      if (type === 1) {
        if (business_license[0].url) {
          business_license = business_license[0].url;
        } else {
          business_license = business_license[0];
        }
      }
      ajax({
        path: "/creditor/v1/change",
        data: {
          name,
          type,
          legal_person,
          id_card,
          f_id_card,
          r_id_card,
          credit_code,
          business_license,
        },
      }).then((res) => {
        if (res.status === 200) {
          message.success("信息提交成功");
          setTimeout(() => {
            history.go(-1);
          }, 1000);
        }
      });
    });
  };
  //获取债权人申请信息
  const getApplyInfo = () => {
    ajax({
      path: "/creditor/v1/merchants",
      method: "get",
    }).then((res) => {
      setType(res.c_type);
      let data = res;
      let f_id_cardArr = [];
      let r_id_cardArr = [];
      let business_licenseArr = [];
      f_id_cardArr.push(data.f_id_card);
      r_id_cardArr.push(data.r_id_card);
      data.f_id_card = f_id_cardArr;
      data.r_id_card = r_id_cardArr;
      data["f_id_card"] = data.f_id_card.map((item, index) => {
        return {
          uid: index,
          status: "done",
          url: item,
          name: index,
        };
      });
      data["r_id_card"] = data.r_id_card.map((item, index) => {
        return {
          uid: index,
          status: "done",
          url: item,
          name: index,
        };
      });
      if (data.c_type === 1 && data.business_license) {
        business_licenseArr.push(data.business_license);
        data.business_license = business_licenseArr;
        data["business_license"] = data.business_license.map((item, index) => {
          return {
            uid: index,
            status: "done",
            url: item,
            name: index,
          };
        });
      }
      setApplyData(data);
      dataFillForm({
        form,
        data,
      });
    });
  };

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>账户管理</span>
            <Link to={"/creditorUser/account/certification/index"}>
              <RightOutlined className={"iconArrow"} />
              <span>认证管理</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>修改变更</span>
          </li>
        </ul>
      </div>
      <div className={"certificationDetail_container"}>
        <Form form={form} {...formItemLayout}>
          {type === 1 && (
            <Row gutter={defaultGutter}>
              <Col {...editFormColSpan}>
                <Form.Item
                  label={"公司名称"}
                  name="name"
                  rules={[{ required: true, message: "该项为必填项" }]}
                >
                  <Input placeholder={"请输入公司名称"} allowClear />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={type === 1 ? "法人姓名" : "姓名"}
                name={type === 1 ? "legal_person" : "name"}
                rules={[{ required: true, message: "该项为必填项" }]}
              >
                <Input placeholder={"请输入法人姓名"} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={type === 1 ? "法人身份证号码" : "身份证号码"}
                name="id_card"
                rules={[{ required: true, message: "身份证号码" }]}
              >
                <Input placeholder={"请输入身份证号码"} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={type === 1 ? "法人身份证正面照" : "身份证正面照"}
                name="f_id_card"
                rules={[{ required: true, message: "" }]}
              >
                {applyData?.f_id_card ? (
                  <ImgUploader
                    key={"f_id_card1"}
                    maxNumber={1}
                    defaultFileList={applyData?.f_id_card || []}
                  />
                ) : (
                  <ImgUploader key={"f_id_card2"} maxNumber={1} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={defaultGutter}>
            <Col {...editFormColSpan}>
              <Form.Item
                label={type === 1 ? "法人身份证反面照" : "身份证反面照"}
                name="r_id_card"
                rules={[{ required: true, message: "" }]}
              >
                {applyData?.r_id_card ? (
                  <ImgUploader
                    maxNumber={1}
                    key={"r_id_card1"}
                    defaultFileList={applyData?.r_id_card}
                  />
                ) : (
                  <ImgUploader key={"r_id_card2"} maxNumber={1} />
                )}
              </Form.Item>
            </Col>
          </Row>
          {type === 1 && (
            <Row gutter={defaultGutter}>
              <Col {...editFormColSpan}>
                <Form.Item
                  label={"统一社会信用代码"}
                  name="credit_code"
                  rules={[
                    { required: true, message: "请输入统一社会信用代码" },
                  ]}
                >
                  <Input placeholder={"请输入统一社会信用代码"} allowClear />
                </Form.Item>
              </Col>
            </Row>
          )}
          {type === 1 && (
            <Row gutter={defaultGutter}>
              <Col {...editFormColSpan}>
                <Form.Item
                  label={"企业营业执照照片"}
                  name="business_license"
                  rules={[{ required: true, message: "企业营业执照照片" }]}
                >
                  {applyData?.business_license ? (
                    <ImgUploader
                      maxNumber={1}
                      key={"business_license1"}
                      defaultFileList={applyData.business_license || []}
                    />
                  ) : (
                    <ImgUploader maxNumber={1} key={"business_license2"} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
        <div className={"handle_submit"}>
          <Button onClick={handleSubmit} type={"primary"}>
            提交审核
          </Button>
        </div>
      </div>
    </div>
  );
};
