import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { RightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import "./style.scss";
import queryToData from "../../../../func/queryToData";
import ajax from "../../../../func/ajax";

export default () => {
  const code = queryToData().code || null;
  const auth_code = queryToData().auth_code || null;
  const weChat = JSON.parse(localStorage.userInfo).openid;
  const aliPay = JSON.parse(localStorage.userInfo).aliuserid;

  //支付宝授权登录
  const aliPayLogin = () => {
    window.location.href =
      "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021001180624195&scope=auth_user&redirect_uri=http://m-satrix.com/user/mySetting/authorize&state=init";
  };

  //微信授权登录
  const weChatLogin = () => {
    window.location.href =
      "https://open.weixin.qq.com/connect/qrconnect?appid=wx94da33391c4a2770&redirect_uri=http://m-satrix.com/user/mySetting/authorize&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
  };

  const getLogin = (code, type) => {
    ajax({
      path: "/customer/User/setAccredit",
      data: { code, type },
    }).then((res) => {
      if (res.status === 200 && res.data.accredit_id) {
        getUseInfo(type);
      }
    });
  };

  const cancelAuth = (type) => {
    ajax({
      path: "/customer/User/setAccreditNot",
      data: { type },
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        message.success("已取消授权");
        getUseInfo(type);
      }
    });
  };

  const getUseInfo = (type) => {
    ajax({
      path: "/customer/User/loginUser",
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        localStorage.clear();
        const { data } = res;
        localStorage.userType = 1;
        localStorage.userInfo = JSON.stringify(data);
        localStorage.token = data.token;
        if (data.aliuserid === 0 && data.openid === 0) {
          localStorage.code = code || auth_code;
        } else {
          localStorage.code = type === 1 ? code : auth_code;
        }
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    if (code && localStorage.code !== code && localStorage.code !== auth_code) {
      localStorage.code = code;
      getLogin(code, 1);
    } else if (
      auth_code &&
      localStorage.code !== auth_code &&
      localStorage.code !== code
    ) {
      localStorage.code = auth_code;
      getLogin(auth_code, 2);
    }
  }, []);
  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>个人设置</span>
            <RightOutlined className={"iconArrow"} />
            <span>第三方授权</span>
          </li>
        </ul>
      </div>
      <div className={"authorize"}>
        <div className="authorize-item">
          <img
            className={weChat === 1 ? "" : "active"}
            src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/59696f9f3cd4c274ecc8b427589f3a4b.png"
            alt=""
          />
          {weChat === 1 ? (
            <div>
              <CheckCircleOutlined
                onClick={() => cancelAuth(1)}
                style={{ color: "#1890ff", fontSize: "20px" }}
              />
              已授权
            </div>
          ) : (
            <>
              <Button onClick={weChatLogin} type={"primary"}>
                去授权
              </Button>
            </>
          )}
        </div>
        <div className="authorize-item">
          <img
            className={aliPay === 1 ? "" : "active"}
            src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/dc0d2b6cb1819186c7b11b2bb39d0778.png"
            alt=""
          />
          {aliPay === 1 ? (
            <div>
              <CheckCircleOutlined
                onClick={() => cancelAuth(2)}
                style={{ color: "#1890ff", fontSize: "20px" }}
              />
              已授权
            </div>
          ) : (
            <Button onClick={aliPayLogin} type={"primary"}>
              去授权
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
