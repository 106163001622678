import ajax from "../func/ajax";

/**
 获取验证码
 **/
export const getPhoneCode = (data) => {
  return ajax({
    path: `/creditor/iphonesend/${data}`,
    isNeedToken: false,
    method: "get",
  });
};

/**
 普通用户注册
 **/
export const customerRegister = (data) => {
  return ajax({
    path: "/customer/Current/register",
    data,
    isNeedToken: false,
  });
};

/**
 普通用户重置密码
 **/
export const customerResetPassword = (data) => {
  return ajax({
    path: "/customer/Current/forgetPwd",
    isNeedToken: false,
    data,
  });
};

/**
 普通用户登录
 **/
export const customerLogin = (data) => {
  return ajax({
    path: "/customer/Current/login",
    data,
    isNeedToken: false,
  });
};

/**
 普通用户授权绑定手机号登录
 **/
export const customerBindPhone = (data) => {
  return ajax({
    path: "/customer/Current/setDound",
    data,
  });
};

/**
 债权人重置秘密
 **/
export const creditorResetPassword = (data) => {
  return ajax({
    path: "/creditor/v1/password",
    data,
    isNeedToken: false,
  });
};

/**
 债权人登录
 **/
export const creditorLogin = (data) => {
  return ajax({
    path: "/creditor/v1/login",
    data,
    isNeedToken: false,
  });
};

/**
 微信授权登录
 **/
export const wxLoginWay = (data) => {
  return ajax({
    path: "/customer/Current/WxLogin",
    data,
    isNeedToken: false,
  });
};

/**
 支付宝授权登录
 **/

export const aliPayLoginWay = (data) => {
  return ajax({
    path: "/customer/Current/AliLogin",
    data,
    isNeedToken: false,
  });
};
