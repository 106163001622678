import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Input, message } from "antd";
import formValid from "../../../../func/formValid";
import { PhoneOutlined } from "@ant-design/icons";
import "./style.scss";
import { Md5 } from "ts-md5";
import { creditorLogin } from "../../../../api/login";

export default () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const handleLogin = () => {
    formValid(form)
      .then(() => {
        let { account, password } = form.getFieldsValue();
        password = Md5.hashStr(String(password.replace(/ /g, "")));
        creditorLogin({ account, password })
          .then((res) => {
            if (res.status === 200) {
              localStorage.clear();
              const data = res.message;
              localStorage.userType = 2;
              localStorage.userInfo = JSON.stringify(data);
              localStorage.token = data.pc_token;
              localStorage.timer = new Date().getTime();
              const delete_sign = data.delete_sign;
              if (delete_sign === 0) {
                setTimeout(() => {
                  message.success("登录成功");
                  history.push("/creditorUser");
                }, 1000);
              } else {
                setTimeout(() => {
                  history.push(`/institutionApply?status=${delete_sign}`);
                }, 1000);
              }
            }
          })
          .catch();
      })
      .catch();
  };

  return (
    <div className={"creditor_login"}>
      <div className={"left_part"}>
        <div className={"left_part_title"}>债权人登录</div>
        <Form form={form} name="normal_login" className="login-form">
          <Form.Item
            name="account"
            rules={[{ required: true, message: "请输入手机号码" }]}
          >
            <Input
              prefix={
                <PhoneOutlined
                  style={{ color: "#1890FF" }}
                  className="site-form-item-icon"
                />
              }
              placeholder="请输入手机号码"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input.Password
              onPressEnter={handleLogin}
              type="password"
              placeholder="请输入密码"
            />
          </Form.Item>

          <Form.Item>
            <Button
              className={"login-form-button"}
              type="primary"
              onClick={handleLogin}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
        <div className={"handle_password"}>
          <Link to={"/login/c_forgetPassword"}>忘记密码</Link>
        </div>
      </div>
      <div className={"right_part"}>
        <div className={"describe_title"}>事项说明</div>
        <div className={"describe_item"}>
          1.债权人可在平台上认领对应的标的物
          可以通过购买服务对标的物进行营销推广。
        </div>
        <div className={"describe_item"}>
          2.如未申请入驻为债权人，请点击此处{" "}
          <Link to={"/institutionApply"}>申请入驻</Link>。
        </div>
      </div>
    </div>
  );
};
