import React, { useState } from "react";
import "./style.scss";
import { Form, Input, Button, message, Steps } from "antd";
import { RightOutlined } from "@ant-design/icons";
import ajax from "../../../../../func/ajax";
import { Link } from "react-router-dom";
import { Md5 } from "ts-md5";

const { Step } = Steps;
const steps = [
  { title: "手机号验证" },
  { title: "支付密码设置" },
  { title: "设置完成" },
];
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default () => {
  const [phone, setPhone] = useState(null);
  const [validate_code, setValidate_code] = useState(null);
  const [current, setCurrent] = useState(0);
  const [liked, setLiked] = useState(true);
  const [count, setCount] = useState(60);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  //验证手机号
  const checkPhone = () => {
    const { phone, validate_code } = form.getFieldsValue();
    ajax({
      path: `/creditor/checkcodehtml/${phone}`,
      data: { code: validate_code },
      method: "get",
    }).then((res) => {
      if (res.status === 200) {
        message.success("手机号验证成功");
        setCurrent(1);
        setPhone(phone);
        setCount(60);
        setValidate_code(validate_code);
      }
    });
  };

  //设置支付密码
  const handleLogin = () => {
    let { new_password, new_password_repeat } = form2.getFieldsValue();
    new_password = Md5.hashStr(String(new_password.replace(/ /g, "")));
    new_password_repeat = Md5.hashStr(
      String(new_password_repeat.replace(/ /g, ""))
    );
    ajax({
      path: "/creditor/v1/userpassword",
      data: {
        phone,
        phone_code: validate_code,
        new_password,
        new_password_repeat,
        type: 1,
      },
    }).then((res) => {
      if (res.error_code === 200) {
        setCurrent(2);
      }
    });
  };

  //倒计时
  const sendCode = () => {
    let counts = count - 1;
    const timer = setInterval(() => {
      setCount(counts--);
      setLiked(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked(true);
        setCount(60);
      }
    }, 1000);
  };

  //ajax获取验证码
  const getCode = (phone) => {
    ajax({
      path: `/creditor/iphonesend/${phone}`,
      data: { phone },
      method: "get",
      isNeedToken: false,
    }).then((res) => {});
  };

  //验证码操作
  const verifiedSubmit = () => {
    let { phone } = form.getFieldsValue();
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      sendCode();
      getCode(phone);
    } else {
      message.error("请输入正确的手机号");
      return;
    }
  };

  return (
    <div>
      <div className={"user_content_header"}>
        <ul>
          <li>
            <span>账户管理</span>
            <Link to={"/creditorUser/account/managePassword/index"}>
              <RightOutlined className={"iconArrow"} />
              <span>密码管理</span>
            </Link>
            <RightOutlined className={"iconArrow"} />
            <span>支付密码设置</span>
          </li>
        </ul>
      </div>
      <div className={"setPayPassword-container"}>
        <Steps current={current} className={"step-content-footer"}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        {current === 0 && (
          <div className={"first-tips"}>
            我们将向该手机发送验证码，请在下方输入您看到的验证码
          </div>
        )}
        {current === 0 && (
          <div className={"steps-content"}>
            <Form
              {...layout}
              form={form}
              initialValues={{
                phone: JSON.parse(localStorage.userInfo).account,
              }}
            >
              <Form.Item
                label="手机号"
                name={"phone"}
                rules={[
                  {
                    required: true,
                    pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                    message: "请输入正确格式的手机号码",
                  },
                ]}
              >
                <Input disabled placeholder={"请输入您的手机号"} />
              </Form.Item>
              <Form.Item
                label="验 证 码"
                name={"validate_code"}
                style={{ display: "flex" }}
                rules={[
                  {
                    required: true,
                    message: "请输入验证码",
                  },
                ]}
              >
                <Input
                  placeholder={"请输入验证码"}
                  addonAfter={
                    <Button
                      disabled={liked ? false : true}
                      onClick={verifiedSubmit}
                      className={liked ? "verificationCode" : ""}
                    >
                      {liked ? "获取验证码" : count + "秒后重发"}
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  type={"primary"}
                  style={{ width: "120px", height: "40px" }}
                  onClick={checkPhone}
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
        {current === 1 && (
          <div className={"steps-content"}>
            <Form {...layout} form={form2}>
              <Form.Item
                label="支付密码"
                name={"new_password"}
                rules={[
                  {
                    required: true,
                    pattern: /^\d{6}$/,
                    message: "密码长度为6位数字",
                  },
                ]}
              >
                <Input placeholder={"密码长度为6位数字"} />
              </Form.Item>
              <Form.Item
                name="new_password_repeat"
                dependencies={["new_password"]}
                label="再次输入密码"
                rules={[
                  { required: true, message: "再次输入密码" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("两次密码输入不一致！");
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={"再次输入请保持一致"} />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  type={"primary"}
                  style={{ width: "120px", height: "40px" }}
                  onClick={handleLogin}
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
        {current === 2 && (
          <div className={"steps-content third-steps-content"}>
            <img
              src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/97ac8fe49eb2968235fd3b23dd2ae926.png"
              alt=""
            />
            <span>您的支付密码已设置完成</span>
          </div>
        )}
      </div>
    </div>
  );
};
