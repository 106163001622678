import React from "react";
import "./style.scss";

import { codeSelect } from "../../static/option";

export default () => {
  return (
    <div className={"footer"}>
      <div className={"container-1200 footer_content"}>
        <div className={"header"}>矩阵资产</div>
        <div className={"middle_content"}>
          <ul>
            <li>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/b48e6eefb3a7791022d69f50b47bf087.png"
                alt=""
              />
              <span className={"des"}>客服电话</span>
              <div className={"infos"}>400-157-1060</div>
            </li>
            <li>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/3ad05066da34b10aec32ebba30b85983.png"
                alt=""
              />
              <span className={"des"}>电子邮箱</span>
              <div className={"infos"}>tz@laipaiya.com</div>
            </li>
            <li>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/8152fe0613f5516e094e5a697637d982.png"
                alt=""
              />
              <span className={"des"}>
              杭州市萧山区钱江世纪城平澜路299号浙江商会大厦20层
              </span>
            </li>
          </ul>
          <div className={"codes"}>
            <div className={"item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/fd27773fff4f2736425e6d78f8ad54c0.jpeg"
                alt=""
              />
              <div>矩阵资产公众号</div>
            </div>
          </div>
          {/*<div className={"codes"}>*/}
          {/*  <div className={"item"}>*/}
          {/*    <img src={codeSelect[0]} alt="" />*/}
          {/*    <div>ios下载</div>*/}
          {/*  </div>*/}
          {/*  <div className={"item"}>*/}
          {/*    <img src={codeSelect[1]} alt="" />*/}
          {/*    <div>Android下载</div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className={"footer_text"}>
          <div className={"numbers"}>
            © Copyright 2019 m-satrix.com 版权所有 杭州矩石阵网络科技有限公司 |
            <a href="http://beian.miit.gov.cn/"> 浙ICP备19038817号-1</a> |
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010402004075">
              &nbsp;浙公网安备 33010402004075号
            </a>
          </div>
          <ul className="footer-bottom-link">
            <li>
              <a
                target="_blank"
                href="http://www.chinacourt.org/index.shtml"
                rel="noopener noreferrer"
              >
                中国法院网
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://www.zjcourt.cn/"
                rel="noopener noreferrer"
              >
                浙江法院新闻网
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://shfy.chinacourt.org/index.shtml"
                rel="noopener noreferrer"
              >
                上海法院网
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://www.jsfy.gov.cn/"
                rel="noopener noreferrer"
              >
                江苏法院网
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://bjgy.chinacourt.org/index.shtml"
                rel="noopener noreferrer"
              >
                北京法院网
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://www.gdcourts.gov.cn/"
                rel="noopener noreferrer"
              >
                广东法院网
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://www.tmsf.com/"
                rel="noopener noreferrer"
              >
                透明售房网
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://www.zjpse.com/"
                rel="noopener noreferrer"
              >
                浙江产权交易所
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://www.cbex.com.cn/"
                rel="noopener noreferrer"
              >
                北京产权交易所
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://www.yunfangdata.com/"
                rel="noopener noreferrer"
              >
                云房数据
              </a>
            </li>


            <li>
              <a
                target="_blank"
                href="http://www.kuaidi.com/"
                rel="noopener noreferrer"
              >
                快递查询API
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
//https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/3cf6e751b1fd8bad9089964c3bcc478b.png
//https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/bb95efb20a5e2321a1e55783f0caa7d0.png
//https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/a119c88449d70fc7af66a4dad09c868f.png
// https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/23f493a621fcb46ac03ab6e5f2dd560f.png
